export const screenChange = (payload: Object) => {
  return {
    type: 'SCREEN_CHANGE',
    payload
  };
};

export const tabChange = (payload: Object) => {
  return {
    type: 'TAB_CHANGE',
    payload
  };
};

export const UITogglePanels = payload => {
  return {
    type: 'UI_TOGGLE_PANELS',
    payload
  };
};

export const config = payload => {
  return {
    type: 'UI_CONFIG',
    payload
  };
};

export const toggleView = () => {
  return {
    type: 'UI_TOGGLE_VIEW'
  };
};

export const changeView = (payload: 'outside' | 'inside') => {
  return {
    type: 'UI_CHANGE_VIEW',
    payload
  };
};

export const changeLanguage = (payload: { value: any; label: any }) => {
  localStorage.setItem('language', payload.value);
  return {
    type: 'UI_CHANGE_LANGUAGE',
    payload
  };
};

export const changeCurrency = (payload: { value: any; label: any }) => {
  localStorage.setItem('currency', payload.value);
  return {
    type: 'UI_CHANGE_CURRENCY',
    payload
  };
};

export const configPanelMaximize = (payload: boolean) => {
  return {
    type: 'UI_CONFIG_PANEL_MAXIMIZE',
    payload
  };
};

export type UIActions = ReturnType<typeof screenChange>;
