import React, { useRef, useEffect, useState } from 'react';
import './preview.scss';
import WallHole from '../wallHole/wallHole';
import { State } from '../../store/store';
import { connect, useDispatch } from 'react-redux';
import EntranceDoor from '../entranceDoor/entranceDoor';
import { UIState, UIScreen } from './../../store/UI/reducer';
import { toggleView } from './../../store/UI/action';
import BalconyDoor from '../balconyDoor/balconyDoor';
import Window from '../window/window';
import Persiennes from '../persiennes/persiennes';
import html2canvas from 'html2canvas';
import Panel from '../panel/panel';

interface PreviewProps {
  preview: any;
  UI: UIState;
  configId: number;
  view: 'inside' | 'outside';
  houseColor: string;
  wallColor: string;
  configPanelMaximized: boolean;
  screen: UIScreen;
}

const Preview = (props: PreviewProps) => {
  const previewRef = useRef<HTMLDivElement | null>(null);
  const shadowRef = useRef(null);
  const sideWalkRef = useRef(null);
  const [scale, setScale] = useState(0.95);
  const dispatch = useDispatch();
  const [outsideBackground, setOutsideBackground] = useState<null | HTMLImageElement>(null);
  const [insideBackground, setInsideBackground] = useState<null | HTMLImageElement>(null);
  const [backgroundImage, setBackgroundImage] = useState<null | HTMLImageElement>(null);
  const [windowDimensions, setWindowDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    if (previewRef.current && props.preview.height) {
      const spaceAbove =
        previewRef.current?.clientHeight / (props.preview.additionalGlassTop?.value > 0 ? 6 : 4);

      const newScale =
        (previewRef.current?.clientHeight - spaceAbove) /
        ((props.preview.height.value +
          (props.preview.additionalGlassTop?.value > 0 &&
            (props.preview.additionalGlassTopHeight?.value ?? 0))) /
          3.3);

      setScale(newScale > 1 ? 1 : newScale);
    }
  }, [
    props.preview,
    windowDimensions,
    props.UI.panels.session,
    props.UI.panels.config,
    props.UI.configPanel.maximized
  ]);

  useEffect(() => {
    const outside = new Image();
    outside.src = require('../../assets/textures/concrete-wall-3.png');
    outside.onload = () => setOutsideBackground(outside);

    setBackgroundImage(outsideBackground);

    const inside = new Image();
    inside.src = require('../../assets/textures/white-wall.png');
    inside.onload = () => setInsideBackground(inside);
  }, []);

  useEffect(() => {
    props.view === 'inside'
      ? setBackgroundImage(insideBackground)
      : setBackgroundImage(outsideBackground);
  }, [props.view]);

  const getClipPathX = () => {
    return (
      props.preview.width?.value / 3.3 +
      (props.preview.additionalGlassLeft?.value ?? 0) *
        ((props.preview.additionalGlassLeftWidth?.value ?? 0) / 3.3 + 23) +
      (props.preview.additionalGlassRight?.value ?? 0) *
        ((props.preview.additionalGlassRightWidth?.value ?? 0) / 3.3 + 23) +
      58 +
      (props.preview.wings?.value > 1 ? 16 : 0)
    );
  };

  const onClick = () => {
    if (props.preview.type?.value !== 'Persiennes' || props.UI.view === 'inside') {
      dispatch(toggleView());
    }

    // // @ts-ignore
    // html2canvas(document.getElementsByClassName('persiennes')[0]).then(canvasElem => {
    //   var imageType = 'image/png';
    //   var imageData = canvasElem.toDataURL(imageType);
    //   document.location.href = imageData.replace(imageType, 'image/octet-stream');
    // });
  };

  useEffect(() => {
    window.addEventListener('resize', handleOnResize);
    return () => window.removeEventListener('resize', handleOnResize);
  }, []);

  const handleOnResize = (e: UIEvent) => {
    setWindowDimensions({ width: window.innerWidth, height: window.innerHeight });
  };

  return (
    <div
      className='preview'
      ref={previewRef}
      onClick={() => onClick()}
      style={{
        backgroundColor: props.UI.view === 'inside' ? props.wallColor : props.houseColor,
        height: props.screen.tabletPort
          ? props.configPanelMaximized
            ? '100%'
            : props.UI.panels.config
            ? '50%'
            : '100%'
          : '100%',
        paddingRight:
          (props.screen.tabletPort ? true : !props.UI.panels.config) &&
          (props.screen.tabletPort ? true : !props.UI.panels.session)
            ? 0
            : '47.5rem',
        paddingLeft: props.screen.tabletPort ? '5.3rem' : 0
      }}
    >
      {props.preview?.type?.value && (
        <WallHole
          scale={scale}
          view={props.view}
          wallColor={props.view === 'outside' ? props.houseColor : props.wallColor}
          bottom={props.preview.type.value === 'Window' && 300}
          productType={props.preview.type.value}
          sill={props.preview.sill?.value ?? null}
        >
          {props.preview?.type?.value === 'EntranceDoor' && (
            <EntranceDoor
              model={props.preview.model.value}
              color={props.preview.color.value}
              frameColor={props.preview.frameColor?.value ?? props.preview.color.value}
              width={props.preview.width.value / 3.3}
              height={props.preview.height.value / 3.3}
              opening={props.preview.opening?.value}
              additionalLeftAmount={props.preview.additionalGlassLeft.value || 0}
              additionalLeftWidth={props.preview.additionalGlassLeftWidth?.value / 3.3}
              additionalRightAmount={props.preview.additionalGlassRight.value || 0}
              additionalRightWidth={props.preview.additionalGlassRightWidth?.value / 3.3}
              additionalTopAmount={props.preview.additionalGlassTop.value || 0}
              additionalTopHeight={props.preview.additionalGlassTopHeight?.value / 3.3}
              handrail={props.preview.handrail}
              handrailInside={props.preview.handrailInside.value}
              handleInside={props.preview.handleInside?.value}
              handleOutside={props.preview.handleOutside?.value}
              protection={props.preview.protection?.value}
              rosetteInside={props.preview.rosetteInside?.value}
              rosetteOutside={props.preview.rosetteOutside?.value}
              scale={scale}
              view={props.view}
              glass={props.preview.glass?.value ?? 'transparent'}
            />
          )}
          {props.preview?.type?.value === 'BalconyDoor' && (
            <BalconyDoor
              color={props.preview.color.value}
              frameColor={props.preview.frameColor?.value}
              width={props.preview.width.value / 3.3}
              height={props.preview.height.value / 3.3}
              opening={props.preview.opening?.value}
              handle={props.preview.handle?.value}
              wings={props.preview.wings?.value}
              scale={scale}
              view={props.view}
              glass={props.preview.glass?.value ?? 'transparent'}
            />
          )}
          {props.preview?.type?.value === 'Window' && (
            <Window
              color={props.preview.color.value}
              frameColor={props.preview.frameColor?.value}
              width={props.preview.width.value / 3.3}
              height={props.preview.height.value / 3.3}
              opening={props.preview.opening?.value}
              handle={props.preview.handle?.value}
              wings={props.preview.wings?.value}
              scale={scale}
              view={props.view}
              glass={props.preview.glass?.value ?? 'transparent'}
            />
          )}
          {props.preview?.type?.value === 'Panel' && (
            <Panel
              color={props.preview.color.value}
              width={props.preview.width.value / 3.3}
              height={props.preview.height.value / 3.3}
              scale={scale}
              view={props.view}
              glass={props.preview.glass?.value ?? 'transparent'}
            />
          )}
          {props.preview?.type?.value === 'Persiennes' && (
            <>
              <Persiennes
                color={props.preview.color.value}
                coverColor={props.preview.coverColor?.value ?? props.preview.color.value}
                boxColor={props.preview.boxColor?.value ?? props.preview.color.value}
                skirtingColor={props.preview.skirtingColor?.value ?? props.preview.color.value}
                width={props.preview.width.value / 3.3}
                height={props.preview.height.value / 3.3}
                opening={props.preview.opening?.value}
                wings={props.preview.wings?.value}
                scale={scale}
                view={props.view}
                boxShape={props.preview.boxShape.value}
                system={props.preview.system.value}
              />
              <Window
                color={props.preview.color.value}
                frameColor={props.preview.frameColor?.value}
                width={props.preview.width.value / 3.3}
                height={props.preview.height.value / 3.3}
                opening={props.preview.opening?.value}
                handle={props.preview.handle?.value}
                wings={props.preview.wings?.value ?? 1}
                scale={scale}
                view={props.view}
                glass={props.preview.glass?.value ?? 'transparent'}
              />
            </>
          )}
        </WallHole>
      )}
      <div
        className='wall'
        style={{
          backgroundImage: backgroundImage?.src && `url(${backgroundImage?.src})`,
          backgroundColor: props.view === 'outside' ? props.houseColor : props.wallColor,
          width: props.screen.tabletPort
            ? 'calc(100% + 5.3rem)'
            : props.UI.panels.config || props.UI.panels.session
            ? 'calc(100% - 47.5rem)'
            : '100%',
          clipPath:
            props.view === 'inside' &&
            `polygon( 
            0 0,
            100% 0,
            100% 100%,
            calc(50% + ${(getClipPathX() * scale) / 2}px) 100%,
            calc(50% + ${(getClipPathX() * scale) / 2}px) calc(100% - ${16 * scale}px),
            calc(50% - ${(getClipPathX() * scale) / 2}px) calc(100% - ${16 * scale}px),
            calc(50% - ${(getClipPathX() * scale) / 2}px) 100%,
            0 100%
          )`
        }}
      ></div>
      {props.view === 'outside' && <div className='wall-shadow' ref={shadowRef} />}
      <div className='sidewalk' ref={sideWalkRef}></div>
      {props.view === 'inside' && (
        <>
          <div
            className='floor-batten'
            style={{
              width: props.screen.tabletPort
                ? 'calc(100% + 5.3rem)'
                : props.UI.panels.config || props.UI.panels.session
                ? 'calc(100% - 47.5rem)'
                : '100%',
              clipPath:
                props.preview.type?.value !== 'Window' &&
                `polygon(
            0 0,
            100% 0,
            100% 100%,
            calc(50% + ${((getClipPathX() - 24) * scale) / 2}px + ${12 * scale}px) 100%,
            calc(50% + ${((getClipPathX() - 24) * scale) / 2}px + ${12 *
                  scale}px) calc(100% - ${24 * scale}px),
            calc(50% - ${((getClipPathX() + 3) * scale) / 2}px) calc(100% - ${24 * scale}px),
            calc(50% - ${((getClipPathX() + 3) * scale) / 2}px) 100%,
            0 100%
          )`,
              height: 20 * scale
            }}
          ></div>
          <div className='floor' />
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    preview: state.preview,
    UI: state.UI,
    configId: state.UI.config,
    view: state.UI.view,
    // @ts-ignore
    houseColor: state.house.facade.value,
    // @ts-ignore
    wallColor: state.house.wallColor.value,
    configPanelMaximized: state.UI.configPanel.maximized,
    screen: state.UI.screen
  };
};

export default connect(mapStateToProps)(Preview);
