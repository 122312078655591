import React, { useState, useEffect } from 'react';
import './persiennes.scss';
import PentoBox from './boxes/pento/pento';
import QuadroBox from './boxes/quadro/quadro';
import PentovalBox from './boxes/pentoval/pentoval';
import RoundBox from './boxes/round/round';
import { toggleView } from './../../store/UI/action';
import { useDispatch } from 'react-redux';

export interface PersiennesProps {
  color: string;
  coverColor: string;
  boxColor: string;
  skirtingColor: string;
  view: 'inside' | 'outside';
  scale: number;
  width: number;
  height: number;
  opening: string;
  wings: number;
  system: string;
  boxShape: string;
}

const Persiennes: React.SFC<PersiennesProps> = (props: PersiennesProps) => {
  const [shutters, setShutters] = useState([]);
  const dispatch = useDispatch();

  const scale = (amount: number) => {
    return amount * props.scale;
  };

  useEffect(() => {
    if (props.height) {
      const shuttersResult = [];
      for (let i = 0; i <= props.height; i += scale(20)) {
        shuttersResult.push(i);
      }
      setShutters(shuttersResult);
    }
  }, [props.height]);

  useEffect(() => {
    if (props.view === 'inside') {
      dispatch(toggleView());
    }
  }, []);

  return (
    <div className='persiennes' style={{ zIndex: props.view === 'inside' && -3 }}>
      <div className='persiennes-shadow'></div>
      <div className='persiennes__back-color' style={{ backgroundColor: props.color }}></div>
      {props.system === 'external' && (
        <>
          {props.boxShape === 'pento' && <PentoBox color={props.boxColor} />}
          {props.boxShape === 'pentoval' && <PentovalBox color={props.boxColor} />}
          {props.boxShape === 'round' && <RoundBox color={props.boxColor} />}
          {props.boxShape === 'quadro' && <QuadroBox color={props.boxColor} />}
          <div className='persiennes-box-under-shadow' />
        </>
      )}
      {props.system === 'builtIn' && <div className='persiennes__top-drop-shadow'></div>}
      <div className='persiennes__cover'>
        <div className='persiennes__cover-left' style={{ backgroundColor: props.coverColor }}>
          <img
            className='persiennes__cover-left-button-top'
            src={require('../../assets/preview/persiennes-button.png')}
            alt=''
          />
          <img
            className='persiennes__cover-left-button-bottom'
            src={require('../../assets/preview/persiennes-button.png')}
            alt=''
          />
        </div>

        {props.wings === 2 && (
          <div className='persiennes__cover-center-wrapper'>
            <div className='persiennes__cover-center' style={{ backgroundColor: props.coverColor }}>
              <img
                className='persiennes__cover-center-button-top'
                src={require('../../assets/preview/persiennes-button.png')}
                alt=''
              />
              <img
                className='persiennes__cover-center-button-bottom'
                src={require('../../assets/preview/persiennes-button.png')}
                alt=''
              />
            </div>
            <div
              className='persiennes__cover-center2'
              style={{ backgroundColor: props.coverColor }}
            >
              <img
                className='persiennes__cover-center2-button-top'
                src={require('../../assets/preview/persiennes-button.png')}
                alt=''
              />
              <img
                className='persiennes__cover-center2-button-bottom'
                src={require('../../assets/preview/persiennes-button.png')}
                alt=''
              />
            </div>
          </div>
        )}

        <div className='persiennes__cover-right' style={{ backgroundColor: props.coverColor }}>
          <img
            className='persiennes__cover-right-button-top'
            src={require('../../assets/preview/persiennes-button.png')}
            alt=''
          />
          <img
            className='persiennes__cover-right-button-bottom'
            src={require('../../assets/preview/persiennes-button.png')}
            alt=''
          />
        </div>
      </div>
      <div className='persiennes__shutters-wrapper'>
        <div className='persiennes__shutters'>
          {shutters.map(top => (
            <div key={top} className='persiennes__shutters-shutter' style={{ height: scale(20) }} />
          ))}
        </div>
        <div className='persiennes__skirting' style={{ backgroundColor: props.skirtingColor }} />
      </div>
    </div>
  );
};

export default Persiennes;
