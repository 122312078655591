import React from 'react';
import './radioInput.scss';

export interface RadioInputProps {
  name?: string;
  label: string;
  value: any;
  onChange: Function;
  checked: boolean;
}

const RadioInput: React.SFC<RadioInputProps> = (props: RadioInputProps) => {
  return (
    <div className='radio-input'>
      <input
        type='radio'
        className='radio-input__input'
        id={props.label}
        name={props.name}
        value={props.value}
        onChange={value => props.onChange(value)}
        checked={props.checked}
      />
      <div className='radio-input__input-custom'>
        <div
          className='radio-input__input-custom-inner'
          style={{ opacity: props.checked ? 1 : 0 }}
          onClick={() => props.onChange(true)}
        />
      </div>
      <label htmlFor={props.label} className='radio-input__label'>
        {props.label}
      </label>
    </div>
  );
};

export default RadioInput;
