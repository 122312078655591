import React, { useRef, useEffect, useState } from 'react';
import './property.scss';
import Plus from '../plus/plus';
import Button from '../button/button';
import { ReactComponent as CheckmarkIcon } from '../../assets/checkmark.svg';
import { State } from '../../store/store';
import { connect } from 'react-redux';

export interface PropertyProps {
  label: string;
  subLabel: string;
  open: boolean;
  onClick: any;
  onNextClick?: any;
  children: React.ReactChild | React.ReactFragment | React.ReactPortal | boolean | null | undefined;
  next?: boolean;
  subcategory?: boolean;
  touched: boolean;
  translation: any;
}

const Property: React.SFC<PropertyProps> = (props: PropertyProps) => {
  const contentWrapperRef = useRef<HTMLDivElement>(null);
  const [overflow, setOverflow] = useState('hidden');
  const [touched, setTouched] = useState(false);
  const [lastTimeClick, setLastTimeClick] = useState(0);

  useEffect(() => {
    if (props.open) {
      setTimeout(() => setOverflow('visible'), 300);
    } else {
      setOverflow('hidden');
      props.touched && setTouched(true);
    }
  }, [props.open]);

  const handleOnClick = () => {
    if (Date.now() > lastTimeClick + 300) {
      props.onClick();
      setLastTimeClick(Date.now());
    }
  };

  return (
    <div className='property'>
      <div className='property__header' onClick={handleOnClick}>
        <div className='property__header__label'>
          <div className='property__header__label-top'>
            {touched && <CheckmarkIcon />}
            <h2 className='property__header__label-label'>{props.label}</h2>
          </div>
          <span className='property__header__label-sub'>{props.subLabel}</span>
        </div>
        <Plus open={props.open} />
      </div>
      <div
        className='property__content'
        style={{
          maxHeight: props.open
            ? props.subcategory
              ? 800
              : contentWrapperRef.current?.getBoundingClientRect().height
            : 0,
          overflow
        }}
      >
        <div className='property__content__wrapper' ref={contentWrapperRef}>
          {props.children}
          {props.next !== false && (
            <Button
              label={props.translation.next}
              style={{
                fontSize: '1.3rem',
                height: '3.6rem',
                width: '11.2rem',
                marginLeft: '-1rem',
                marginTop: '3.5rem'
              }}
              onClick={props.onNextClick}
              type={2}
            />
          )}
        </div>
      </div>
      <hr />
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    translation: state.translation
  };
};

export default connect(mapStateToProps)(Property);
