import React, { useRef, useState } from 'react';
import './dimensionInput.scss';

export interface DimensionInputProps {
  min: number;
  max: number;
  name: string;
  value: string | number;
  label: string;
  onChange: Function;
}

const DimensionInput: React.SFC<DimensionInputProps> = (props: DimensionInputProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const valueSizeRef = useRef<HTMLParagraphElement>(null);
  const [textInputValue, setTextInputValue] = useState(null);

  const handleOnTextInputChange = e => {
    if (e.target.value !== props.value) {
      setTextInputValue(+e.target.value);
    }
  };

  const handleOnTextInputBlur = e => {
    if (textInputValue && textInputValue >= props.min && textInputValue <= props.max) {
      props.onChange(+e.target.value);
    }
    setTextInputValue(null);
  };

  return (
    <div className='dimension-input' ref={ref}>
      <p className='dimension-input__value-size' ref={valueSizeRef}>
        {props.value}
      </p>
      <div className='dimension-input__label-holder'>
        <input
          type='text'
          className='dimension-input__input'
          value={textInputValue || props.value}
          style={{ width: valueSizeRef.current?.clientWidth }}
          onChange={handleOnTextInputChange}
          onBlur={handleOnTextInputBlur}
        />
        <label htmlFor={props.name} className='dimension-input__label'>
          mm {props.label}
        </label>
      </div>
      <div className='dimension-input__input-wrapper'>
        <input
          className='dimension-input__input'
          id={props.name}
          type='range'
          min={props.min}
          max={props.max}
          onChange={e => props.onChange(+e.target.value)}
          value={props.value}
        />
        <div
          className='dimension-input__track'
          style={{
            width:
              ((ref.current?.offsetWidth || 0) / (props.max - props.min)) *
              (+props.value - props.min)
          }}
        ></div>
      </div>
    </div>
  );
};

export default DimensionInput;
