import React, { useState, useRef, useEffect } from 'react';
import './configPanel.scss';
import Property from '../../components/property/property';
import Button from '../../components/button/button';
import AmountInput from '../../components/amountInput/amountInput';
import { connect, useDispatch } from 'react-redux';
import { State } from '../../store/store';
import { ConfigState, CurrencyConfig } from '../../store/config/reducer';
import {
  toggleOpen,
  changeProductAmount,
  sessionProductPrice,
  sessionProductDimensions
} from './../../store/session/actions';
import inputGenerator from './inputGenerator';
import { previewAddProperty } from './../../store/preview/actions';
import { ProductsConfig } from './../../store/config/reducer';
import { UIScreen } from './../../store/UI/reducer';
import { config, changeView } from '../../store/UI/action';
import { ReactComponent as Arrow } from '../../assets/arrow.svg';
import { configPanelMaximize, UITogglePanels } from './../../store/UI/action';

export interface ConfigPanelProps {
  config: ConfigState;
  session: ProductsConfig;
  id: number;
  screen: UIScreen;
  preview: any;
  language: string;
  showPrice: boolean;
  maximized: boolean;
  translation: any;
  currencyConfig: CurrencyConfig;
}

const ConfigPanel: React.SFC<ConfigPanelProps> = (props: ConfigPanelProps) => {
  const [properties, setProperties] = useState([]);
  const panelRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const handleOnClick = (
    entry: number,
    property: number,
    dependingView: 'inside' | 'outside' | null,
    subcategory?: number
  ) => {
    dispatch(toggleOpen({ entry, property, subcategory }));
    dependingView && dispatch(changeView(dependingView));
  };

  const handleOnNextClick = (entry: number, property: number, subcategory?: number) => {
    dispatch(toggleOpen({ entry, property: property + 1, subcategory }));
  };

  useEffect(() => {
    const propsArr: any[] = [];
    if (typeof props.id === 'number') {
      const productConfigId = props.session[props.id].id;
      Object.keys(props.config.products[productConfigId].properties).map(propertyId => {
        const propertyConfig = props.config.properties[+propertyId];
        const propertyDepends = propertyConfig.depends;
        if (
          propertyDepends &&
          props.session[props.id].properties[propertyDepends.property]?.values[
            propertyDepends.input
          ]?.value !== propertyDepends.value
        ) {
          return;
        } else {
          const subLabel = [];
          if (props.session[+props.id].properties[+propertyId].touched) {
            if (propertyConfig.labels.en === 'Dimensions') {
              let width = 0;
              let height = 0;
              Object.keys(props.session[+props.id].properties[+propertyId].values).forEach(
                inputId => {
                  const inputValue =
                    props.session[+props.id].properties[+propertyId].values[+inputId].value;
                  const previewValue = propertyConfig.inputs[+inputId].preview;
                  switch (previewValue) {
                    case 'width':
                      width += inputValue;
                      break;
                    case 'height':
                      height += inputValue;
                      break;
                    case 'additionalGlassRightWidth':
                      width += props.preview.additionalGlassRight.value * inputValue;
                      break;
                    case 'additionalGlassLeftWidth':
                      width += props.preview.additionalGlassLeft.value * inputValue;
                      break;
                    case 'additionalGlassTopHeight':
                      height += props.preview.additionalGlassTop.value * inputValue;
                      break;
                  }
                  subLabel[0] = width + 'mm x ' + height + 'mm';
                }
              );
            } else if (propertyConfig.type !== 'subcategory') {
              Object.keys(props.session[+props.id].properties[+propertyId].values).forEach(
                inputId => {
                  const valueId =
                    props.session[+props.id].properties[+propertyId].values[+inputId].valueId;
                  const valueLabel =
                    valueId && propertyConfig.inputs[+inputId]?.options[+valueId]?.labels?.yu;
                  valueLabel && subLabel.push(valueLabel);
                }
              );
            } else {
              Object.keys(props.session[+props.id].properties[+propertyId].subcategories).forEach(
                subcategoryId => {
                  const valueId =
                    props.session[+props.id].properties[+propertyId].subcategories[+subcategoryId]
                      .valueId;
                  const valueLabel =
                    valueId &&
                    propertyConfig.subcategories[+subcategoryId].inputs[1].options[+valueId]?.labels
                      ?.yu;
                  valueLabel && subLabel.push(valueLabel);
                }
              );
            }
          }

          propsArr.push(
            <Property
              key={propertyId}
              label={propertyConfig.labels[props.language]}
              open={props.session[+props.id].properties[+propertyId].open}
              subLabel={
                subLabel.length > 0 ? subLabel.join(' | ') : props.translation.clickToConfigure
              }
              onClick={() =>
                handleOnClick(+props.id, +propertyId, propertyConfig.dependingView ?? null)
              }
              onNextClick={() => handleOnNextClick(+props.id, +propertyId)}
              touched={props.session[+props.id].properties[+propertyId].touched}
              subcategory={
                props.session[+props.id].properties[+propertyId].subcategories ? true : false
              }
            >
              {propertyConfig.type !== 'subcategory'
                ? Object.keys(propertyConfig.inputs).map(inputId => {
                    const depends = propertyConfig.inputs[+inputId].depends;
                    const dependsInput =
                      depends &&
                      props.session[+props.id].properties[+depends.property]?.values[depends.input];
                    const dependsInputValue = dependsInput && +dependsInput.value;

                    if ((depends && dependsInputValue) || !depends) {
                      return inputGenerator(
                        props.id,
                        propertyConfig.inputs[inputId],
                        propertyConfig.labels.en,
                        +inputId,
                        null,
                        +propertyId
                      );
                    }
                  })
                : Object.keys(propertyConfig.subcategories).map(subcategoryId => {
                    const subcategoryProperty = propertyConfig.subcategories[+subcategoryId];
                    const open =
                      props.session[+props.id].properties[+propertyId].subcategories[subcategoryId]
                        .open;

                    return (
                      <Property
                        key={subcategoryId}
                        label={subcategoryProperty.labels[props.language]}
                        open={open}
                        subLabel={props.translation.clickToConfigure}
                        onClick={() =>
                          handleOnClick(
                            +props.id,
                            +propertyId,
                            subcategoryProperty.dependingView ?? null,
                            +subcategoryId
                          )
                        }
                        next={false}
                        touched={false}
                      >
                        {Object.keys(subcategoryProperty.inputs).map(inputId => {
                          return inputGenerator(
                            props.id,
                            subcategoryProperty.inputs[inputId],
                            propertyConfig.labels.en,
                            +inputId,
                            +subcategoryId,
                            +propertyId,
                            propertyConfig.onlyOne
                          );
                        })}
                      </Property>
                    );
                  })}
            </Property>
          );
        }
      });

      dispatch(
        previewAddProperty({
          previewProperty: 'type',
          value: { value: props.session[props.id].type }
        })
      );
    }
    setProperties(propsArr);
  }, [props.id, props.session, props.language]);

  useEffect(() => {
    if (props.showPrice && typeof props.id === 'number') {
      let perm2 = 0;
      let fix = 0;

      const width =
        props.preview.width?.value +
        (props.preview.additionalGlassLeft?.value ?? 0) *
          (props.preview.additionalGlassLeftWidth?.value ?? 0) +
        (props.preview.additionalGlassRight?.value ?? 0) *
          (props.preview.additionalGlassRightWidth?.value ?? 0);

      const height =
        props.preview.height?.value +
        (props.preview.additionalGlassTop?.value ?? 0) *
          (props.preview.additionalGlassTopHeight?.value ?? 0);

      const m2 = (width * height) / 1000000;

      dispatch(
        sessionProductDimensions({
          id: props.id,
          dimensions: {
            width: props.preview.width?.value,
            height: props.preview.height?.value,
            overallWidth: width,
            overallHeight: height
          }
        })
      );

      if (props.session[props.id]?.properties) {
        Object.keys(props.session[props.id].properties).forEach(propertyId => {
          const propertyValues = props.session[props.id]?.properties[+propertyId]?.values;
          if (propertyValues) {
            Object.keys(propertyValues).forEach(valueId => {
              if (propertyValues[+valueId].valueId) {
                const price =
                  props.config.properties[+propertyId].inputs[+valueId].options[
                    +propertyValues[+valueId].valueId
                  ]?.price;

                if (
                  props.config.properties[+propertyId].inputs[+valueId]?.optionsDepends?.product[
                    props.session[props.id].id
                  ] &&
                  props.config.properties[+propertyId].inputs[+valueId]?.optionsDepends?.product[
                    props.session[props.id].id
                  ][+propertyValues[+valueId].valueId]?.price
                ) {
                  const priceDependingOption =
                    props.config.properties[+propertyId].inputs[+valueId]?.optionsDepends?.product[
                      props.session[props.id].id
                    ][+propertyValues[+valueId].valueId]?.price ?? null;

                  if (priceDependingOption.fix) {
                    fix += priceDependingOption.value;
                  } else {
                    perm2 += priceDependingOption.value;
                  }
                } else if (price) {
                  if (price.fix) {
                    fix += price.value;
                  } else {
                    perm2 += price.value;
                  }
                }
              }
            });
          } else if (props.session[props.id]?.properties[+propertyId]?.subcategories) {
            const properties = props.session[props.id]?.properties[+propertyId]?.subcategories;
            Object.keys(properties).forEach(valueId => {
              if (properties[+valueId].valueId) {
                const price =
                  props.config.properties[+propertyId].subcategories[+valueId].inputs[1].options[
                    +properties[+valueId].valueId
                  ].price;
                if (price.fix) {
                  fix += price.value;
                } else {
                  perm2 += price.value;
                }
              }
            });
          }
        });
      }

      const amount = props.session[props.id].amount;
      const price = m2 * perm2 + fix;
      const totalPrice = price * amount;

      dispatch(
        previewAddProperty({
          previewProperty: 'price',
          value: { value: price }
        })
      );
      dispatch(sessionProductPrice({ id: props.id, price }));
    }
  }, [props.session[props.id]?.change, props.preview.type?.value, props.session[props.id]?.amount]);

  const handleClickOnDone = () => {
    config(null);
    dispatch(UITogglePanels({ config: false }));
  };

  return (
    <div
      className='config-panel'
      ref={panelRef}
      style={{
        top: props.screen.tabletPort ? (props.maximized ? 0 : '50%') : 0,
        height: props.screen.tabletPort ? (props.maximized ? '100%' : '50%') : '100%'
      }}
    >
      {properties.length > 0 && (
        <>
          {props.screen.tabletPort && (
            <Arrow
              className='config-panel__arrow'
              style={{ transform: `rotate(${props.maximized ? '180deg' : 0})` }}
              onClick={() => {
                dispatch(configPanelMaximize(!props.maximized));
              }}
            />
          )}
          <div className='config-panel__top-space'></div>
          <h1 className='config-panel__product-title'>
            {props.session[props.id] && props.session[props.id].labels[props.language]}
          </h1>
          {/* <h1 className='config-panel__main-price'>{props.showPrice ? '  ' : ''}</h1> */}
          <div className='config-panel__properties'>
            <hr />
            {properties.map(propertie => propertie)}
          </div>
          <div className='config-panel__bottom'>
            {props.preview.price?.value ? (
              <div className='config-panel__bottom-price'>
                <h1 className='config-panel__bottom-price-main'>
                  {props.currencyConfig.side === 'left' ? props.currencyConfig.symbol : ''}
                  {Math.round(props.preview.price.value * props.session[props.id]?.amount)}.00
                  {props.currencyConfig.side === 'right' ? ' ' + props.currencyConfig.symbol : ''}
                </h1>
                {/* <p className='config-panel__bottom-price-sub'>1200.00 KM + 230.00 KM u dodacima</p> */}
              </div>
            ) : null}
            <div className='config-panel__bottom-actions'>
              <AmountInput
                onChange={value => dispatch(changeProductAmount({ entry: props.id, value }))}
                value={props.session[props.id]?.amount}
              />
              <Button label={props.translation.done} onClick={() => handleClickOnDone()} />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    config: state.config,
    session: state.session,
    id: state.UI.config,
    screen: state.UI.screen,
    preview: state.preview,
    language: state.UI.language,
    showPrice: state.config.general.showPrice,
    maximized: state.UI.configPanel.maximized,
    translation: state.translation,
    currencyConfig: state.config.general.currency[state.UI.currency]
  };
};

export default connect(mapStateToProps)(ConfigPanel);
