import React from 'react';
import './textInput.scss';

interface TextInputInterface extends React.InputHTMLAttributes<HTMLInputElement> {
  searchable?: boolean;
  options?: string[];
  onOptionSelect?: Function;
}

const TextInput: React.SFC<TextInputInterface> = (props: TextInputInterface, HTMLInputElement) => {
  return (
    <div className='text-input'>
      <input type='text' className='text-input__input' {...props} />
      {props.options?.length > 0 && (
        <ul className='text-input__options'>
          {props.options.map(option => (
            <li
              key={option}
              className='text-input__options-option'
              onClick={e => props.onOptionSelect({ target: { name: props.name, value: option } })}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default TextInput;
