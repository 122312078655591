import React, { useState, useEffect } from 'react';
import './door.scss';
import { isColorLight } from './../../../utils/color';

export interface DoorProps {
  color: string;
  view: 'inside' | 'outside';
  scale: number;
  width: number;
  height: number;
  handle: string;
  opening: string;
  wingType: 'main' | 'additional';
  wings: number;
  wingId: number;
}

const Door: React.SFC<DoorProps> = (props: DoorProps) => {
  const [handle, setHandle] = useState<null | HTMLImageElement>(null);

  const scale = (amount: number) => {
    return amount * props.scale;
  };

  useEffect(() => {
    if (props.handle) {
      const handleImg = new Image();
      handleImg.src = require(`../../../assets/products/BalconyDoor/Handle/${props.handle}-preview.png`);
      handleImg.onload = () => setHandle(handleImg);
    }
  }, [props.handle]);

  return (
    <div
      className='door'
      style={{
        // width: scale(props.width / props.wings + 16),
        width: scale(props.width + 16),
        height: scale(props.height + 16),
        margin:
          props.wings === 1
            ? `-${scale(8)}px -${scale(8)}px`
            : props.wingId === 1
            ? `-${scale(8)}px -${0}px -${scale(8)}px -${scale(8)}px`
            : `-${scale(8)}px -${scale(8)}px -${scale(8)}px -${0}px`,
        transform:
          (props.opening === 'leftInside' || props.opening === 'rightOutside') && 'rotateY(180deg)'
      }}
    >
      <div
        className='door__frame-top'
        style={{
          backgroundColor: props.color,
          height: scale(28)
        }}
      >
        <div
          className='door__frame-top-shadow-1'
          style={{
            width: `calc(100% - ${scale(48)}px)`,
            left: scale(24),
            backgroundColor: isColorLight(props.color) ? 'black' : 'black'
          }}
        ></div>
        <div
          className='door__frame-top-corner-line-left'
          style={{
            backgroundColor: isColorLight(props.color) ? 'black' : 'white',
            opacity: isColorLight(props.color) ? 0.15 : 0.1
          }}
        ></div>
        <div
          className='door__frame-top-corner-line-right'
          style={{
            backgroundColor: isColorLight(props.color) ? 'black' : 'white',
            opacity: isColorLight(props.color) ? 0.15 : 0.1
          }}
        ></div>
      </div>
      <div
        className='door__frame-left'
        style={{
          backgroundColor: props.color,
          width: scale(28)
        }}
      >
        <div
          className='door__frame-left-shadow-1'
          style={
            {
              // backgroundColor: isColorLight(props.color) ? 'black' : 'white'
            }
          }
        ></div>
      </div>
      <div className='door__space'></div>
      <div
        className='door__frame-right'
        style={{
          backgroundColor: props.color,
          width: scale(28)
        }}
      >
        {handle?.src &&
          props.wingType === 'main' &&
          (((props.opening === 'leftInside' || props.opening === 'rightInside') &&
            props.view === 'inside') ||
            ((props.opening === 'leftOutside' || props.opening === 'rightOutside') &&
              props.view === 'outside')) && (
            <img
              className='door__handle'
              src={handle.src}
              style={{
                bottom: scale(230),
                width: scale(handle.width),
                height: scale(handle.height)
              }}
            />
          )}
        <div
          className='door__frame-right-shadow-1'
          style={
            {
              // backgroundColor: isColorLight(props.color) ? 'black' : 'white'
            }
          }
        ></div>
      </div>
      <div
        className='door__frame-bottom'
        style={{
          backgroundColor: props.color,
          height: scale(28)
        }}
      >
        <div
          className='door__frame-bottom-shadow-1'
          style={{
            width: `calc(100% - ${scale(48)}px)`,
            left: scale(24),
            backgroundColor: isColorLight(props.color) ? 'black' : 'white'
          }}
        ></div>
        <div
          className='door__frame-bottom-corner-line-left'
          style={{
            backgroundColor: isColorLight(props.color) ? 'black' : 'white',
            opacity: isColorLight(props.color) ? 0.15 : 0.1
          }}
        ></div>
        <div
          className='door__frame-bottom-corner-line-right'
          style={{
            backgroundColor: isColorLight(props.color) ? 'black' : 'white',
            opacity: isColorLight(props.color) ? 0.15 : 0.1
          }}
        ></div>
      </div>
    </div>
  );
};

export default Door;
