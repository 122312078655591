export const hexToRgb = (hex: string): null | { r: number; g: number; b: number } => {
  var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  hex = hex.replace(shorthandRegex, function(m, r, g, b) {
    return r + r + g + g + b + b;
  });

  var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16)
      }
    : null;
};

function rgbToHex(r: number, g: number, b: number) {
  return '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

export function shadeHexColor(color, percent) {
  if (
    color === '#fff' ||
    (color === '#ffffff' && percent > 0) ||
    color === '#000' ||
    (color === '#000000' && percent < 0)
  ) {
    return color;
  } else {
    var f = parseInt(color.slice(1), 16),
      t = percent < 0 ? 0 : 255,
      p = percent < 0 ? percent * -1 : percent,
      R = f >> 16,
      G = (f >> 8) & 0x00ff,
      B = f & 0x0000ff;
    return (
      '#' +
      (
        0x1000000 +
        (Math.round((t - R) * p) + R) * 0x10000 +
        (Math.round((t - G) * p) + G) * 0x100 +
        (Math.round((t - B) * p) + B)
      )
        .toString(16)
        .slice(1)
    );
  }
}

export const isColorLight = (hex: string) => {
  const rgbSum = Object.values(hexToRgb(hex)).reduce((acc, curr) => acc + curr);
  return rgbSum / 3 > 200 ? true : false;
};

export const isColorLighterThan = (hex: string, lighterThan) => {
  const rgbSum = Object.values(hexToRgb(hex)).reduce((acc, curr) => acc + curr);
  return rgbSum / 3 > lighterThan ? true : false;
};

export const rgbAverage = (hex: string) => {
  return Object.values(hexToRgb(hex)).reduce((acc, curr) => acc + curr) / 3;
};
