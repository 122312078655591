const notification = async (props: {
  x: number;
  y: number;
  label: string;
  position: 'left' | 'center' | 'right';
}) => {
  const notification = document.createElement('div');
  const notificationLabel = document.createElement('p');
  const x = document.createElement('svg');
  notificationLabel.innerText = props.label;
  x.innerHTML = `<svg width="18" height="18" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M16.192 6.34399L11.949 10.586L7.70703 6.34399L6.29303 7.75799L10.535 12L6.29303 16.242L7.70703 17.656L11.949 13.414L16.192 17.656L17.606 16.242L13.364 12L17.606 7.75799L16.192 6.34399Z" fill="#1C3549"/>
  </svg>
  `;

  x.style.position = 'absolute';
  x.style.top = '5px';
  x.style.right = '5px';
  x.style.opacity = '0';
  x.style.transition = 'all 0.2s';
  x.style.cursor = 'pointer';

  notification.addEventListener('mouseenter', () => {
    x.style.opacity = '1';
  });
  notification.addEventListener('mouseleave', () => {
    x.style.opacity = '0';
  });
  notification.addEventListener('click', () => {
    document.body.removeChild(notification);
  });

  notification.appendChild(notificationLabel);
  notification.appendChild(x);
  notification.style.padding = '1rem 2.5rem';
  notification.style.position = 'absolute';
  notification.style.backgroundColor = 'white';
  notification.style.left = props.x + 'px';
  notification.style.top = props.y + 'px';
  notification.style.zIndex = '50';
  notification.style.borderRadius = '10px';
  notification.style.boxShadow = '0 3px 10px rgba(0,0,0,0.12)';
  notification.style.transform =
    props.position === 'center'
      ? 'translateX(-50%)'
      : props.position === 'left' && 'translateX(-100%)';
  notification.style.animation = 'fadeIn 0.2s ease-in-out';

  notificationLabel.style.whiteSpace = 'noWrap';

  document.body.insertAdjacentElement('afterbegin', notification);

  setTimeout(() => {
    notification.style.animation = 'fadeOut 0.2s ease-in-out both';
    setTimeout(() => {
      if (document.body.contains(notification)) {
        document.body.removeChild(notification);
      }
    }, 180);
  }, 4000);
};

export default notification;
