export interface House {
  facade: {
    open: boolean;
    value: string;
  };
  wallColor: {
    open: boolean;
    value: string;
  };
  scenes: {
    open: boolean;
    value: string;
  };
  insert: {
    open: boolean;
    value: string;
  };
}

const initState: House = {
  facade: {
    open: false,
    value: '#9f8f89'
  },
  wallColor: {
    open: false,
    value: '#c5c8c9'
  },
  scenes: {
    open: false,
    value: ''
  },
  insert: {
    open: false,
    value: ''
  }
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case 'HOUSE_CHANGE_VALUE':
      return {
        ...state,
        [action.payload.property]: {
          ...state[action.payload.property],
          value: action.payload.value
        }
      };
    case 'HOUSE_TOGGLE_PROPERTY':
      const nextState = {};
      Object.keys(state).forEach(
        propertyKey =>
          (nextState[propertyKey] = {
            ...state[propertyKey],
            open: propertyKey === action.payload.property ? !state[propertyKey].open : false
          })
      );
      return nextState;
    default:
      return state;
  }
};

export default reducer;
