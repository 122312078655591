import store, { State } from '../../store/store';
import React from 'react';
import { previewAddProperty, previewChangeProperty } from './../../store/preview/actions';
import DimensionInput from '../../components/dimensionInput/dimensionInput';
import ImageSelect from '../../components/imageSelect/imageSelect';
import SelectInput from '../../components/selectInput/selectInput';
import ColorSelect from '../../components/colorSelect/colorSelect';
import { changeProductValue, removeInputValue } from './../../store/session/actions';
import ImageOptionSelect from '../../components/imageOptionSelect/imageOptionSelect';
import { ProductsConfig } from '../../store/config/reducer';
import CheckBox from '../../components/checkbox/checkbox';

const dispatch = (action: any) => store.dispatch(action);

const handleOnChange = (
  value: string | number | object,
  propertyId: number,
  inputId: number,
  id: number,
  subcategory: number | null,
  session: any,
  inputConfig?: any,
  onlyOne?: boolean,
  option?: string | number,
  optionId?: number
) => {
  const getValueId = value => {
    let valueId: any = null;
    if (inputConfig.optionsDepends?.product[session[id].id]) {
      Object.keys(inputConfig.optionsDepends.product[session[id].id]).forEach(optionId => {
        if (inputConfig.optionsDepends.product[session[id].id][optionId].value === value) {
          valueId = inputConfig.optionsDepends.product[session[id].id][optionId].id;
        }
      });
    } else {
      Object.keys(inputConfig.options).forEach(optionId => {
        if (inputConfig.options[optionId].value) {
          if (inputConfig.options[optionId].value === value) {
            valueId = +inputConfig.options[optionId].id;

            // deprecated
          } else if (inputConfig.options[optionId].labels.en === value) {
            valueId = +optionId;
          }
        }
      });
    }
    return valueId;
  };

  dispatch(
    changeProductValue({
      id: id,
      propertyId,
      value,
      inputId,
      subcategory,
      onlyOne: onlyOne,
      valueId: getValueId(value),
      option,
      optionId
    })
  );
  // @ts-ignore
  inputConfig.preview &&
    dispatch(
      previewChangeProperty({
        previewProperty: inputConfig.preview,
        // @ts-ignore
        value: typeof value === 'object' ? value.value : value
      })
    );
};

const inputGenerator = (
  id: number,
  inputConfig: any,
  property: string,
  inputId?: number,
  subcategoryId?: number | null,
  propertyId?: number,
  onlyOne?: boolean
) => {
  const state: State = store.getState();
  const session: ProductsConfig = state.session;
  const language = state.UI.language;

  inputConfig.preview &&
    dispatch(
      previewAddProperty({
        previewProperty: inputConfig.preview,
        value: {
          value: !subcategoryId
            ? session[id].properties[propertyId].values[inputId].value
            : session[id].properties[propertyId].subcategories[subcategoryId].value
        }
      })
    );

  const option = !subcategoryId
    ? session[id].properties[propertyId].values[inputId].option
    : session[id].properties[propertyId].subcategories[subcategoryId].option;
  inputConfig.preview &&
    option &&
    dispatch(previewAddProperty({ previewProperty: inputConfig.preview, value: { option } }));

  const getValueId = value => {
    let valueId: any = null;
    if (inputConfig.optionsDepends?.product[session[id].id]) {
      Object.keys(inputConfig.optionsDepends.product[session[id].id]).forEach(optionId => {
        if (inputConfig.optionsDepends.product[session[id].id][optionId].value === value) {
          valueId = inputConfig.optionsDepends.product[session[id].id][optionId].id;
        }
      });
    } else {
      Object.keys(inputConfig.options).forEach(optionId => {
        if (inputConfig.options[optionId].value) {
          if (inputConfig.options[optionId].value === value) {
            valueId = +inputConfig.options[optionId].id;

            // deprecated
          } else if (inputConfig.options[optionId].labels.en === value) {
            valueId = +optionId;
          }
        }
      });
    }
    return valueId;
  };

  const value = subcategoryId
    ? session[id].properties[propertyId].subcategories[subcategoryId].value
    : session[id].properties[propertyId].values[inputId].value;

  const onChange = (value, option?, optionId?) =>
    handleOnChange(
      value,
      propertyId,
      inputId,
      id,
      subcategoryId,
      session,
      inputConfig,
      onlyOne,
      option,
      optionId
    );

  const handleRemove = () => {
    dispatch(
      removeInputValue({
        id,
        propertyId,
        inputId,
        subcategoryId: subcategoryId ? subcategoryId : null
      })
    );
  };

  let inputOptions: any = null;
  if (inputConfig.optionsDepends?.product && inputConfig.optionsDepends?.product[session[id].id]) {
    inputOptions = inputConfig.optionsDepends?.product[session[id].id];
  } else {
    inputOptions = inputConfig.options;
  }

  switch (inputConfig.type) {
    case 'dimension':
      let depending: any = null;
      if (
        inputConfig.optionsDepends?.product &&
        inputConfig.optionsDepends.product[session[id].id]
      ) {
        if (inputConfig.optionsDepends.product[session[+id].id].depends) {
          const depends = inputConfig.optionsDepends.product[session[id].id].depends;
          if (session[+id].properties[depends.property]) {
            const dependingValue = session[+id].properties[depends.property].values[depends.input];
            depending = depends.values[dependingValue.value];
          }
        } else {
          depending = inputConfig.optionsDepends.product[session[+id].id];
        }
      }
      return (
        <DimensionInput
          key={inputConfig.labels.en}
          min={depending ? depending.min : inputConfig.options.min}
          max={depending ? depending.max : inputConfig.options.max}
          value={value}
          label={inputConfig.labels[language]}
          name={inputConfig.options.name}
          onChange={value => onChange(value)}
        />
      );
    case 'imageSelect':
      return (
        <ImageSelect
          key={session[id].id}
          options={inputOptions}
          product={session[id].type}
          property={property}
          language={language}
          onChange={value => onChange(value)}
          uncheckable={inputConfig.uncheckable ?? false}
          remove={handleRemove}
          oneColumn={inputConfig.oneColumn ?? false}
          value={value}
        />
      );
    case 'imageOptionSelect':
      return (
        <ImageOptionSelect
          key={session[id].id}
          options={inputOptions}
          product={session[id].type}
          property={property}
          language={language}
          onChange={(value, option, optionId) => onChange(value, option, optionId)}
          value={value}
          option={session[id].properties[propertyId].values[inputId].option}
        />
      );
    case 'select':
      let selectOptions: any = null;
      if (
        inputConfig.optionsDepends?.product &&
        inputConfig.optionsDepends?.product[session[id].id]
      ) {
        const selectDependingOptions = inputConfig.optionsDepends?.product[session[id].id];
        selectOptions = Object.keys(selectDependingOptions).map(option => ({
          value: selectDependingOptions[option].value,
          label: selectDependingOptions[option].labels[language]
        }));
      } else {
        selectOptions = Object.keys(inputConfig.options).map(option => ({
          value: inputConfig.options[option].value,
          label: inputConfig.options[option].labels[language]
        }));
      }

      let selectValue = null;
      if (subcategoryId) {
        selectValue = {
          value: session[id].properties[propertyId].subcategories[subcategoryId].value,
          label:
            inputConfig.options[
              getValueId(session[id].properties[propertyId].subcategories[subcategoryId].value)
            ]?.labels[language]
        };
      } else if (inputConfig.optionsDepends?.product[session[id].id]) {
        selectValue = {
          value: session[id].properties[propertyId].values[inputId].value,
          label:
            inputConfig.optionsDepends.product[session[id].id][
              getValueId(session[id].properties[propertyId].values[inputId].value)
            ]?.labels[language]
        };
      } else {
        selectValue = {
          value: session[id].properties[propertyId].values[inputId].value,
          label:
            inputConfig.options[
              getValueId(session[id].properties[propertyId].values[inputId].value)
            ]?.labels[language]
        };
      }

      return (
        <SelectInput
          key={session[id].id}
          // @ts-ignore
          options={selectOptions}
          placeholder={'Izaberite'}
          onChange={value => onChange(value.value)}
          value={selectValue}
          label={inputConfig.labels[language]}
        />
      );
    case 'colorSelect':
      return (
        <ColorSelect
          key={session[id].id}
          options={Object.keys(inputConfig.options).map(option => ({
            value: inputConfig.options[option].value,
            label: inputConfig.options[option].labels[language],
            price: inputConfig.options[option].price.value
          }))}
          onChange={value => onChange(value)}
          uncheckable={inputConfig.uncheckable ?? false}
          remove={handleRemove}
          value={value}
        />
      );
    case 'checkbox':
      return (
        <CheckBox
          key={session[id].id}
          onChange={value => onChange(value)}
          value={value}
          label={inputConfig.labels[language]}
        />
      );
  }
};

export default inputGenerator;
