export const houseChangeValue = (payload: { property: string; value: string }) => {
  return {
    type: 'HOUSE_CHANGE_VALUE',
    payload
  };
};

export const houseToggleProperty = (payload: { property: string }) => {
  return {
    type: 'HOUSE_TOGGLE_PROPERTY',
    payload
  };
};
