import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import UIReducer from './UI/reducer';
import ConfigReducer from './config/reducer';
import SessionReducer from './session/reducer';
import previewReducer from './preview/reducer';
import generalReducer from './general/reducer';
import houseReducer from './house/reducer';
import requestReducer from './request/reducer';
import localizationReducer from './translation/reducer';

const rootReducer = combineReducers({
  UI: UIReducer,
  config: ConfigReducer,
  general: generalReducer,
  session: SessionReducer,
  preview: previewReducer,
  house: houseReducer,
  request: requestReducer,
  translation: localizationReducer
});

// @ts-ignore
const composeEnhancers = compose;

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

export default store;

export type State = ReturnType<typeof rootReducer>;
