import React, { useRef } from 'react';
import './header.scss';
import { useDispatch, connect } from 'react-redux';
import { changeLanguage, changeCurrency, UITogglePanels } from './../../store/UI/action';
import { ReactComponent as SessionIcon } from '../../assets/header-session.svg';
import { ReactComponent as ConfigIcon } from '../../assets/header-config.svg';
import { localizationChangeLanguage } from '../../store/translation/actions';
import { State } from '../../store/store';
import Select from 'react-select';
import notification from './../../utils/notification';
import { Translation } from '../../store/translation/reducer';
import { UIState } from '../../store/UI/reducer';
import { CurrencyConfig } from '../../store/config/reducer';

interface HeaderProps {
  UI: UIState;
  language: string;
  translation: Translation;
  currencyConfig: { [key: string]: CurrencyConfig };
}

const Header = (props: HeaderProps) => {
  const dispatch = useDispatch();
  const configIconRef = useRef<SVGSVGElement | null>(null);

  const selectStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: '1.6rem',
      fontWeight: '600'
    }),
    control: (provided, state) => ({
      ...provided,
      border: 'none',
      borderRadius: 0,
      fontSize: '1.6rem',
      cursor: 'pointer',
      boxShadow: 'none !important',
      width: '65px',

      fontWeight: '600',

      '&:hover': {
        // border: 'solid 0.2px #1c3549  '
      }
    }),
    indicatorSeparator: () => ({
      display: 'none'
    }),
    dropdownIndicator: () => ({
      color: 'black',
      marginLeft: '-2.2rem',
      marginTop: '2px',
      opacity: 0,
      transition: 'all 0.25s ease-in-out',

      '&:hover': {
        opacity: 1
      }
    }),
    container: provided => ({
      ...provided
    })
  };

  const handleOnLanguageChange = (value: { value: string; label: string }) => {
    dispatch(changeLanguage(value));
    dispatch(localizationChangeLanguage(value.value));
  };

  const handleOnConfigClick = () => {
    dispatch(
      UITogglePanels({
        config: props.UI.panels.config ? false : typeof props.UI.config === 'number' ? true : false
      })
    );

    if (typeof props.UI.config !== 'number') {
      notification({
        x: configIconRef.current?.getBoundingClientRect().left,
        y: configIconRef.current?.getBoundingClientRect().y,
        label: props.translation.nothingToConfigure,
        position: 'left'
      });
    }
  };

  return (
    <header>
      <div className='space'></div>
      <h1>Konfigurator</h1>
      <div className='right-holder'>
        <Select
          isSearchable={false}
          styles={selectStyles}
          options={[
            { label: 'YU', value: 'yu' },
            { label: 'EN', value: 'en' }
          ]}
          value={{ value: props.language, label: props.language.toUpperCase() }}
          // @ts-ignore
          onChange={value => handleOnLanguageChange(value)}
        />
        <Select
          isSearchable={false}
          styles={selectStyles}
          options={Object.keys(props.currencyConfig).map(curr => ({
            value: props.currencyConfig[curr].value,
            label: props.currencyConfig[curr].label
          }))}
          value={{ value: props.UI.currency, label: props.currencyConfig[props.UI.currency].label }}
          // @ts-ignore
          onChange={value => dispatch(changeCurrency(value))}
        />

        <div className='right-holder-icons'>
          <ConfigIcon
            className={'right-holder-icons-icon' + (props.UI.panels?.config ? ' active' : '')}
            ref={configIconRef}
            onClick={() => handleOnConfigClick()}
          />
          <SessionIcon
            className={'right-holder-icons-icon' + (props.UI.panels?.session ? ' active' : '')}
            onClick={() =>
              dispatch(
                UITogglePanels({
                  session: !props.UI.panels.session,
                  config: !props.UI.panels.session ? false : props.UI.panels.config
                })
              )
            }
          />
        </div>
      </div>
    </header>
  );
};

const mapStateToProps = (state: State) => {
  return {
    UI: state.UI,
    currencyConfig: state.config.general.currency,
    language: state.UI.language,
    translation: state.translation
  };
};

export default connect(mapStateToProps)(Header);
