import React from 'react';
import './imageOptionSelect.scss';
import { useDispatch } from 'react-redux';
import { setInfoCard } from '../../store/preview/actions';
import RadioInput from '../radioInput/radioInput';

export interface ImageOptionSelectProps {
  options: {
    [key: number]: {
      id: number;
      labels: {
        yu: string | number;
        en: string | number;
      };
      price: {
        value: number;
        fix?: boolean;
      };
      value: string | number;
      options: {
        [key: number]: {
          id: number;
          valueId?: number;
          value: string | number;
          labels: { yu: string; en: string };
        };
      };
    };
  };
  product: string;
  property: string;
  value: string | number;
  option: string | number;
  language: string;
  onChange: Function;
}

const ImageOptionSelect: React.SFC<ImageOptionSelectProps> = (props: ImageOptionSelectProps) => {
  const dispatch = useDispatch();

  const handleOnChange = (value: string | number, option: string | number, optionId: number) => {
    props.onChange(value, option, optionId);
  };

  return (
    <div className='image-option-select'>
      {Object.keys(props.options).map((optionId, index) => {
        return (
          <React.Fragment key={index + optionId}>
            <div
              className='image-option-select__option'
              onMouseEnter={() =>
                props.options[optionId].info &&
                dispatch(
                  setInfoCard({
                    ...props.options[optionId].info,
                    label: props.options[optionId].labels[props.language],
                    img: `${props.product}/${props.property}/${props.options[optionId].labels.en}.png`
                  })
                )
              }
              onMouseLeave={() => props.options[optionId].info && dispatch(setInfoCard({}))}
            >
              <img
                src={require(`../../assets/products/${props.product}/${props.property}/${props.options[optionId].labels.en}.png`)}
              />

              <div className='image-option-select__option__options'>
                {Object.keys(props.options[+optionId].options).map(optionOptionId => (
                  <div className='image-option-select__option__options-input-holder'>
                    <RadioInput
                      name={props.options[+optionId].options[+optionOptionId].value.toString()}
                      label={props.options[+optionId].options[+optionOptionId].labels.yu}
                      value={props.value}
                      checked={
                        props.option === props.options[+optionId].options[+optionOptionId].value
                      }
                      onChange={value =>
                        handleOnChange(
                          props.options[+optionId].value,
                          props.options[+optionId].options[+optionOptionId].value,
                          +optionOptionId
                        )
                      }
                    />
                  </div>
                ))}
              </div>
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default ImageOptionSelect;
