import React from 'react';
import './infoCard.scss';
import StatusBar from './statusBar/statusBar';

interface InfoCardProps {
  label: string;
  info: { yu: string; en: string };
  img: string;
  bars?: {
    [key: number]: {
      labels: { yu: string; en: string };
      precent: number;
    };
  };
}

const InfoCard: React.SFC<InfoCardProps> = (props: InfoCardProps) => {
  return (
    <div className='info-card'>
      <div className='info-card__info-container'>
        <h1 className='info-card__info-container__label'>{props.label}</h1>
        <p className='info-card__info-container__info'>{props.info?.yu}</p>
        {props.bars && (
          <div className='info-card__info-container__status'>
            <div className='info-card__info-container__status-lines'>
              <div className='info-card__info-container__status-lines-line'></div>
              <div className='info-card__info-container__status-lines-line'></div>
              <div className='info-card__info-container__status-lines-line'></div>
              <div className='info-card__info-container__status-lines-line'></div>
            </div>
            {Object.keys(props.bars).map(barId => (
              <StatusBar precent={props.bars[barId].precent} label={props.bars[barId].labels.yu} />
            ))}
          </div>
        )}
      </div>
      <div className='info-card__preview'>
        <img
          src={props.img && require(`../../assets/products/${props.img}`)}
          alt=''
          className='info-card__preview__img'
        />
      </div>
    </div>
  );
};

export default InfoCard;
