import React from 'react';
import './additionalPanelTop.scss';

export interface AdditionalPanelTopProps {
  frameColor: string;
  color: string;
  height: number;
  gridColumn: string;
  scale: number;
}

const AdditionalPanelTop: React.SFC<AdditionalPanelTopProps> = (props: AdditionalPanelTopProps) => {
  return (
    <div
      className='frame__additional-panel'
      style={{ gridColumn: props.gridColumn, gridRow: '2 / 3' }}
    >
      <div
        className='space'
        style={{ width: '100%', height: props.height * props.scale + 'px' }}
      ></div>
      <div
        className='frame__additional-bottom'
        style={{
          backgroundColor: props.frameColor ? props.frameColor : props.color,
          height: 23 * props.scale
        }}
      >
        <div className='frame__additional-bottom-shadow-1'></div>
        <div className='frame__additional-bottom-shadow-2'></div>
        <div className='frame__additional-bottom-shadow-3'></div>
        <div className='frame__additional-bottom-shadow-4'></div>
      </div>
    </div>
  );
};

export default AdditionalPanelTop;
