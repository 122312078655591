import * as React from 'react';
import './amountInput.scss';

export interface AmountInputProps {
  value: number;
  onChange: Function;
}

const AmountInput: React.SFC<AmountInputProps> = (props: AmountInputProps) => {
  return (
    <input
      type='number'
      className='amount-input'
      value={props.value}
      onChange={e => props.onChange(+e.target.value)}
      min={1}
    />
  );
};

export default AmountInput;
