import React from 'react';
import './pento.scss';
import { shadeHexColor } from '../../../../utils/color';
import { rgbAverage } from './../../../../utils/color';

export interface PentoBoxProps {
  color: string;
}

const PentoBox: React.SFC<PentoBoxProps> = (props: PentoBoxProps) => {
  return (
    <div
      className='pento-box'
      style={{
        backgroundColor: shadeHexColor(props.color, 0),
        borderTop: `solid 1px ${shadeHexColor(props.color, 0.35)}`,
        borderLeft: `solid 2px ${shadeHexColor(props.color, 0.35)}`
      }}
    >
      <div className='pento-box-top-light'>
        <div
          className='pento-box-top-light-shade-2'
          style={{ backgroundColor: shadeHexColor(props.color, -0.04) }}
        ></div>
        <div
          className='pento-box-top-light-shade'
          style={{ backgroundColor: shadeHexColor(props.color, -0.08) }}
        ></div>
        <div
          className='pento-box-top-light-light'
          style={{ backgroundColor: shadeHexColor(props.color, 0.45) }}
        ></div>
        <div
          className='pento-box-top-light-light-2'
          style={{ backgroundColor: shadeHexColor(props.color, 0.2) }}
        ></div>
      </div>
      <img
        className='pento-box-bottom-light'
        src={require('../../../../assets/preview/persiennes-bottom-light.png')}
        style={{ opacity: rgbAverage(props.color) / 255 }}
      />
    </div>
  );
};

export default PentoBox;
