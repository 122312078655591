import React from 'react';
import './imageSelect.scss';
import Checkmark from '../checkmark/checkmark';
import { useDispatch } from 'react-redux';
import { setInfoCard } from '../../store/preview/actions';

export interface ImageSelectProps {
  options: Object;
  product: string;
  property: string;
  value: string | number;
  language: string;
  uncheckable: boolean;
  remove?: Function;
  oneColumn?: boolean;
  onChange: Function;
}

const ImageSelect: React.SFC<ImageSelectProps> = (props: ImageSelectProps) => {
  const dispatch = useDispatch();

  const handleOnChange = (value: string) => {
    props.onChange(value);
  };

  return (
    <div
      className='image-select'
      style={{ gridTemplateColumns: props.oneColumn ? '1fr' : '1fr 1fr' }}
    >
      {Object.keys(props.options).map((optionId, index) => {
        return (
          <React.Fragment key={index + optionId}>
            <div
              className='image-select__option'
              onClick={() =>
                props.options[optionId].value !== props.value &&
                handleOnChange(props.options[optionId].value)
              }
              onMouseEnter={() =>
                props.options[optionId].info &&
                dispatch(
                  setInfoCard({
                    ...props.options[optionId].info,
                    label: props.options[optionId].labels[props.language],
                    img: `${props.product}/${props.property}/${props.options[optionId].value}.png`
                  })
                )
              }
              onMouseLeave={() => props.options[optionId].info && dispatch(setInfoCard({}))}
            >
              <div className='image-select__img-wrapper'>
                {props.options[optionId].value === props.value && (
                  <Checkmark uncheckable={props.uncheckable} onClick={() => props.remove()} />
                )}
                <img
                  src={require(`../../assets/products/${props.product}/${props.property}/${props.options[optionId].value}.png`)}
                />
              </div>
              <p className='image-select__option-label'>
                {props.options[optionId].labels[props.language]}
              </p>
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default ImageSelect;
