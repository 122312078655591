import React, { useState, useEffect, useRef } from 'react';
import './actionPanel.scss';
import { connect, useDispatch } from 'react-redux';
import { State } from '../../store/store';
import { UITogglePanels } from './../../store/UI/action';

export interface ActionPanelProps {
  UI: any;
  action: string;
  label: string;
  children: any;
  description?: string;
}

const ActionPanel: React.SFC<ActionPanelProps> = (props: ActionPanelProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const panelRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }
    return () => document.removeEventListener('click', handleClickOutside);
  }, [isOpen]);

  const handleClickOutside = async (e: MouseEvent) => {
    if (!panelRef.current.contains(e.target)) {
      setIsOpen(false);
      dispatch(UITogglePanels({ [props.action]: false }));
    }
  };

  return (
    <div className='action-panel' ref={panelRef}>
      <h1 className='action-panel__label'>{props.label}</h1>

      {props.description && <p className='action-panel__description'>{props.description}</p>}
      <hr className='action-panel__hr' />
      {props.children}
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    UI: state.UI.panels
  };
};

export default connect(mapStateToProps)(ActionPanel);
