import React from 'react';
import './panel.scss';
import { isColorLight } from './../../utils/color';

export interface PanelProps {
  color: string;
  width: number;
  height: number;
  scale: number;
  view: 'inside' | 'outside';
  glass: string;
}

const Panel: React.SFC<PanelProps> = (props: PanelProps) => {
  const scale = (amount: number) => {
    return amount * props.scale;
  };

  return (
    <div
      className='panel-product'
      style={{ width: props.width * props.scale, height: props.height * props.scale }}
    >
      <div
        className='backview'
        style={{
          backgroundImage: `url(${require(`../../assets/preview/backview-${props.glass}.jpg`)})`
        }}
      ></div>
      <div
        className='panel-product__top'
        style={{
          backgroundColor: props.color,
          gridColumn: `1 / -1`,
          gridRow: '1 / 2',
          height: `calc(23px * ${props.scale})`
        }}
      >
        <div
          className='panel-product__top-shadow-1'
          style={{
            width: `calc(100% - 40px * ${props.scale})`,
            left: 20 * props.scale,
            backgroundColor: isColorLight(props.color) ? 'black' : 'white'
          }}
        ></div>
        <div
          className='panel-product__top-shadow-2'
          style={{
            width: `calc(100% - 44px * ${props.scale})`,
            left: 22 * props.scale,
            opacity: isColorLight(props.color) ? 0.15 : 0.2
          }}
        ></div>
        <div
          className='panel-product__top-corner-line-left'
          style={{
            backgroundColor: isColorLight(props.color) ? 'black' : 'white',
            opacity: isColorLight(props.color) ? 0.1 : 0.1
          }}
        ></div>
        <div
          className='panel-product__top-corner-line-right'
          style={{
            backgroundColor: isColorLight(props.color) ? 'black' : 'white',
            opacity: isColorLight(props.color) ? 0.1 : 0.1
          }}
        ></div>
      </div>

      <div
        className='panel-product__left'
        style={{
          backgroundColor: props.color,
          gridColumn: '1 / 2',
          width: `calc(23px * ${props.scale})`
        }}
      >
        <div
          className='panel-product__left-shadow-1'
          style={{
            backgroundColor: isColorLight(props.color) ? 'black' : 'white'
          }}
        ></div>
        <div
          className='panel-product__left-shadow-2'
          style={{
            opacity: isColorLight(props.color) ? 0.15 : 0.2
          }}
        ></div>
      </div>

      <div
        className='panel-product-space'
        style={{ width: scale(props.width - 46), height: scale(props.height - 46) }}
      ></div>

      <div
        className='panel-product__right'
        style={{
          backgroundColor: props.color,
          width: `calc(23px * ${props.scale})`
        }}
      >
        <div
          className='panel-product__right-shadow-1'
          style={{
            backgroundColor: isColorLight(props.color) ? 'black' : 'white'
          }}
        ></div>
        <div
          className='panel-product__right-shadow-2'
          style={{
            opacity: isColorLight(props.color) ? 0.15 : 0.2
          }}
        ></div>
      </div>

      <div
        className='panel-product__bottom'
        style={{
          backgroundColor: props.color,
          gridColumn: `1 / -1`,
          gridRow: '3 / 4',
          height: `calc(23px * ${props.scale})`
        }}
      >
        <div
          className='panel-product__bottom-shadow-1'
          style={{
            width: `calc(100% - 40px * ${props.scale})`,
            left: 20 * props.scale,
            backgroundColor: isColorLight(props.color) ? 'black' : 'white'
          }}
        ></div>
        <div
          className='panel-product__bottom-shadow-2'
          style={{
            width: `calc(100% - 44px * ${props.scale})`,
            left: 22 * props.scale,
            opacity: isColorLight(props.color) ? 0.15 : 0.2
          }}
        ></div>
        <div
          className='panel-product__bottom-corner-line-left'
          style={{
            backgroundColor: isColorLight(props.color) ? 'black' : 'white',
            opacity: isColorLight(props.color) ? 0.1 : 0.1
          }}
        ></div>
        <div
          className='panel-product__bottom-corner-line-right'
          style={{
            backgroundColor: isColorLight(props.color) ? 'black' : 'white',
            opacity: isColorLight(props.color) ? 0.1 : 0.1
          }}
        ></div>
      </div>
    </div>
  );
};

export default Panel;
