import axios from '../../utils/axios';
import api from '../../api.json';

export const requestFieldOnChange = (payload: { fieldName: string; value: string | number }) => {
  return {
    type: 'REQUEST_FIELD_ON_CHANGE',
    payload
  };
};

export const requestLocationAutocomplete = (payload: {
  value: string | number;
  sessiontoken: any;
}) => {
  return dispatch => {
    dispatch({
      type: 'REQUEST_LOCATION_AUTOCOMPLETE',
      payload: []
    });

    if (payload.value.toString().length > 4) {
      axios
        .get(
          `${api.locationAutocomplete}input=${payload.value}&sessiontoken=${payload.sessiontoken}&key=AIzaSyBInzpJztjpUcPKXDi-vHOCcaJ_3l3MNIw`
        )
        .then(res => {
          const result = res.data.predictions.map(predict => predict.description);
          dispatch({
            type: 'REQUEST_LOCATION_AUTOCOMPLETE',
            payload: result
          });
        });

      // const result = data.predictions.map(predict => predict.description);
      // dispatch({
      //   type: 'REQUEST_LOCATION_AUTOCOMPLETE',
      //   payload: result
      // });
    }
  };
};

export const requestLocationAutocompleteReset = () => {
  return {
    type: 'REQUEST_LOACTION_AUTOCOMPLETE_RESET'
  };
};

const data = {
  predictions: [
    {
      description: 'Prnjavor, Bosna i Hercegovina',
      id: 'a8439612775c9b61c359c210bf3e230b90515aa8',
      matched_substrings: [
        {
          length: 8,
          offset: 0
        }
      ],
      place_id: 'ChIJoYlSUlTeXUcRvc3enX_Xr50',
      reference: 'ChIJoYlSUlTeXUcRvc3enX_Xr50',
      structured_formatting: {
        main_text: 'Prnjavor',
        main_text_matched_substrings: [
          {
            length: 8,
            offset: 0
          }
        ],
        secondary_text: 'Bosna i Hercegovina'
      },
      terms: [
        {
          offset: 0,
          value: 'Prnjavor'
        },
        {
          offset: 10,
          value: 'Bosna i Hercegovina'
        }
      ],
      types: ['locality', 'political', 'geocode']
    },
    {
      description: 'Prnjavor, Srbija',
      id: '563ce183d534f202c9ea22aa4beb059d77834f81',
      matched_substrings: [
        {
          length: 8,
          offset: 0
        }
      ],
      place_id: 'ChIJ689xosDEW0cRZB2O9C6W6Dc',
      reference: 'ChIJ689xosDEW0cRZB2O9C6W6Dc',
      structured_formatting: {
        main_text: 'Prnjavor',
        main_text_matched_substrings: [
          {
            length: 8,
            offset: 0
          }
        ],
        secondary_text: 'Srbija'
      },
      terms: [
        {
          offset: 0,
          value: 'Prnjavor'
        },
        {
          offset: 10,
          value: 'Srbija'
        }
      ],
      types: ['locality', 'political', 'geocode']
    },
    {
      description: 'Prnjavor, Ripanj, Srbija',
      id: '01c149d97d8e12716f9db57896ced9da828dbb12',
      matched_substrings: [
        {
          length: 8,
          offset: 0
        }
      ],
      place_id:
        'EhhQcm5qYXZvciwgUmlwYW5qLCBTcmJpamEiLiosChQKEgl_7sZXWnVaRxHoCr0-TUyJExIUChIJrwbfPEV1WkcRtsu4_ti7pvs',
      reference:
        'EhhQcm5qYXZvciwgUmlwYW5qLCBTcmJpamEiLiosChQKEgl_7sZXWnVaRxHoCr0-TUyJExIUChIJrwbfPEV1WkcRtsu4_ti7pvs',
      structured_formatting: {
        main_text: 'Prnjavor',
        main_text_matched_substrings: [
          {
            length: 8,
            offset: 0
          }
        ],
        secondary_text: 'Ripanj, Srbija'
      },
      terms: [
        {
          offset: 0,
          value: 'Prnjavor'
        },
        {
          offset: 10,
          value: 'Ripanj'
        },
        {
          offset: 18,
          value: 'Srbija'
        }
      ],
      types: ['route', 'geocode']
    },
    {
      description: 'Prnjavor, Hrvatska',
      id: '8d6a7fb1812b9bc9f960cc590831dc7ed57a8134',
      matched_substrings: [
        {
          length: 8,
          offset: 0
        }
      ],
      place_id: 'ChIJWWXdsZlOXEcRaK2Kv5YgjxM',
      reference: 'ChIJWWXdsZlOXEcRaK2Kv5YgjxM',
      structured_formatting: {
        main_text: 'Prnjavor',
        main_text_matched_substrings: [
          {
            length: 8,
            offset: 0
          }
        ],
        secondary_text: 'Hrvatska'
      },
      terms: [
        {
          offset: 0,
          value: 'Prnjavor'
        },
        {
          offset: 10,
          value: 'Hrvatska'
        }
      ],
      types: ['locality', 'political', 'geocode']
    },
    {
      description: 'Prnjavorska, Sarajevo, Bosna i Hercegovina',
      id: '4ae16bfb86f8afd8483b49291c9f73958fc4e601',
      matched_substrings: [
        {
          length: 8,
          offset: 0
        }
      ],
      place_id:
        'EipQcm5qYXZvcnNrYSwgU2FyYWpldm8sIEJvc25hIGkgSGVyY2Vnb3ZpbmEiLiosChQKEgkfkqlKC8lYRxFNMCGCRoa1nhIUChIJ0Ztx7bHLWEcRPrOH3qbNLlY',
      reference:
        'EipQcm5qYXZvcnNrYSwgU2FyYWpldm8sIEJvc25hIGkgSGVyY2Vnb3ZpbmEiLiosChQKEgkfkqlKC8lYRxFNMCGCRoa1nhIUChIJ0Ztx7bHLWEcRPrOH3qbNLlY',
      structured_formatting: {
        main_text: 'Prnjavorska',
        main_text_matched_substrings: [
          {
            length: 8,
            offset: 0
          }
        ],
        secondary_text: 'Sarajevo, Bosna i Hercegovina'
      },
      terms: [
        {
          offset: 0,
          value: 'Prnjavorska'
        },
        {
          offset: 13,
          value: 'Sarajevo'
        },
        {
          offset: 23,
          value: 'Bosna i Hercegovina'
        }
      ],
      types: ['route', 'geocode']
    }
  ],
  status: 'OK'
};
