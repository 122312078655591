import React from 'react';
import './addProductPanel.scss';
import { connect, useDispatch } from 'react-redux';
import { State } from '../../store/store';
import ActionPanel from '../../components/actionPanel/actionPanel';
import { addProduct } from './../../store/session/actions';
import { config } from '../../store/UI/action';
import { ProductsConfig } from './../../store/config/reducer';
import { UITogglePanels } from './../../store/UI/action';
import { UIScreen } from '../../store/UI/reducer';

export interface AddProductPanelProps {
  productsConfig: ProductsConfig;
  session: ProductsConfig;
  language: string;
  translation: any;
  screen: UIScreen;
}

const AddProductPanel: React.SFC<AddProductPanelProps> = (props: AddProductPanelProps) => {
  const dispatch = useDispatch();

  const handleOnClick = product => {
    const nextId = Object.keys(props.session).length;
    dispatch(addProduct(product));
    dispatch(config(nextId));
    if (props.screen.tabletPort) {
      dispatch(UITogglePanels({ config: true, session: false }));
    } else {
      dispatch(UITogglePanels({ config: true }));
    }
    dispatch(UITogglePanels({ addProduct: false }));
  };

  return (
    <ActionPanel action='addProduct' label={props.translation.actionPanel.addProduct.addProduct}>
      <div className='add-product-panel__products'>
        {Object.keys(props.productsConfig).map((product, index) => (
          <div
            className='add-product-panel__products-product'
            key={index}
            onClick={() => handleOnClick(props.productsConfig[product])}
          >
            <img
              src={require(`../../assets/products/${props.productsConfig[product].type}/thumbnail.svg`)}
              alt=''
              className='add-product-panel__products-product-image'
            />
            <p className='add-product-panel__products-product-label'>
              {props.productsConfig[product].labels[props.language]}
            </p>
          </div>
        ))}
      </div>
    </ActionPanel>
  );
};

const mapStateToProps = (state: State) => {
  return {
    productsConfig: state.config.products,
    session: state.session,
    language: state.UI.language,
    translation: state.translation,
    screen: state.UI.screen
  };
};

export default connect(mapStateToProps)(AddProductPanel);
