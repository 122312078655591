import React from 'react';
import './sessionItem.scss';
import { ReactComponent as X } from '../../../assets/x-small.svg';
import { ReactComponent as Pen } from '../../../assets/pen.svg';

export interface SessionItemProps {
  type: string;
  label: string;
  sublabel: string;
  price?: string;
  onClick: Function;
  onDelete: Function;
}

const SessionItem: React.SFC<SessionItemProps> = (props: SessionItemProps) => {
  return (
    <div className='session-item'>
      <Pen className='session-item__pen' />
      <div className='session-item__icon-holder'>
        <img
          src={require(`../../../assets/products/${props.type}/icon.svg`)}
          alt=''
          className='session-item__icon-holder__img'
        />
      </div>
      <div className='session-item__info'>
        <p className='session-item__info-label'>{props.label}</p>
        <p className='session-item__info-sublabel'>{props.sublabel}</p>
      </div>
      {props.price && <p className='session-item__price'>{props.price}</p>}
      <X className='session-item__delete' onClick={() => props.onDelete()} />
      <div className='session-item__hover' onClick={() => props.onClick()}></div>
    </div>
  );
};

export default SessionItem;
