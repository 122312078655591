import React from 'react';
import EntranceDoorFrame from './frame/entranceDoorFrame';
import Panel from './panel/panel';

interface EntranceDoorProps {
  color: string;
  frameColor: string;
  additionalLeftAmount: number;
  additionalLeftWidth: number;
  additionalRightAmount: number;
  additionalRightWidth: number;
  additionalTopAmount: number;
  additionalTopHeight: number;
  handrail: { value: string | number; option: string | number };
  width: number;
  height: number;
  opening: string;
  scale: number;
  model: string;
  handleInside: string;
  handleOutside: string;
  view: 'inside' | 'outside';
  handrailInside: boolean;
  protection: string;
  rosetteInside: string;
  rosetteOutside: string;
  glass: string;
}

const EntranceDoor = (props: EntranceDoorProps) => {
  return (
    <EntranceDoorFrame
      color={props.frameColor}
      additionalLeftAmount={props.additionalLeftAmount}
      additionalLeftWidth={props.additionalLeftWidth}
      additionalRightAmount={props.additionalRightAmount}
      additionalRightWidth={props.additionalRightWidth}
      additionalTopAmount={props.additionalTopAmount}
      additionalTopHeight={props.additionalTopHeight}
      scale={props.scale}
      view={props.view}
      glass={props.glass}
    >
      <Panel
        model={props.model}
        width={props.width}
        height={props.height}
        color={props.color}
        opening={props.opening}
        handrail={props.handrail}
        handrailInside={props.handrailInside}
        handleInside={props.handleInside}
        handleOutside={props.handleOutside}
        protection={props.protection}
        rosetteInside={props.rosetteInside}
        rosetteOutside={props.rosetteOutside}
        scale={props.scale}
        view={props.view}
        cover={'oneSide'}
      />
    </EntranceDoorFrame>
  );
};

export default EntranceDoor;
