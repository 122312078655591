export const generalToggleOpen = (payload: { property: number; subcategory?: number }) => {
  return {
    type: 'GENERAL_TOGGLE_OPEN',
    payload
  };
};

export const generalChangePropertyValue = (payload: {
  propertyId: number;
  value: string | number | object;
  inputId: number;
  subcategory?: number;
  onlyOne?: boolean;
}) => {
  return {
    type: 'GENERAL_CHANGE_PROPERTY_VALUE',
    payload
  };
};
