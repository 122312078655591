import React, { useState, useEffect, useRef } from 'react';
import './actionsPanel.scss';
import { ReactComponent as HamurgerIcon } from '../../assets/hamburger.svg';
import { ReactComponent as PlusIcon } from '../../assets/plus.svg';
import { ReactComponent as HomeIcon } from '../../assets/home.svg';
import { ReactComponent as ConfigIcon } from '../../assets/config-action.svg';
import { ReactComponent as SaveIcon } from '../../assets/save.svg';
import { ReactComponent as DetailsIcon } from '../../assets/details-action.svg';
import { ReactComponent as SendIcon } from '../../assets/send-action.svg';
import { useDispatch, connect } from 'react-redux';
import { State } from '../../store/store';
import { UITogglePanels } from './../../store/UI/action';
import { UIState } from './../../store/UI/reducer';
import { ProductsConfig } from '../../store/config/reducer';

export interface ActionsPanelProps {
  UI: UIState;
  session: ProductsConfig;
  translation: any;
}

const ActionsPanel: React.SFC<ActionsPanelProps> = (props: ActionsPanelProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const actionsPanelRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }
  }, [isOpen]);

  const handleClickOutside = (e: MouseEvent) => {
    if (!actionsPanelRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  const handleOnClick = value => {
    if (value[Object.keys(value)[0]] === false) {
      setTimeout(() => dispatch(UITogglePanels(value)), 600);
    } else {
      dispatch(UITogglePanels(value));
      setIsOpen(false);
    }
  };

  return (
    <div
      className='actions-panel'
      ref={actionsPanelRef}
      style={{
        width: isOpen ? '26rem' : '5.3rem',
        backgroundColor: isOpen ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.85)'
      }}
    >
      <HamurgerIcon className='actions-panel__hamburger-icon' onClick={() => setIsOpen(!isOpen)} />
      <div className='actions-panel__actions'>
        <div
          className='actions-panel__actions-action'
          onClick={() => handleOnClick({ addProduct: !props.UI.panels.addProduct })}
        >
          <PlusIcon />
          <p className='actions-panel__actions-action-label'>
            {props.translation.actionPanel.addProduct.addProduct}
          </p>
        </div>
        <div
          className='actions-panel__actions-action'
          onClick={() => handleOnClick({ house: !props.UI.panels.house })}
        >
          <HomeIcon />
          <p className='actions-panel__actions-action-label'>
            {props.translation.actionPanel.house.house}
          </p>
        </div>
        <div
          className='actions-panel__actions-action'
          onClick={() => handleOnClick({ general: !props.UI.panels.general })}
        >
          <ConfigIcon />
          <p className='actions-panel__actions-action-label'>
            {props.translation.actionPanel.general.general}
          </p>
        </div>
        <div
          className='actions-panel__actions-action'
          onClick={() => handleOnClick({ save: !props.UI.panels.save })}
        >
          <SaveIcon />
          <p className='actions-panel__actions-action-label'>
            {props.translation.actionPanel.save.save}
          </p>
        </div>
        <div
          className='actions-panel__actions-action'
          onClick={() => handleOnClick({ details: !props.UI.panels.details })}
        >
          <DetailsIcon />
          <p className='actions-panel__actions-action-label'>
            {props.translation.actionPanel.details.details}
          </p>
        </div>
        <div
          className='actions-panel__actions-action'
          onClick={() => handleOnClick({ request: !props.UI.panels.request })}
        >
          <SendIcon />
          <p className='actions-panel__actions-action-label'>
            {props.translation.actionPanel.request.request}
          </p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    UI: state.UI,
    session: state.session,
    translation: state.translation
  };
};

export default connect(mapStateToProps)(ActionsPanel);
