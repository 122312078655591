import { UIActions } from './action';

export interface UIScreen {
  phone: boolean;
  tabletPort: boolean;
  tabletLand: boolean;
}

export interface UIPanels {
  actions: boolean;
  config: boolean;
  session: boolean;
  addProduct: boolean;
  house: boolean;
  general: boolean;
  save: boolean;
  details: boolean;
  request: boolean;
}

export interface UIState {
  panels: UIPanels;
  screen: UIScreen;
  tabs: {
    config: boolean;
    details: boolean;
    request: boolean;
  };
  config: number | null;
  language: 'en' | 'yu';
  currency: string;
  view: 'inside' | 'outside';
  configPanel: {
    maximized: boolean;
  };
}

const initState: UIState = {
  panels: {
    actions: true,
    config: false,
    session: true,
    addProduct: false,
    house: false,
    general: false,
    save: false,
    details: false,
    request: false
  },
  screen: {
    tabletLand: false,
    tabletPort: false,
    phone: false
  },
  tabs: {
    config: true,
    details: false,
    request: false
  },
  config: null,
  language: 'en',
  currency: 'bam',
  view: 'outside',
  configPanel: {
    maximized: false
  }
};

const reducer = (state = initState, action: any) => {
  switch (action.type) {
    case 'UI_TOGGLE_PANELS':
      return {
        ...state,
        panels: {
          ...state.panels,
          ...action.payload
        }
      };
    case 'SCREEN_CHANGE':
      return {
        ...state,
        screen: {
          ...action.payload
        }
      };
    case 'TAB_CHANGE':
      return { ...state, tabs: { ...state.tabs, ...action.payload } };
    case 'UI_CONFIG':
      return {
        ...state,
        config: action.payload
      };
    case 'UI_TOGGLE_VIEW':
      return {
        ...state,
        view: state.view === 'inside' ? 'outside' : 'inside'
      };
    case 'UI_CHANGE_VIEW':
      return {
        ...state,
        view: action.payload
      };
    case 'UI_CHANGE_LANGUAGE':
      return {
        ...state,
        language: action.payload.value
      };
    case 'UI_CHANGE_CURRENCY':
      return {
        ...state,
        currency: action.payload.value
      };
    case 'UI_CONFIG_PANEL_MAXIMIZE':
      return {
        ...state,
        configPanel: {
          ...state.configPanel,
          maximized: action.payload
        }
      };
    default:
      return state;
  }
};

export default reducer;
