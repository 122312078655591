import React from 'react';
import './additionalPanelLeft.scss';

export interface AdditionalPanelProps {
  frameColor: string;
  color: string;
  width: number;
  scale: number;
}

const AdditionalPanelLeft: React.SFC<AdditionalPanelProps> = (props: AdditionalPanelProps) => {
  return (
    <div className='frame__additional'>
      <div className='frame__additional-panel'>
        <div
          className='space'
          style={{ width: props.width * props.scale + 'px', height: '100%' }}
        ></div>
        <div
          className='frame__additional-left-bottom'
          style={{
            backgroundColor: props.frameColor ? props.frameColor : props.color,
            height: 23 * props.scale
          }}
        >
          <div className='frame__additional-left-bottom-shadow-1'></div>
          <div className='frame__additional-left-bottom-shadow-2'></div>
        </div>
      </div>

      <div
        className='frame__additional-left'
        style={{
          backgroundColor: props.frameColor ? props.frameColor : props.color,
          width: 23 * props.scale
        }}
      >
        <div className='frame__additional-left-shadow-1'></div>
        <div className='frame__additional-left-shadow-2'></div>
        <div className='frame__additional-left-shadow-3'></div>
        <div className='frame__additional-left-shadow-4'></div>
      </div>
    </div>
  );
};

export default AdditionalPanelLeft;
