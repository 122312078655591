interface PreviewState {
  price: 0;
}

const initState = {
  infoCard: {}
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case 'PREVIEW_PROPERTY_ADD':
      return {
        ...state,
        [action.payload.previewProperty]: {
          ...state[action.payload.previewProperty],
          ...action.payload.value
        }
      };
    case 'PREVIEW_PROPERTY_CHANGE':
      return {
        ...state,
        [action.payload.previewProperty]: { value: action.payload.value }
      };
    case 'PREVIEW_SET_INFO_CARD':
      return {
        ...state,
        infoCard: action.payload
      };
    case 'PREVIEW_CHANGE':
      return action.payload;
    default:
      return state;
  }
};

export default reducer;
