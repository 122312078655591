import React, { useEffect, useState } from 'react';
import Frame from './frame/frame';
import Door from './door/door';
import './balconyDoor.scss';

export interface BalconyDoorProps {
  color: string;
  frameColor: string;
  width: number;
  height: number;
  opening: string;
  scale: number;
  handle: string;
  view: 'inside' | 'outside';
  wings: number;
  glass: string;
}

const BalconyDoor: React.SFC<BalconyDoorProps> = (props: BalconyDoorProps) => {
  const [wingIds, setWingIds] = useState<number[] | null>(null);

  useEffect(() => {
    if (props.wings) {
      const ids = [];
      for (let i = 1; i <= props.wings; i++) {
        ids.push(i);
      }
      setWingIds(ids);
    }
  }, [props.wings]);

  return (
    <Frame
      color={props.frameColor ?? props.color}
      scale={props.scale}
      wings={1}
      glass={props.glass}
    >
      <div
        className='balcony-door-wrapper'
        style={{
          zIndex:
            (props.opening === 'leftOutside' || props.opening === 'rightOutside') &&
            props.view === 'outside'
              ? 1
              : (props.opening === 'leftInside' || props.opening === 'rightInside') &&
                props.view === 'inside' &&
                1
        }}
      >
        {wingIds &&
          wingIds.map(id => (
            <Door
              width={props.width / props.wings}
              height={props.height}
              color={props.color}
              opening={props.opening}
              handle={props.handle}
              scale={props.scale}
              view={props.view}
              wingType={
                props.wings > 1
                  ? ((props.opening === 'rightInside' || props.opening === 'leftOutside') &&
                      id === 1) ||
                    ((props.opening === 'leftInside' || props.opening === 'rightOutside') &&
                      id === props.wings)
                    ? 'main'
                    : 'additional'
                  : 'main'
              }
              wings={props.wings}
              wingId={id}
            />
          ))}
      </div>
    </Frame>
  );
};

export default BalconyDoor;
