import React from 'react';
import './detailsItem.scss';

export interface DetailsItemProps {
  head: any[];
  properties: any[];
}

const DetailsItem: React.SFC<DetailsItemProps> = (props: DetailsItemProps) => {
  return (
    <div className='details-item'>
      <div className='details-item__head'>
        {props.head.map(item => (
          <p className='details-item__head-p'>{item}</p>
        ))}
      </div>
      {props.properties.map(property => (
        <div className='details-item__property'>
          <p>{property[0]}</p>
          <p>{property[1]}</p>
        </div>
      ))}
    </div>
  );
};

export default DetailsItem;
