import React from 'react';
import './statusBar.scss';

export interface StatusBarProps {
  precent: number;
  label: string;
}

const StatusBar: React.SFC<StatusBarProps> = (props: StatusBarProps) => {
  return (
    <div className='status-bar' style={{ width: props.precent + '%' }}>
      <p className='status-bar__label'>{props.label}</p>
    </div>
  );
};

export default StatusBar;
