export const isTablet = () => {
  return window.innerWidth <= 1200;
};

export const isTabletPort = () => {
  return window.innerWidth <= 900;
};

export const isPhone = () => {
  return window.innerWidth <= 480;
};
