import React from 'react';
import Select from 'react-select';

export interface SelectInputProps {
  label: string;
}

const SelectInput: React.SFC<SelectInputProps> = (props: any) => {
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      fontSize: '1.6rem'
    }),
    control: (provided, state) => ({
      ...provided,
      border: 'none !important',
      borderBottom: 'solid 0.2rem #1C3549 !important',
      borderRadius: 0,
      fontSize: '1.6rem',
      cursor: 'pointer',
      boxShadow: 'none !important',

      '&:hover': {
        border: 'none',
        borderBottom: 'solid 0.2rem #1C3549',
        borderRadius: 0
      }
    }),
    indicatorSeparator: () => ({
      visibility: 'hidden'
    }),
    dropdownIndicator: () => ({
      color: 'black',
      marginLeft: '-2.7rem'
    }),
    container: provided => ({
      ...provided,
      marginBottom: '2.5rem'
    })
  };

  return (
    <div className='select-input'>
      <p className='select-input-label'>{props.label}</p>
      <Select
        {...props}
        styles={customStyles}
        isSearchable={false}
        value={!props.value.label ? '' : props.value}
      />
    </div>
  );
};

export default SelectInput;
