import React, { useRef } from 'react';
import './wallHole.scss';
import LeftTopCornerShadow from '../../assets/preview/spaceLeftTopCornerShadow.png';
import RightTopCornerShadow from '../../assets/preview/spaceRightTopCornerShadow.png';

interface WallHoleProps {
  view: 'inside' | 'outside';
  bottom: number;
  children: any;
  scale: number;
  wallColor: string;
  productType: string;
  sill: string;
}

const WallHole = (props: WallHoleProps) => {
  const holeRef = useRef<HTMLDivElement | null>(null);

  return (
    <div
      className='wall-hole'
      ref={holeRef}
      style={{
        // transform: `scale3d(${props.scale ?? 1}, ${props.scale ?? 1}, ${props.scale ??
        //   1}) perspective(1px) translateY(0.09%) translateX(0.09%)`
        transform: props.view === 'inside' && 'rotateY(180deg) perspective(1px)',
        // backgroundColor: props.wallColor
        bottom: props.bottom
      }}
    >
      <div className='wall-hole-wrapper'>
        <div className='wall-hole__top' style={{ height: `calc(6px * ${props.scale})` }}></div>
        <div className='wall-hole__left' style={{ width: `calc(6px * ${props.scale})` }}></div>
        {props.children}
        <div className='wall-hole__right' style={{ width: `calc(6px * ${props.scale})` }}></div>
        <div
          className='wall-hole__bottom-left-corner'
          style={{
            borderBottomWidth: 16 * props.scale,
            borderLeftWidth: 6 * props.scale,
            borderLeftColor: props.wallColor
          }}
        ></div>
        <div
          className='wall-hole__bottom-plato'
          style={{
            width: `calc(100% + 12px * ${props.scale})`,
            height: 18 * props.scale,
            WebkitClipPath: `polygon(${7 * props.scale}px 0, calc(100% - ${7 *
              props.scale}px) 0%, 100% 100%, 0% 100%)`,
            clipPath: `polygon(${7 * props.scale}px 0, calc(100% - ${7 *
              props.scale}px) 0%, 100% 100%, 0% 100%)`,
            marginLeft: -6 * props.scale,
            marginTop: -3 * props.scale,
            backgroundColor:
              props.view === 'inside' && props.productType !== 'Window' && 'transparent'
          }}
        >
          {props.sill && <div className='wall-hole__bottom-plato-sill' />}
        </div>
        <div
          className='wall-hole__bottom-plato-height'
          style={{
            height: 3 * props.scale,
            opacity: props.view === 'inside' && 0
          }}
        ></div>
        <div
          className='wall-hole__bottom-right-corner'
          style={{
            borderBottomWidth: 16 * props.scale,
            borderRightWidth: 6 * props.scale,
            borderRightColor: props.wallColor
          }}
        ></div>
        <img
          src={LeftTopCornerShadow}
          className='wall-hole__left-top-corner-shadow'
          style={{ width: 13 * props.scale, height: 13 * props.scale }}
        ></img>
        <img
          src={RightTopCornerShadow}
          className='wall-hole__right-top-corner-shadow'
          style={{ width: 13 * props.scale, height: 13 * props.scale }}
        ></img>
      </div>
      {props.sill && (
        <div
          className='sill'
          style={{
            clipPath: `polygon(4px 0, calc(100% - 4px) 0, 100% 6px, 100% 100%, 0 100%, 0 6px)`
          }}
        >
          <div className='sill-bottom' />
        </div>
      )}
    </div>
  );
};

export default WallHole;
