import en from '../../localization/en.json';

export type Translation = typeof en;

const reducer = (state = en, action: any) => {
  switch (action.type) {
    case 'TRANSLATION_SET_LANGUAGE':
      return action.payload;
    default:
      return state;
  }
};

export default reducer;
