import * as React from 'react';
import './plus.scss';

export interface PlusProps {
  open: boolean;
}

const Plus: React.SFC<PlusProps> = (props: PlusProps) => {
  return (
    <div className='plus'>
      <div className='plus__horizontal'></div>
      <div className='plus__vertical' style={{ height: props.open ? 0 : '100%' }}></div>
    </div>
  );
};

export default Plus;
