import React, { useEffect, useState } from 'react';
import './detailsPanel.scss';
import ActionPanel from '../../components/actionPanel/actionPanel';
import Button from '../../components/button/button';
import { State } from '../../store/store';
import { connect, useDispatch } from 'react-redux';
import { ConfigState, ProductsConfig, CurrencyConfig } from '../../store/config/reducer';
import { UITogglePanels } from './../../store/UI/action';

export interface DetailsPanel {
  config: ConfigState;
  session: ProductsConfig;
  language: string;
  currency: string;
  screen: any;
  translation: any;
  currencyConfig: CurrencyConfig;
}

const DetailsPanel: React.SFC<DetailsPanel> = (props: DetailsPanel) => {
  const [entries, setEntries] = useState<null | [{ head: any[]; properties: any[] }]>(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const productEntries: [{ head: any[]; properties: any[] }] = [{ head: [], properties: [] }];
    Object.keys(props.session).forEach((productEntryKey, index) => {
      const productEntry = props.session[+productEntryKey];
      productEntries[+index] = {
        head: [
          +index + 1,
          productEntry.labels[props.language],
          productEntry.amount,
          Math.round(productEntry.price) + '.00'
        ],
        properties: []
      };

      productEntries[+index].properties.push([
        'Dimenzije',
        productEntry.width + 'mm x ' + productEntry.height + 'mm'
      ]);

      if (
        productEntry.width !== productEntry.overallWidth ||
        productEntry.height !== productEntry.overallHeight
      ) {
        productEntries[+index].properties.push([
          'Overall Dimensions',
          productEntry.overallWidth + 'mm x ' + productEntry.overallHeight + 'mm'
        ]);
      }

      Object.keys(productEntry.properties).forEach(propertyId => {
        const property = productEntry.properties[+propertyId];

        if (!property.subcategories) {
          Object.keys(property.values).forEach(valueId => {
            const propertyInput = property.values[+valueId];
            const propertyConfig = props.config.properties[+propertyId];
            const propertyInputConfig = propertyConfig.inputs[+valueId];

            if (propertyInput.valueId) {
              const propertyLabel = propertyConfig.labels[props.language];
              const propertyValueLabel =
                propertyInputConfig.options[propertyInput.valueId].labels[props.language];
              const propertyOptionLabel = propertyInput.optionId
                ? propertyInputConfig.options[propertyInput.valueId].options[propertyInput.optionId]
                    .labels[props.language]
                : null;

              propertyInput.valueId &&
                productEntries[+index].properties.push([
                  propertyLabel,
                  propertyOptionLabel ? propertyOptionLabel : propertyValueLabel
                ]);
            }
          });
        } else {
          Object.keys(property.subcategories).forEach(subcategoryId => {
            const propertyInput = property.subcategories[+subcategoryId];
            const propertyConfig = props.config.properties[+propertyId];
            const propertySubcategoryConfig =
              props.config.properties[+propertyId].subcategories[+subcategoryId];
            const propertyInputConfig = propertySubcategoryConfig.inputs[1];

            if (propertyInput.valueId) {
              const propertyLabel = propertyConfig.labels[props.language];
              const propertySubcategoryLabel = propertySubcategoryConfig.labels[props.language];
              const propertyValueLabel =
                propertyInputConfig.options[propertyInput.valueId].labels[props.language];
              // const propertyOptionLabel = propertyInput.optionId
              //   ? propertyInputConfig.options[propertyInput.valueId].options[propertyInput.optionId]
              //       .labels[props.language]
              //   : null;

              propertyInput.valueId &&
                productEntries[+index].properties.push([
                  propertyLabel + ' ' + propertySubcategoryLabel,
                  // propertyOptionLabel ? propertyOptionLabel : propertyValueLabel
                  propertyValueLabel
                ]);
            }
          });
        }
      });
    });

    setEntries(productEntries);
  }, [props.session, props.language, props.currency]);

  const handleOnSend = () => {
    dispatch(UITogglePanels({ request: true, details: false }));
  };

  return (
    <ActionPanel
      action='details'
      label={props.translation.actionPanel.details.details}
      description={props.translation.actionPanel.details.description}
    >
      <div className='details-panel'>
        {entries && entries[0].properties.length > 1 && (
          <>
            <div className='details-panel__details'>
              {entries?.map((entry, index) => {
                return (
                  <>
                    <div className='details-panel__details-head'>
                      <p className='details-panel__details-head-p'>
                        {entry.head[0]}. {entry.head[1]}
                      </p>
                      <p className='details-panel__details-head-p'>{entry.head[2]}x</p>
                      <p className='details-panel__details-head-p'>
                        {props.currencyConfig.side === 'left' ? props.currencyConfig.symbol : ''}
                        {entry.head[3]}
                        {props.currencyConfig.side === 'right'
                          ? ' ' + props.currencyConfig.symbol
                          : ''}
                      </p>
                    </div>
                    {entry.properties?.map(property => (
                      <div className='details-panel__details-property'>
                        <p className='details-panel__details-property-p'>{property[0]}:</p>
                        <p className='details-panel__details-property-p'>{property[1]}</p>
                      </div>
                    ))}
                  </>
                );
              })}
              <div className='details-panel__details-head'>
                <p className='details-panel__details-head-p'>Total:</p>
                <p className='details-panel__details-head-p'>
                  {props.currencyConfig.side === 'left' ? props.currencyConfig.symbol : ''}
                  {Object.keys(props.session)
                    .map(key => Math.round(props.session[+key].price))
                    .reduce((acc, curr) => acc + curr)}
                  .00
                  {props.currencyConfig.side === 'right' ? ' ' + props.currencyConfig.symbol : ''}
                </p>
              </div>
            </div>
            <Button
              label='PDF'
              onClick={() => {}}
              style={{
                display: 'inline-block',
                width: 'calc(50% - 1rem)',
                marginTop: '6rem',
                marginRight: '1rem'
              }}
            />
            <Button
              label={props.translation.send}
              onClick={() => handleOnSend()}
              style={{ display: 'inline-block', width: 'calc(50% - 1rem)', marginTop: '6rem' }}
            />
          </>
        )}
      </div>
    </ActionPanel>
  );
};

const mapStateToProps = (state: State) => {
  return {
    config: state.config,
    session: state.session,
    language: state.UI.language,
    currency: state.UI.currency,
    screen: state.UI.screen,
    translation: state.translation,
    currencyConfig: state.config.general.currency[state.UI.currency]
  };
};

export default connect(mapStateToProps)(DetailsPanel);
