export interface requestFields {
  [key: string]: string | number;
}

interface requestState {
  fields: requestFields;
  locationAutocomplete: [];
}

const initState: requestState = {
  fields: {
    email: '',
    fullName: '',
    address: '',
    phone: '',
    note: ''
  },
  locationAutocomplete: []
};

const reducer = (state = initState, action) => {
  switch (action.type) {
    case 'REQUEST_FIELD_ON_CHANGE':
      return {
        ...state,
        fields: {
          ...state.fields,
          [action.payload.fieldName]: action.payload.value
        }
      };
    case 'REQUEST_LOCATION_AUTOCOMPLETE':
      return {
        ...state,
        locationAutocomplete: action.payload
      };
    case 'REQUEST_LOACTION_AUTOCOMPLETE_RESET':
      return {
        ...state,
        locationAutocomplete: []
      };
    default:
      return state;
  }
};

export default reducer;
