import React, { useEffect, useState } from 'react';
import './generalPanel.scss';
import ActionPanel from '../../components/actionPanel/actionPanel';
import Property from '../../components/property/property';
import { State } from '../../store/store';
import { connect, useDispatch } from 'react-redux';
import { ConfigState } from '../../store/config/reducer';
import { generalToggleOpen } from '../../store/general/actions';
import inputGenerator from './inputGenerator';

export interface GeneralPanelProps {
  config: ConfigState;
  general: any;
  language: string;
  translation: any;
}

const GeneralPanel: React.SFC<GeneralPanelProps> = (props: GeneralPanelProps) => {
  const [properties, setProperties] = useState(null);
  const dispatch = useDispatch();

  const handleOnClick = (property: number, subcategory?: number) => {
    dispatch(generalToggleOpen({ property, subcategory }));
  };

  const handleOnNextClick = (property: number, subcategory?: number) => {
    dispatch(generalToggleOpen({ property: property + 1, subcategory }));
  };

  useEffect(() => {
    const propsArr = [];

    Object.keys(props.general.properties).map(propertyId => {
      const propertyConfig = props.config.properties[+propertyId];
      propsArr.push(
        propertyConfig.type !== 'subcategory' ? (
          <Property
            key={propertyId}
            label={propertyConfig.labels[props.language]}
            open={props.general.properties[+propertyId].open}
            subLabel={props.translation.clickToConfigure}
            onClick={() => handleOnClick(+propertyId)}
            onNextClick={() => handleOnNextClick(+propertyId)}
            touched={props.general.properties[+propertyId].touched}
          >
            {Object.keys(propertyConfig.inputs).map(inputId => {
              const depends = propertyConfig.inputs[inputId].depends;
              if (depends) {
                if (props.general.properties[depends.property].values[depends.input].touched) {
                  return inputGenerator(
                    propertyConfig.inputs[inputId],
                    propertyConfig.labels.en,
                    +inputId,
                    null,
                    +propertyId
                  );
                }
              } else {
                return inputGenerator(
                  propertyConfig.inputs[inputId],
                  propertyConfig.labels.en,
                  +inputId,
                  null,
                  +propertyId
                );
              }
            })}
          </Property>
        ) : (
          <Property
            key={propertyId}
            label={propertyConfig.labels[props.language]}
            open={props.general.properties[+propertyId].open}
            subLabel={props.translation.clickToConfigure}
            onClick={() => handleOnClick(+propertyId)}
            onNextClick={() => handleOnNextClick(+propertyId)}
            touched={props.general.properties[+propertyId].touched}
            subcategory={true}
          >
            {Object.keys(propertyConfig.subcategories).map(subcategoryId => {
              const subcategoryProperty = propertyConfig.subcategories[subcategoryId];
              return (
                <Property
                  key={subcategoryId}
                  label={subcategoryProperty.labels[props.language]}
                  open={props.general.properties[+propertyId].subcategories[subcategoryId].open}
                  subLabel={props.translation.clickToConfigure}
                  onClick={() => handleOnClick(+propertyId, +subcategoryId)}
                  next={false}
                  touched={false}
                >
                  {Object.keys(subcategoryProperty.inputs).map(inputId => {
                    return inputGenerator(
                      subcategoryProperty.inputs[inputId],
                      propertyConfig.labels.en,
                      +inputId,
                      +subcategoryId,
                      +propertyId,
                      propertyConfig.onlyOne
                    );
                  })}
                </Property>
              );
            })}
          </Property>
        )
      );
    });

    setProperties(propsArr);
  }, [props]);

  return (
    <ActionPanel
      action='general'
      label={props.translation.actionPanel.general.general}
      description={props.translation.actionPanel.general.description}
    >
      {/* <div className='general-panel'>{properties && properties.map(property => property)}</div> */}
    </ActionPanel>
  );
};

const mapStateToProps = (state: State) => {
  return {
    config: state.config,
    general: state.general,
    language: state.UI.language,
    translation: state.translation
  };
};

export default connect(mapStateToProps)(GeneralPanel);
