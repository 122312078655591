import React from 'react';
import './housePanel.scss';
import ActionPanel from '../../components/actionPanel/actionPanel';
import Property from '../../components/property/property';
import ColorSelect from '../../components/colorSelect/colorSelect';
import { connect, useDispatch } from 'react-redux';
import { State } from '../../store/store';
import { House } from '../../store/house/reducer';
import { houseChangeValue, houseToggleProperty } from './../../store/house/actions';

export interface HousePanelProps {
  house: House;
  translation: any;
}

const HousePanel: React.SFC<HousePanelProps> = (props: HousePanelProps) => {
  const dispatch = useDispatch();

  const handleOnClick = (property: string) => {
    dispatch(houseToggleProperty({ property }));
  };

  const handleOnChange = (property: string, value: string) => {
    dispatch(houseChangeValue({ property, value }));
  };

  return (
    <ActionPanel action='house' label={props.translation.actionPanel.house.house}>
      <div className='house-panel'>
        <Property
          label={props.translation.actionPanel.house.facade}
          subLabel={props.translation.clickToConfigure}
          open={props.house.facade.open}
          onClick={() => handleOnClick('facade')}
          touched={false}
          next={false}
        >
          <ColorSelect
            options={[
              { value: '#9f8f89' },
              { value: '#d0c2b8' },
              { value: '#cad4df' },
              { value: '#8da972' },
              { value: '#f2e9d1' },
              { value: '#a7857e' },
              { value: '#c5c8c9' },
              { value: '#eebc8b' }
            ]}
            uncheckable={false}
            value={props.house.facade.value}
            onChange={value => handleOnChange('facade', value)}
          />
        </Property>
        <Property
          label={props.translation.actionPanel.house.wallColor}
          subLabel={props.translation.clickToConfigure}
          open={props.house.wallColor.open}
          onClick={() => handleOnClick('wallColor')}
          touched={false}
          next={false}
        >
          <ColorSelect
            options={[
              { value: '#9f8f89' },
              { value: '#d0c2b8' },
              { value: '#cad4df' },
              { value: '#8da972' },
              { value: '#f2e9d1' },
              { value: '#a7857e' },
              { value: '#c5c8c9' },
              { value: '#eebc8b' }
            ]}
            uncheckable={false}
            value={props.house.wallColor.value}
            onChange={value => handleOnChange('wallColor', value)}
          />
        </Property>
        <Property
          label={props.translation.actionPanel.house.scenes}
          subLabel={props.translation.clickToConfigure}
          open={props.house.scenes.open}
          onClick={() => handleOnClick('scenes')}
          touched={false}
          next={false}
        >
          {' '}
        </Property>
        <Property
          label={props.translation.actionPanel.house.insertIntoHouse}
          subLabel={props.translation.clickToConfigure}
          open={props.house.insert.open}
          onClick={() => handleOnClick('insert')}
          touched={false}
          next={false}
        >
          {' '}
        </Property>
      </div>
    </ActionPanel>
  );
};

const mapStateToProps = (state: State) => {
  return {
    house: state.house,
    translation: state.translation
  };
};

export default connect(mapStateToProps)(HousePanel);
