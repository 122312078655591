import React, { useState } from 'react';
import './requestPanel.scss';
import ActionPanel from '../../components/actionPanel/actionPanel';
import TextInput from '../../components/textInput/textInput';
import Button from '../../components/button/button';
import { State } from '../../store/store';
import { connect, useDispatch } from 'react-redux';
import {
  requestFieldOnChange,
  requestLocationAutocomplete,
  requestLocationAutocompleteReset
} from './../../store/request/actions';
import { requestFields } from './../../store/request/reducer';

export interface RequestPanelProps {
  fields: requestFields;
  locationAutocomplete: string[];
  translation: any;
}

const RequestPanel: React.SFC<RequestPanelProps> = (props: RequestPanelProps) => {
  const dispatch = useDispatch();
  const [sessiontoken, setSessiontoken] = useState(Math.round(Math.random() * 1000000));

  const handleOnChange = (
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    dispatch(requestFieldOnChange({ fieldName: e.target.name, value: e.target.value }));
  };

  const handleOnAddressChange = (
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    dispatch(requestFieldOnChange({ fieldName: e.target.name, value: e.target.value }));
    dispatch(requestLocationAutocomplete({ value: e.target.value, sessiontoken: sessiontoken }));
  };

  const handleOnAddressSelect = (
    e: React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    dispatch(requestFieldOnChange({ fieldName: e.target.name, value: e.target.value }));
    dispatch(requestLocationAutocompleteReset());
    setSessiontoken(Math.round(Math.random() * 1000000));
  };

  return (
    <ActionPanel
      action='request'
      label={props.translation.actionPanel.request.request}
      description={props.translation.actionPanel.request.description}
    >
      <div className='request-panel'>
        <TextInput
          type='text'
          name='email'
          placeholder='E-mail'
          onChange={handleOnChange}
          value={props.fields.email}
        />
        <TextInput
          type='text'
          name='fullName'
          placeholder={props.translation.actionPanel.request.fullName}
          onChange={handleOnChange}
          value={props.fields.fullName}
        />
        <TextInput
          type='text'
          name='address'
          placeholder={props.translation.actionPanel.request.address}
          onChange={handleOnAddressChange}
          value={props.fields.address}
          searchable={true}
          options={props.locationAutocomplete}
          onOptionSelect={handleOnAddressSelect}
        />
        <TextInput
          type='text'
          name='phone'
          placeholder={props.translation.actionPanel.request.phone}
          onChange={handleOnChange}
          value={props.fields.phone}
        />
        <textarea
          name='note'
          placeholder={props.translation.actionPanel.request.note}
          onChange={handleOnChange}
          value={props.fields.note}
        />
        <Button
          label={props.translation.send}
          onClick={() => {}}
          style={{ width: '100%', marginTop: '6rem' }}
        />
      </div>
    </ActionPanel>
  );
};

const mapStateToProps = (state: State) => {
  return {
    fields: state.request.fields,
    locationAutocomplete: state.request.locationAutocomplete,
    translation: state.translation
  };
};

export default connect(mapStateToProps)(RequestPanel);
