import React from 'react';
import './quadro.scss';
import { shadeHexColor } from '../../../../utils/color';

export interface QuadroBoxProps {
  color: string;
}

const QuadroBox: React.SFC<QuadroBoxProps> = (props: QuadroBoxProps) => {
  return (
    <div
      className='quadro-box'
      style={{
        backgroundColor: shadeHexColor(props.color, 0),
        borderTop: `solid 1px ${shadeHexColor(props.color, 0.35)}`,
        borderLeft: `solid 2px ${shadeHexColor(props.color, 0.35)}`
      }}
    >
      <div className='quadro-box-top-light'>
        <div
          className='quadro-box-top-light-shade-2'
          style={{ backgroundColor: shadeHexColor(props.color, -0.04) }}
        ></div>
        <div
          className='quadro-box-top-light-shade'
          style={{ backgroundColor: shadeHexColor(props.color, -0.08) }}
        ></div>
        <div
          className='quadro-box-top-light-light'
          style={{ backgroundColor: shadeHexColor(props.color, 0.45) }}
        ></div>
        <div
          className='quadro-box-top-light-light-2'
          style={{ backgroundColor: shadeHexColor(props.color, 0.2) }}
        ></div>
      </div>
    </div>
  );
};

export default QuadroBox;
