import React from 'react';
import './round.scss';
import { shadeHexColor } from '../../../../utils/color';

export interface RoundBoxProps {
  color: string;
}

const RoundBox: React.SFC<RoundBoxProps> = (props: RoundBoxProps) => {
  return (
    <div
      className='round-box'
      style={{
        backgroundColor: shadeHexColor(props.color, 0)
        // borderTop: `solid 1px ${shadeHexColor(props.color, 0.1)}`,
        // borderLeft: `solid 2px ${shadeHexColor(props.color, 0.1)}`
      }}
    ></div>
  );
};

export default RoundBox;
