import React, { useEffect, useState } from 'react';
import './App.scss';
import { useDispatch, connect } from 'react-redux';
import Header from './layout/header/header';
import useScreenSizeObserver from './hooks/useScreenSizeObserver';
import { BrowserRouter as Router, Route, Switch, withRouter } from 'react-router-dom';
// import Predracun from './components/Predracun/Predracun';
import Preview from './components/preview/preview';
import { State } from './store/store';
import ConfigPanel from './layout/configPanel/configPanel';
import { configInit } from './store/config/actions';
import ActionsPanel from './layout/actionsPanel/actionsPanel';
import AddProductPanel from './layout/addProductPanel/addProductPanel';
import HousePanel from './layout/housePanel/housePanel';
import GeneralPanel from './layout/generalPanel/generalPanel';
import InfoCard from './components/infoCard/infoCard';
import SessionPanel from './layout/sessionPanel/sessionPanel';
import { ProductsConfig, CurrencyConfig } from './store/config/reducer';
import { UIState } from './store/UI/reducer';
import Details from './layout/details/details';
import RequestPanel from './layout/requestPanel/requestPanel';
import { CSSTransition } from 'react-transition-group';
import DetailsPanel from './layout/detailsPanel/detailsPanel';
import Demo from './components/demo/demo';
import { changeLanguage, changeCurrency } from './store/UI/action';
import { localizationChangeLanguage } from './store/translation/actions';

interface IProps {
  UI: UIState;
  productsConfig: ProductsConfig;
  preview: any;
  location: { hash: string; pathname: string; search: string; state: any };
  currencyConfig: { [key: string]: CurrencyConfig };
}

const App = (props: IProps) => {
  const dispatch = useDispatch();
  const [demo, setDemo] = useState(true);
  const [urlParams, setUrlParams] = useState<any>({});

  useEffect(() => {
    const searchParamsArr = props.location.search.replace('?', '').split('&');
    const searchParams = {};
    searchParamsArr.forEach(
      param => param && (searchParams[param.split('=')[0]] = param.split('=')[1])
    );
    setUrlParams(searchParams);
    window.history.replaceState({}, document.title, '/');
  }, []);

  useEffect(() => {
    dispatch(configInit());
    updateCurrency();
    document.oncontextmenu = e => e.preventDefault();
  }, []);

  useEffect(() => handleLanguageUpdate(), [urlParams.lang]);

  const updateCurrency = () => {
    const savedCurrency = localStorage.getItem('currency');
    if (savedCurrency) {
      const currencyConfig = props.currencyConfig[savedCurrency];
      currencyConfig &&
        dispatch(changeCurrency({ value: currencyConfig.value, label: currencyConfig.label }));
    }
  };

  const handleLanguageUpdate = () => {
    const storedLanguage = localStorage.getItem('language') ?? null;
    let language;

    if (urlParams.lang) {
      language = { value: urlParams.lang.toLowerCase(), label: urlParams.lang.toUpperCase() };
    } else if (storedLanguage) {
      language = { value: storedLanguage.toLowerCase(), label: storedLanguage.toUpperCase() };
    }

    if (language) {
      dispatch(changeLanguage(language));
      dispatch(localizationChangeLanguage(language.value));
    }
  };

  useScreenSizeObserver();

  return (
    <div className='App'>
      {demo && <Demo show={setDemo} />}
      <Router>
        <Header />
        <div className='content'>
          <ActionsPanel />
          <CSSTransition
            in={props.UI.panels.addProduct}
            appear={props.UI.panels.addProduct}
            unmountOnExit={true}
            mountOnEnter={true}
            timeout={600}
            classNames='action-panel-slide'
          >
            <AddProductPanel />
          </CSSTransition>
          <CSSTransition
            in={props.UI.panels.house}
            appear={props.UI.panels.house}
            unmountOnExit={true}
            mountOnEnter={true}
            timeout={600}
            classNames='action-panel-slide'
          >
            <HousePanel />
          </CSSTransition>
          <CSSTransition
            in={props.UI.panels.general}
            appear={props.UI.panels.general}
            unmountOnExit={true}
            mountOnEnter={true}
            timeout={600}
            classNames='action-panel-slide'
          >
            <GeneralPanel />
          </CSSTransition>
          <CSSTransition
            in={props.UI.panels.request}
            appear={props.UI.panels.request}
            unmountOnExit={true}
            mountOnEnter={true}
            timeout={600}
            classNames='action-panel-slide'
          >
            <RequestPanel />
          </CSSTransition>
          <CSSTransition
            in={props.UI.panels.details}
            appear={props.UI.panels.details}
            unmountOnExit={true}
            mountOnEnter={true}
            timeout={600}
            classNames='action-panel-slide'
          >
            <DetailsPanel />
          </CSSTransition>
          <div className='main-wrapper'>
            <Preview />
            <CSSTransition
              in={props.UI.panels.session}
              enter={props.UI.panels.session}
              mountOnEnter={true}
              unmountOnExit={true}
              timeout={600}
              classNames='right-panels-slide'
            >
              <SessionPanel />
            </CSSTransition>

            <CSSTransition
              in={props.UI.panels.config}
              enter={props.UI.panels.config}
              mountOnEnter={true}
              unmountOnExit={true}
              timeout={600}
              classNames={
                props.UI.screen.tabletPort && !props.UI.configPanel.maximized
                  ? 'right-panels-slide-above'
                  : 'right-panels-slide'
              }
            >
              <ConfigPanel />
            </CSSTransition>
          </div>
          <Switch>
            <Route path='/details'>
              <Details />
            </Route>
          </Switch>
        </div>
      </Router>

      {/* <CSSTransition
        in={props.preview.infoCard && Object.keys(props.preview.infoCard).length > 0}
        appear={props.preview.infoCard && Object.keys(props.preview.infoCard).length > 0}
        mountOnEnter={true}
        unmountOnExit={true}
        timeout={400}
        classNames='fade'
      > */}
      {props.preview.infoCard && Object.keys(props.preview.infoCard).length > 0 && (
        <InfoCard
          label={props.preview.infoCard.label}
          info={props.preview.infoCard.info}
          img={props.preview.infoCard.img}
          bars={props.preview.infoCard.bars}
        />
      )}
      {/* </CSSTransition> */}
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    currencyConfig: state.config.general.currency,
    UI: state.UI,
    productsConfig: state.config,
    preview: state.preview
  };
};

export default withRouter(connect(mapStateToProps)(App));
