export interface GeneralPropertiesConfig {
  properties: {
    [key: number]: {
      id: number;
      touched?: boolean;
      values?: {
        [key: number]: {
          id: number;
          value?: number | string;
          touched?: boolean;
          values?: {
            value: string | number;
            labels: { yu: string; en: string };
          };
        };
      };
      subcategories?: {
        [key: number]: {
          id: number;
          open: boolean;
          value?: string | number;
        };
      };
      open: boolean;
    };
  };
}

const initState: GeneralPropertiesConfig = {
  properties: {
    6: {
      id: 6,
      open: false,
      subcategories: {
        1: {
          id: 1,
          open: true,
          value: '#3d4045'
        },
        2: {
          id: 2,
          open: false
        }
      }
    }
  }
};

const reducer = (state = initState, action: any) => {
  switch (action.type) {
    case 'GENERAL_TOGGLE_OPEN':
      if (!action.payload.subcategory) {
        let newState = {};
        Object.keys(state.properties).forEach(propertyId => {
          newState = {
            ...newState,
            [propertyId]: {
              ...state.properties[propertyId],
              open:
                +propertyId === action.payload.property
                  ? state.properties[propertyId].open
                    ? false
                    : true
                  : false
            }
          };
        });

        return {
          ...state,
          properties: newState
        };
      } else {
        const propertySubcategory = state.properties[action.payload.property].subcategories;
        let newState = propertySubcategory;

        Object.keys(propertySubcategory).forEach(id => {
          newState = {
            ...newState,
            [+id]: {
              ...newState[+id],
              open:
                newState[+id].id === action.payload.subcategory
                  ? propertySubcategory[id].open
                    ? false
                    : true
                  : false
            }
          };
        });

        return {
          ...state,
          properties: {
            ...state.properties,
            [action.payload.property]: {
              ...state.properties[action.payload.property],
              subcategories: newState
            }
          }
        };
      }

    case 'GENERAL_CHANGE_PROPERTY_VALUE':
      if (!action.payload.subcategory) {
        return {
          ...state,
          properties: {
            ...state.properties,
            [action.payload.propertyId]: {
              ...state.properties[action.payload.propertyId],
              touched: true,
              values: {
                ...state.properties[action.payload.propertyId].values,
                [action.payload.inputId]: {
                  ...state.properties[action.payload.propertyId].values[action.payload.inputId],
                  value: action.payload.value,
                  touched:
                    typeof action.payload.value === 'object'
                      ? action.payload.value.value === 0
                        ? false
                        : true
                      : true
                }
              }
            }
          }
        };
      } else {
        let newSubactegory = {};
        Object.keys(state.properties[action.payload.propertyId].subcategories).forEach(
          subcategoryId => {
            newSubactegory = {
              ...newSubactegory,
              [subcategoryId]: {
                ...state.properties[action.payload.propertyId].subcategories[subcategoryId],
                value:
                  +subcategoryId === +action.payload.subcategory
                    ? action.payload.value
                    : action.payload.onlyOne
                    ? null
                    : state.properties[action.payload.propertyId].subcategories[subcategoryId]
                        .value,
                touched:
                  typeof action.payload.value === 'object'
                    ? action.payload.value.value === 0
                      ? false
                      : true
                    : true
              }
            };
          }
        );

        return {
          ...state,
          properties: {
            ...state.properties,
            [action.payload.propertyId]: {
              ...state.properties[action.payload.propertyId],
              touched: true,
              subcategories: {
                ...newSubactegory
              }
            }
          }
        };
      }
    default:
      return state;
  }
};

export default reducer;
