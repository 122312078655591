import React, { useRef } from 'react';
import './demo.scss';
import { ReactComponent as X } from '../../assets/x-small.svg';
import { State } from '../../store/store';
import { Translation } from '../../store/translation/reducer';
import { connect } from 'react-redux';

export interface DemoProps {
  show: Function;
  translation: Translation;
}

const Demo: React.SFC<DemoProps> = (props: DemoProps) => {
  const mainRef = useRef(null);
  const faderRef = useRef(null);

  const handleClose = () => {
    mainRef.current.style.opacity = '0';
    faderRef.current.style.opacity = '0';
    setTimeout(() => props.show(false), 300);
  };

  return (
    <>
      <div className='fader' ref={faderRef} />
      <div className='demo' ref={mainRef}>
        <h2 className='demo-title'>{props.translation.demo.title}</h2>
        <X className='demo-x' onClick={handleClose} />
        <p className='demo-p'>{props.translation.demo.p}</p>
      </div>
    </>
  );
};

const mapStateToProps = (state: State) => {
  return {
    translation: state.translation
  };
};

export default connect(mapStateToProps)(Demo);
