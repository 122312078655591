import { Dispatch } from 'redux';
import axios from '../../utils/axios';
import api from '../../api.json';

export const configInit = () => {
  return async (dispatch: Dispatch) => {
    const init = await axios.get(api.configInit);

    dispatch({
      type: 'CONFIG_INIT',
      payload: init
    });
  };
};

export type ConfigActionTypes = { type: string; payload: ReturnType<typeof configInit> };
