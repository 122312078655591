import React from 'react';
import './button.scss';

export interface ButtonProps {
  label: string;
  style?: Object;
  onClick: any;
  type?: 1 | 2;
}

const Button: React.SFC<ButtonProps> = (props: ButtonProps) => {
  return (
    <button
      className={'button-primary' + (props.type === 2 ? ' hover2' : '')}
      onClick={props.onClick}
      style={props.style ?? null}
    >
      {props.label}
    </button>
  );
};

export default Button;
