import React, { useEffect, useState } from 'react';
import './panel.scss';
import { isColorLight } from './../../../utils/color';

export interface PanelProps {
  width: number;
  height: number;
  opening: string;
  color: string;
  scale: number;
  model: string;
  handrail: { value: string | number; option: string | number };
  handleInside: string;
  handleOutside: string;
  view: 'inside' | 'outside';
  cover?: 'oneSide' | 'twoSide';
  handrailInside: boolean;
  protection: string;
  rosetteInside: string;
  rosetteOutside: string;
}

const Panel: React.SFC<PanelProps> = (props: PanelProps) => {
  const [panel, setPanel] = useState<HTMLImageElement | null>(null);
  const [handrail, setHandrail] = useState<HTMLImageElement | null>(null);
  const [handleInside, setHandleInside] = useState<HTMLImageElement | null>(null);
  const [handleOutside, setHandleOutside] = useState<HTMLImageElement | null>(null);
  const [protection, setProtection] = useState<HTMLImageElement | null>(null);
  const [rosetteInside, setRosetteInside] = useState<HTMLImageElement | null>(null);
  const [rosetteOutside, setRosetteOutside] = useState<HTMLImageElement | null>(null);

  useEffect(() => {
    const panelImg = new Image();
    panelImg.src = require(`../../../assets/products/EntranceDoor/Model/${props.model}-panel.png`);
    panelImg.onload = () => setPanel(panelImg);
  }, [props.model]);

  useEffect(() => {
    const handrailImg = new Image();
    handrailImg.src = require(`../../../assets/products/EntranceDoor/Handrail/${props.handrail.value}/${props.handrail.option}.png`);
    handrailImg.onload = () => setHandrail(handrailImg);
  }, [props.handrail]);

  useEffect(() => {
    if (props.handleInside) {
      const handleInsideImg = new Image();
      handleInsideImg.src = require(`../../../assets/products/EntranceDoor/Handle/${props.handleInside}-preview.png`);
      handleInsideImg.onload = () => setHandleInside(handleInsideImg);
    }
  }, [props.handleInside]);

  useEffect(() => {
    if (props.handleOutside) {
      const handleOutsideImg = new Image();
      handleOutsideImg.src = require(`../../../assets/products/EntranceDoor/Handle/${props.handleOutside}-preview.png`);
      handleOutsideImg.onload = () => setHandleOutside(handleOutsideImg);
    } else {
      setHandleOutside(null);
    }
  }, [props.handleOutside]);

  useEffect(() => {
    if (props.protection) {
      const protectionImg = new Image();
      protectionImg.src = require(`../../../assets/products/EntranceDoor/Panel protection/${props.protection}-preview.png`);
      protectionImg.onload = () => setProtection(protectionImg);
    } else {
      setProtection(null);
    }
  }, [props.protection]);

  useEffect(() => {
    if (props.rosetteInside) {
      const rosetteInsideImg = new Image();
      rosetteInsideImg.src = require(`../../../assets/products/EntranceDoor/Rosette/${props.rosetteInside}-preview.png`);
      rosetteInsideImg.onload = () => setRosetteInside(rosetteInsideImg);
    }
  }, [props.rosetteInside]);

  useEffect(() => {
    if (props.rosetteOutside) {
      const rosetteOutsideImg = new Image();
      rosetteOutsideImg.src = require(`../../../assets/products/EntranceDoor/Rosette/${props.rosetteOutside}-preview.png`);
      rosetteOutsideImg.onload = () => setRosetteOutside(rosetteOutsideImg);
    }
  }, [props.rosetteOutside]);

  const scale = (amount: number) => {
    return amount * props.scale;
  };

  return (
    <div
      className='panel'
      style={{
        width: scale(props.width) + 'px',
        height:
          !props.protection || props.view === 'inside'
            ? scale(props.height - 4)
            : scale(props.height),

        transform:
          (props.opening === 'rightInside' || props.opening === 'leftOutside') && 'rotateY(180deg)',
        boxShadow: props.view === 'inside' ? 'none' : 'inset 0 0 3px rgba(0, 0, 0, 0.35)'
      }}
    >
      {((props.view === 'inside' &&
        (props.opening === 'leftInside' || props.opening === 'rightInside')) ||
        (props.view === 'outside' &&
          (props.opening === 'leftOutside' || props.opening === 'rightOutside'))) && (
        <div className='panel__hinge'>
          <div
            className='panel__hinge-top'
            style={{
              right: scale(-3),
              width: scale(12),
              height: scale(69)
            }}
          ></div>
          <div
            className='panel__hinge-middle'
            style={{
              right: scale(-3),
              width: scale(12),
              height: scale(69)
            }}
          ></div>
          <div
            className='panel__hinge-bottom'
            style={{
              right: scale(-3),
              width: scale(12),
              height: scale(69)
            }}
          ></div>
        </div>
      )}
      {props.view === 'outside' && protection?.src && (
        <img
          src={protection.src}
          alt=''
          className='panel__protection'
          style={{ height: scale(protection?.height) }}
        />
      )}
      <div
        className='panel-wrapper'
        style={{
          backgroundColor: props.color,
          height: `calc(100% - ${scale(4)}px)`,
          border: props.view === 'outside' && 'solid 1px rgba(0, 0, 0, 0.08)'
        }}
      >
        {props.view === 'outside' && (
          <>
            <img
              src={panel?.src}
              alt=''
              className='panel__panel'
              style={{
                width: scale(panel?.width),
                height: scale(panel?.height),
                left: panel?.width > 100 ? 0 : '50%',
                transform: `translate(${panel?.width < 100 ? '-50%, -50%' : '0, -50%'})`
              }}
            />
            <div
              className='panel__light'
              style={{
                right: props.opening === 'rightInside' && 0,
                transform:
                  (props.opening === 'rightInside' || props.opening === 'leftOutside') &&
                  'rotateY(180deg)'
              }}
            ></div>
          </>
        )}

        {((!handleOutside?.src && props.view === 'outside') ||
          (props.view === 'inside' && props.handrailInside)) && (
          <img
            src={handrail?.src}
            alt=''
            className='panel__handrail'
            style={{
              width: scale(handrail?.width),
              height: scale(handrail?.height),
              left: scale(25)
            }}
          />
        )}
        {props.view === 'inside' && !props.handrailInside && (
          <img
            src={handleInside?.src}
            style={{
              width: scale(handleInside?.width),
              height: scale(handleInside?.height),
              bottom: scale(310)
            }}
            alt=''
            className='handle__inside'
          />
        )}
        {props.view === 'outside' && handleOutside?.src && (
          <img
            src={handleOutside.src}
            style={{
              width: scale(handleOutside?.width),
              height: scale(handleOutside?.height),
              bottom: scale(310)
            }}
            alt=''
            className='handle__outside'
          />
        )}
        <div className='rosette' style={{ bottom: scale(280), width: scale(20) }}>
          {props.view === 'inside' && rosetteInside?.src && (
            <img
              src={rosetteInside?.src}
              style={{
                width: scale(rosetteInside?.width),
                height: scale(rosetteInside?.height)
              }}
              alt=''
              className='rosette__inside'
            />
          )}
          {props.view === 'outside' && rosetteOutside?.src && (
            <img
              src={rosetteOutside?.src}
              style={{
                width: scale(rosetteOutside?.width),
                height: scale(rosetteOutside?.height)
              }}
              alt=''
              className='rosette__outside'
            />
          )}
        </div>
        {props.view === 'inside' && (
          <>
            {props.cover === 'oneSide' && (
              <>
                <div
                  className='panel__oneSideCover'
                  style={{
                    width: scale(props.width) - scale(46),
                    height: scale(props.height) - scale(46 + 10),
                    marginTop: scale(22),
                    marginLeft: scale(22)
                  }}
                >
                  <div className='panel__oneSideCover__corner-line'>
                    <div
                      className='panel__oneSideCover__corner-line-top-left'
                      style={{
                        backgroundColor: isColorLight(props.color) ? 'black' : 'white',
                        height: scale(31),
                        left: scale(10),
                        top: scale(-5)
                      }}
                    ></div>
                    <div
                      className='panel__oneSideCover__corner-line-top-right'
                      style={{
                        backgroundColor: isColorLight(props.color) ? 'black' : 'white',
                        height: scale(31),
                        right: scale(11),
                        bottom: scale(-5)
                      }}
                    ></div>
                    <div
                      className='panel__oneSideCover__corner-line-bottom-left'
                      style={{
                        backgroundColor: isColorLight(props.color) ? 'black' : 'white',
                        height: scale(31),
                        right: scale(12),
                        bottom: scale(-2)
                      }}
                    ></div>
                    <div
                      className='panel__oneSideCover__corner-line-bottom-right'
                      style={{
                        backgroundColor: isColorLight(props.color) ? 'black' : 'white',
                        height: scale(31),
                        left: scale(10),
                        bottom: scale(-2)
                      }}
                    ></div>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Panel;
