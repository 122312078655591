import React, { Children } from 'react';
import './frame.scss';
import { isColorLight } from './../../../utils/color';

export interface FrameProps {
  color: string;
  scale: number;
  children: any;
  wings: number;
  glass: string;
}

const Frame: React.SFC<FrameProps> = (props: FrameProps) => {
  return (
    <div
      className='frame'
      // style={{ gridTemplateColumns: `repeat(${3 * props.wings}, max-content)` }}
    >
      <div
        className='backview'
        style={{
          backgroundImage: `url(${require(`../../../assets/preview/backview-${props.glass}.jpg`)})`
        }}
      ></div>
      <div
        className='frame__top'
        style={{
          backgroundColor: props.color,
          gridColumn: `1 / -1`,
          gridRow: '1 / 2',
          height: `calc(23px * ${props.scale})`
        }}
      >
        <div
          className='frame__top-shadow-1'
          style={{
            width: `calc(100% - 40px * ${props.scale})`,
            left: 20 * props.scale,
            backgroundColor: isColorLight(props.color) ? 'black' : 'white'
          }}
        ></div>
        <div
          className='frame__top-shadow-2'
          style={{
            width: `calc(100% - 44px * ${props.scale})`,
            left: 22 * props.scale,
            opacity: isColorLight(props.color) ? 0.15 : 0.2
          }}
        ></div>
        <div
          className='frame__top-corner-line-left'
          style={{
            backgroundColor: isColorLight(props.color) ? 'black' : 'white',
            opacity: isColorLight(props.color) ? 0.1 : 0.1
          }}
        ></div>
        <div
          className='frame__top-corner-line-right'
          style={{
            backgroundColor: isColorLight(props.color) ? 'black' : 'white',
            opacity: isColorLight(props.color) ? 0.1 : 0.1
          }}
        ></div>
      </div>

      <div
        className='frame__left'
        style={{
          backgroundColor: props.color,
          gridColumn: '1 / 2',
          width: `calc(23px * ${props.scale})`
        }}
      >
        <div
          className='frame__left-shadow-1'
          style={{
            backgroundColor: isColorLight(props.color) ? 'black' : 'white'
          }}
        ></div>
        <div
          className='frame__left-shadow-2'
          style={{
            opacity: isColorLight(props.color) ? 0.15 : 0.2
          }}
        ></div>
      </div>

      {props.children}

      <div
        className='frame__right'
        style={{
          backgroundColor: props.color,
          width: `calc(23px * ${props.scale})`
        }}
      >
        <div
          className='frame__right-shadow-1'
          style={{
            backgroundColor: isColorLight(props.color) ? 'black' : 'white'
          }}
        ></div>
        <div
          className='frame__right-shadow-2'
          style={{
            opacity: isColorLight(props.color) ? 0.15 : 0.2
          }}
        ></div>
      </div>

      <div
        className='frame__bottom'
        style={{
          backgroundColor: props.color,
          gridColumn: `1 / -1`,
          gridRow: '3 / 4',
          height: `calc(23px * ${props.scale})`
        }}
      >
        <div
          className='frame__bottom-shadow-1'
          style={{
            width: `calc(100% - 40px * ${props.scale})`,
            left: 20 * props.scale,
            backgroundColor: isColorLight(props.color) ? 'black' : 'white'
          }}
        ></div>
        <div
          className='frame__bottom-shadow-2'
          style={{
            width: `calc(100% - 44px * ${props.scale})`,
            left: 22 * props.scale,
            opacity: isColorLight(props.color) ? 0.15 : 0.2
          }}
        ></div>
        <div
          className='frame__bottom-corner-line-left'
          style={{
            backgroundColor: isColorLight(props.color) ? 'black' : 'white',
            opacity: isColorLight(props.color) ? 0.1 : 0.1
          }}
        ></div>
        <div
          className='frame__bottom-corner-line-right'
          style={{
            backgroundColor: isColorLight(props.color) ? 'black' : 'white',
            opacity: isColorLight(props.color) ? 0.1 : 0.1
          }}
        ></div>
      </div>
    </div>
  );
};

export default Frame;
