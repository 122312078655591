import { ConfigActionTypes } from './actions';

export interface ProductsConfig {
  [key: number]: {
    id: number;
    type: string;
    labels: { yu: string; en: string };
    amount?: number;
    change?: number;
    price?: number;
    width?: number;
    height?: number;
    overallWidth?: number;
    overallHeight?: number;
    properties: {
      [key: number]: {
        id: number;
        touched?: boolean;
        values?: {
          [key: number]: {
            id: number;
            value?: any;
            touched?: boolean;
            valueId?: number;
            optionId?: number;
            option?: string | number;
          };
        };
        subcategories?: {
          [key: number]: {
            id: number;
            open: boolean;
            valueId?: number;
            value?: string | number;
            optionId?: number;
            option?: string | number;
          };
        };
        open: boolean;
      };
    };
  };
}

const products: ProductsConfig = {
  1: {
    id: 1,
    type: 'EntranceDoor',
    labels: { yu: 'Ulazna vrata', en: 'Entrance Door' },
    properties: {
      1: { id: 1, values: { 1: { id: 1, valueId: 2, value: 'kf-04' } }, open: false },
      2: {
        id: 2,
        values: {
          1: { id: 1, valueId: 0, value: 0 },
          2: { id: 2, valueId: 0, value: 0 },
          3: { id: 3, valueId: 0, value: 0 }
        },
        open: false
      },
      3: {
        id: 3,
        values: {
          1: { id: 1, value: 1100 },
          2: { id: 2, value: 2200 },
          3: { id: 3, value: 400 },
          4: { id: 4, value: 400 },
          5: { id: 5, value: 400 }
        },
        open: false
      },
      4: {
        id: 4,
        subcategories: {
          1: {
            id: 1,
            open: false,
            valueId: 1,
            value: 'salamander-burguman-75'
          },
          2: {
            id: 2,
            open: false
          }
        },
        open: false
      },
      5: {
        id: 5,
        values: {
          1: {
            id: 1,
            valueId: 1,
            value: 'leftInside'
          }
        },
        open: false
      },
      6: {
        id: 6,
        open: false,
        subcategories: {
          1: {
            id: 1,
            valueId: 9,
            open: true,
            value: '#3d4045'
          },
          2: {
            id: 2,
            valueId: 9,
            open: false
          }
        }
      },
      7: {
        id: 7,
        open: false,
        values: { 1: { id: 1, valueId: 1, value: 'standard' } }
      },
      8: {
        id: 8,
        open: false,
        values: {
          1: { id: 1, value: false },
          2: { id: 2, valueId: 1, value: 'opr', option: 'opr1250', optionId: 6 }
        }
      },
      9: {
        id: 9,
        open: false,
        subcategories: {
          1: {
            id: 1,
            valueId: 1,
            open: false,
            value: 'standard'
          },
          2: {
            id: 2,
            valueId: null,
            open: false,
            value: null
          }
        }
      },
      10: {
        id: 10,
        open: false,
        subcategories: {
          1: { id: 1, valueId: 3, value: 'kst23', open: false },
          2: { id: 2, valueId: 3, value: 'kst23', open: false }
        }
      },
      11: {
        id: 11,
        open: false,
        values: { 1: { id: 1, valueId: null, value: null } }
      },
      // 12: {
      //   id: 12,
      //   open: false,
      //   values: { 1: { id: 1, valueId: 1, value: '' } }
      // },
      // 13: {
      //   id: 13,
      //   open: false,
      //   values: { 1: { id: 1, valueId: 1, value: '' } }
      // }
      23: {
        id: 23,
        open: false,
        values: { 1: { id: 1, valueId: 2, value: 'iseo r6' } }
      }
    }
  },
  2: {
    id: 2,
    type: 'BalconyDoor',
    labels: { yu: 'Balkonska vrata', en: 'Balcony Door' },
    properties: {
      3: { id: 3, values: { 1: { id: 1, value: 800 }, 2: { id: 2, value: 2100 } }, open: false },
      4: {
        id: 4,
        open: false,
        subcategories: {
          1: { id: 1, open: false, valueId: 1, value: 'salamander-burguman-75' },
          2: { id: 2, open: false, valueId: null, value: null }
        }
      },
      5: {
        id: 5,
        values: {
          1: {
            id: 1,
            valueId: 1,
            value: 'leftInside'
          }
        },
        open: false
      },
      6: {
        id: 6,
        open: false,
        subcategories: {
          1: {
            id: 1,
            valueId: 9,
            open: true,
            value: '#ffffff'
          },
          2: {
            id: 2,
            valueId: 9,
            open: false
          }
        }
      },
      14: {
        id: 14,
        open: false,
        values: {
          1: {
            id: 1,
            value: 'standard',
            valueId: 1
          }
        }
      },
      15: {
        id: 15,
        open: false,
        values: {
          1: {
            id: 1,
            value: 1,
            valueId: 1
          }
        }
      },
      16: {
        id: 16,
        open: false,
        values: { 1: { id: 1, value: 'transparent', valueId: 1 } }
      }
    }
  },
  3: {
    id: 3,
    type: 'Window',
    labels: { yu: 'Prozor', en: 'Window' },
    properties: {
      3: { id: 3, values: { 1: { id: 1, value: 1000 }, 2: { id: 2, value: 1000 } }, open: false },
      4: {
        id: 4,
        open: false,
        subcategories: {
          1: { id: 1, open: false, valueId: 1, value: 'salamander-burguman-75' },
          2: { id: 2, open: false, valueId: null, value: null }
        }
      },
      5: {
        id: 5,
        values: {
          1: {
            id: 1,
            valueId: 1,
            value: 'leftInside'
          }
        },
        open: false
      },
      6: {
        id: 6,
        open: false,
        subcategories: {
          1: {
            id: 1,
            valueId: 9,
            open: true,
            value: '#ffffff'
          },
          2: {
            id: 2,
            valueId: 9,
            open: false
          }
        }
      },
      14: {
        id: 14,
        open: false,
        values: {
          1: {
            id: 1,
            value: 'standard',
            valueId: 1
          }
        }
      },
      15: {
        id: 15,
        open: false,
        values: {
          1: {
            id: 1,
            value: 1,
            valueId: 1
          }
        }
      },
      16: {
        id: 16,
        open: false,
        values: { 1: { id: 1, value: 'transparent', valueId: 1 } }
      },
      20: {
        id: 20,
        open: false,
        values: {
          1: {
            id: 1,
            value: null,
            valueId: null
          }
        }
      }
    }
  },
  4: {
    id: 4,
    type: 'Panel',
    labels: { yu: 'Panel', en: 'Panel' },
    properties: {
      3: { id: 3, values: { 1: { id: 1, value: 1000 }, 2: { id: 2, value: 1000 } }, open: false },
      4: {
        id: 4,
        open: false,
        subcategories: {
          1: { id: 1, open: false, valueId: 1, value: 'salamander-burguman-75' },
          2: { id: 2, open: false, valueId: null, value: null }
        }
      },
      6: {
        id: 6,
        open: false,
        subcategories: {
          1: {
            id: 1,
            valueId: 9,
            open: true,
            value: '#ffffff'
          },
          2: {
            id: 2,
            valueId: 9,
            open: false
          }
        }
      },
      16: {
        id: 16,
        open: false,
        values: { 1: { id: 1, value: 'transparent', valueId: 1 } }
      }
    }
  },
  5: {
    id: 5,
    type: 'Persiennes',
    labels: { yu: 'Roletne', en: 'Roller shutters' },
    properties: {
      3: { id: 3, values: { 1: { id: 1, value: 1500 }, 2: { id: 2, value: 1900 } }, open: false },
      5: {
        id: 5,
        values: {
          1: {
            id: 1,
            valueId: 1,
            value: 'manual'
          }
        },
        open: false
      },
      22: {
        id: 22,
        open: false,
        subcategories: {
          1: {
            id: 1,
            valueId: 9,
            open: true,
            value: '#d7d5d8'
          },
          2: {
            id: 2,
            valueId: null,
            open: false
          },
          3: {
            id: 3,
            valueId: null,
            open: false
          },
          4: {
            id: 4,
            valueId: null,
            open: false
          }
        }
      },
      // 15: {
      //   id: 15,
      //   open: false,
      //   values: {
      //     1: {
      //       id: 1,
      //       value: 1,
      //       valueId: 1
      //     }
      //   }
      // },
      17: {
        id: 17,
        open: false,
        values: {
          1: {
            id: 1,
            value: 'pento',
            valueId: 1
          }
        }
      },
      18: {
        id: 18,
        open: false,
        values: {
          1: {
            id: 1,
            value: null
          }
        }
      },
      19: {
        id: 19,
        open: false,
        values: {
          1: {
            id: 1,
            value: 'alu39mm',
            valueId: 1
          }
        }
      },
      21: {
        id: 21,
        open: false,
        values: {
          1: {
            id: 1,
            value: 'external',
            valueId: 1
          }
        }
      }
    }
  }
};

//

export interface PropertiesConfig {
  [key: number]: {
    id: number;
    type?: 'subcategory';
    labels: { yu: string; en: string };
    onlyOne?: boolean;
    dependingView?: 'inside' | 'outside';
    depends?: {
      property: number;
      input: number;
      value?: any;
    };
    inputs?: {
      [key: number]: {
        id: number;
        type:
          | 'imageSelect'
          | 'imageOptionSelect'
          | 'select'
          | 'dimension'
          | 'colorSelect'
          | 'checkbox';
        preview: string;
        oneColumn?: boolean;
        uncheckable?: boolean;
        options: {
          [key: number]: {
            id: number;
            labels: {
              yu: string | number;
              en: string | number;
            };
            price: {
              value: number;
              fix?: boolean;
            };
            value: string | number;
            info?: {
              info: { yu: string; en: string };
              bars?: {
                [key: number]: {
                  labels: { yu: string; en: string };
                  precent: number;
                };
              };
            };
            options?: {
              [key: number]: {
                id: number;
                valueId?: number;
                value: number | string;
                labels: { yu: string; en: string };
              };
            };
          };
          min?: number;
          max?: number;
        };
        labels?: { yu: string; en: string };
        depends?: {
          property: number;
          input: number;
          value?: any;
        };
        optionsDepends?: {
          product?: {
            [key: number]: any;
            // {
            // depends?: {
            //   property: number;
            //   input: number;
            //   values: {
            //     [key: string]: {
            //       min: number;
            //       max: number;
            //     };
            //   };
            // };
            min?: number;
            max?: number;
            // };
          };
          property?: number;
          input?: number;
        };
      };
    };
    subcategories?: {
      [key: number]: {
        id: number;
        labels: { yu: string; en: string };
        dependingView?: 'inside' | 'outside';
        inputs: {
          [key: number]: {
            id: number;
            type: string;
            preview: string;
            uncheckable?: boolean;
            optionsDepends?: {
              product: {
                [key: number]: any;
              };
            };
            options: {
              [key: number]: {
                id: number;
                labels: { yu: string; en: string };
                price: {
                  value: number;
                  fix?: boolean;
                };
                value?: string | number;
                info?: {
                  info: { yu: string; en: string };
                  bars?: {
                    [key: number]: {
                      labels: { yu: string; en: string };
                      precent: number;
                    };
                  };
                };
              };
            };
          };
        };
      };
    };
  };
}

const properties: PropertiesConfig = {
  1: {
    id: 1,
    labels: { yu: 'Model', en: 'Model' },
    dependingView: 'outside',
    inputs: {
      1: {
        id: 1,
        type: 'imageSelect',
        preview: 'model',
        options: {
          1: {
            id: 1,
            value: 'barcelona',
            labels: { yu: 'Barcelona', en: 'Barcelona' },
            price: { value: 15 }
          },
          2: { id: 2, value: 'kf-04', labels: { yu: 'KF 04', en: 'KF 04' }, price: { value: 64 } },
          3: { id: 3, value: 'kf-05', labels: { yu: 'KF 05', en: 'KF 05' }, price: { value: 146 } }
        }
      }
    }
  },
  2: {
    id: 2,
    labels: { yu: 'Dodatna stakla', en: 'Additional glass' },
    inputs: {
      1: {
        id: 1,
        type: 'select',
        preview: 'additionalGlassLeft',
        labels: {
          yu: 'Lijeva strana',
          en: 'Left side'
        },
        options: {
          0: { id: 0, value: 0, labels: { yu: 0, en: 0 }, price: { value: 0 } },
          1: { id: 1, value: 1, labels: { yu: 1, en: 1 }, price: { value: 0 } },
          2: { id: 2, value: 2, labels: { yu: 2, en: 2 }, price: { value: 0 } },
          3: { id: 3, value: 3, labels: { yu: 3, en: 3 }, price: { value: 0 } }
        }
      },
      2: {
        id: 2,
        type: 'select',
        preview: 'additionalGlassRight',
        labels: {
          yu: 'Desna strana',
          en: 'Right side'
        },
        options: {
          0: { id: 0, value: 0, labels: { yu: 0, en: 0 }, price: { value: 0 } },
          1: { id: 1, value: 1, labels: { yu: 1, en: 1 }, price: { value: 0 } },
          2: { id: 2, value: 2, labels: { yu: 2, en: 2 }, price: { value: 0 } },
          3: { id: 3, value: 3, labels: { yu: 3, en: 3 }, price: { value: 0 } }
        }
      },
      3: {
        id: 3,
        type: 'select',
        preview: 'additionalGlassTop',
        labels: {
          yu: 'Iznad',
          en: 'Upper'
        },
        options: {
          0: { id: 0, value: 0, labels: { yu: 0, en: 0 }, price: { value: 0 } },
          1: { id: 1, value: 1, labels: { yu: 1, en: 1 }, price: { value: 0 } }
        }
      }
    }
  },
  3: {
    id: 3,
    labels: {
      yu: 'Dimenzije',
      en: 'Dimensions'
    },
    inputs: {
      1: {
        id: 1,
        type: 'dimension',
        preview: 'width',
        labels: {
          yu: 'Širina',
          en: 'Width'
        },
        optionsDepends: {
          product: {
            1: {
              min: 800,
              max: 1300
            },
            2: {
              depends: {
                property: 15,
                input: 1,
                values: {
                  1: {
                    min: 600,
                    max: 900
                  },
                  2: {
                    min: 1200,
                    max: 1800
                  }
                }
              }
            },
            3: {
              depends: {
                property: 15,
                input: 1,
                values: {
                  1: {
                    min: 600,
                    max: 1200
                  },
                  2: {
                    min: 800,
                    max: 1400
                  }
                }
              }
            },
            4: {
              min: 500,
              max: 1800
            },
            5: {
              min: 500,
              max: 1800
            }
          }
        },
        options: {}
      },
      2: {
        id: 2,
        type: 'dimension',
        preview: 'height',
        labels: {
          yu: 'Visina',
          en: 'Height'
        },
        optionsDepends: {
          product: {
            1: {
              min: 1800,
              max: 2500
            },
            2: {
              min: 1800,
              max: 2500
            },
            3: {
              depends: {
                property: 15,
                input: 1,
                values: {
                  1: {
                    min: 600,
                    max: 1200
                  },
                  2: {
                    min: 800,
                    max: 1400
                  }
                }
              }
            },
            4: {
              min: 500,
              max: 2100
            },
            5: {
              min: 500,
              max: 2200
            }
          }
        },
        options: {}
      },
      3: {
        id: 3,
        type: 'dimension',
        preview: 'additionalGlassLeftWidth',
        depends: {
          property: 2,
          input: 1
        },
        labels: {
          yu: 'Širina lijeve strane',
          en: 'Width of right side'
        },
        options: {
          min: 200,
          max: 1400
        }
      },
      4: {
        id: 4,
        type: 'dimension',
        preview: 'additionalGlassRightWidth',
        depends: {
          property: 2,
          input: 2
        },
        labels: {
          yu: 'Širina desne strane',
          en: 'Width of right side'
        },
        options: {
          min: 200,
          max: 1400
        }
      },
      5: {
        id: 5,
        type: 'dimension',
        preview: 'additionalGlassTopHeight',
        depends: {
          property: 2,
          input: 3
        },
        labels: {
          yu: 'Visina stakla iznad',
          en: 'Height of upper glass'
        },
        options: {
          min: 200,
          max: 1000
        }
      }
    }
  },
  4: {
    id: 4,
    type: 'subcategory',
    labels: {
      yu: 'Sistem',
      en: 'System'
    },
    onlyOne: true,
    subcategories: {
      1: {
        id: 1,
        labels: { yu: 'PVC', en: 'PVC' },
        inputs: {
          1: {
            id: 1,
            type: 'imageSelect',
            preview: 'systems',
            optionsDepends: {
              product: {
                5: {
                  1: {
                    id: 1,
                    labels: { yu: '13mm', en: '13mm' },
                    value: '13mm',
                    price: { value: 12 }
                  }
                }
              }
            },
            options: {
              1: {
                id: 1,
                labels: { yu: 'Salamander Burguman 75', en: 'Salamander Burguman 75' },
                value: 'salamander-burguman-75',
                price: { value: 380 },
                info: {
                  info: {
                    yu:
                      'Moderna prozorska tehnika sa 5 komornim sustavom, 76 mm dubinom ugradnje i dvije brtve jamči optimalnu toplinsku i zvučnu izolaciju te visoku sigurnost.',
                    en: ''
                  },
                  bars: {
                    1: {
                      labels: { yu: 'Termoizolacija ', en: 'Termo isolation' },
                      precent: 60
                    },
                    2: {
                      labels: { yu: 'Zvučna izolacija', en: 'Sound isolation' },
                      precent: 82
                    },
                    3: {
                      labels: { yu: 'Cijena', en: 'Price' },
                      precent: 30
                    }
                  }
                }
              },
              2: {
                id: 2,
                labels: { yu: 'Salamander Burguman 90', en: 'Salamander Burguman 90' },
                value: 'salamander-burguman-90',
                price: { value: 523 },
                info: {
                  info: {
                    yu:
                      'Moderna prozorska tehnika sa 5 komornim sustavom, 76 mm dubinom ugradnje i dvije brtve jamči optimalnu toplinsku i zvučnu izolaciju te visoku sigurnost.',
                    en: ''
                  },
                  bars: {
                    1: {
                      labels: { yu: 'Termoizolacija ', en: 'Termo isolation' },
                      precent: 80
                    },
                    2: {
                      labels: { yu: 'Zvučna izolacija', en: 'Sound isolation' },
                      precent: 90
                    },
                    3: {
                      labels: { yu: 'Cijena', en: 'Price' },
                      precent: 50
                    }
                  }
                }
              }
            }
          }
        }
      },
      2: {
        id: 2,
        labels: { yu: 'Aluminijum', en: 'Aluminium' },
        inputs: {
          1: {
            id: 1,
            type: 'imageSelect',
            preview: 'material',
            options: {
              1: {
                id: 1,
                labels: { yu: 'Rehau 75', en: 'Rehau 75' },
                value: 'rehau-75',
                price: { value: 630 }
              },
              2: {
                id: 2,
                labels: { yu: 'Rehau 90', en: 'Rehau 90' },
                value: 'rehau-90',
                price: { value: 765 }
              }
            }
          }
        }
      }
    }
  },
  5: {
    id: 5,
    labels: {
      yu: 'Otvaranje',
      en: 'Opening'
    },
    inputs: {
      1: {
        id: 1,
        type: 'select',
        preview: 'opening',
        labels: { yu: '', en: '' },
        optionsDepends: {
          product: {
            2: {
              1: {
                id: 1,
                labels: { yu: 'Lijevo unutra', en: 'Left inside' },
                value: 'leftInside',
                price: { value: 0 }
              },
              2: {
                id: 2,
                labels: { yu: 'Desno unutra', en: 'Right inside' },
                value: 'rightInside',
                price: { value: 0 }
              },
              3: {
                id: 3,
                labels: { yu: 'Lijevo vani', en: 'Left outside' },
                value: 'leftOutside',
                price: { value: 0 }
              },
              4: {
                id: 4,
                labels: { yu: 'Desno vani', en: 'Right outside' },
                value: 'rightOutside',
                price: { value: 0 }
              },
              5: {
                id: 5,
                labels: { yu: 'Klizno', en: 'Slide' },
                value: 'slide',
                price: { value: 300 }
              }
            },
            5: {
              1: {
                id: 1,
                labels: { yu: 'Ručno', en: 'Manual' },
                value: 'manual',
                price: { value: 0 }
              },
              2: {
                id: 2,
                labels: { yu: 'Elektronski - Prekidač', en: 'Electro - Switch' },
                value: 'electroSwitch',
                price: { value: 150 }
              },
              3: {
                id: 3,
                labels: { yu: 'Elektronski - Daljinski', en: 'Electro - Remote' },
                value: 'electroRemote',
                price: { value: 200 }
              }
            }
          }
        },
        options: {
          1: {
            id: 1,
            labels: { yu: 'Lijevo unutra', en: 'Left inside' },
            value: 'leftInside',
            price: { value: 0 }
          },
          2: {
            id: 2,
            labels: { yu: 'Desno unutra', en: 'Right inside' },
            value: 'rightInside',
            price: { value: 0 }
          },
          3: {
            id: 3,
            labels: { yu: 'Lijevo vani', en: 'Left outside' },
            value: 'leftOutside',
            price: { value: 0 }
          },
          4: {
            id: 4,
            labels: { yu: 'Desno vani', en: 'Right outside' },
            value: 'rightOutside',
            price: { value: 0 }
          }
        }
      }
    }
  },
  6: {
    id: 6,
    type: 'subcategory',
    labels: {
      yu: 'Boja',
      en: 'Color'
    },
    onlyOne: false,
    subcategories: {
      1: {
        id: 1,
        labels: { yu: 'Panel', en: 'Panel' },
        inputs: {
          1: {
            id: 1,
            type: 'colorSelect',
            preview: 'color',
            options: {
              1: {
                id: 1,
                labels: { yu: 'RAL 9584', en: 'RAL 9584' },
                value: '#ffffff',
                price: { value: 0 }
              },
              2: {
                id: 2,
                labels: { yu: 'RAL 5874', en: 'RAL 5874' },
                value: '#41494C',
                price: { value: 7 }
              },
              3: {
                id: 3,
                labels: { yu: 'RAL 8965', en: 'RAL 8965' },
                value: '#660101',
                price: { value: 13 }
              },
              4: {
                id: 4,
                labels: { yu: 'RAL 8941', en: 'RAL 8965' },
                value: '#838281',
                price: { value: 8 }
              },
              5: {
                id: 5,
                labels: { yu: 'RAL 8945', en: 'RAL 8965' },
                value: '#3E2809',
                price: { value: 16 }
              },
              6: {
                id: 6,
                labels: { yu: 'RAL 2854', en: 'RAL 8965' },
                value: '#033871',
                price: { value: 9 }
              },
              7: {
                id: 7,
                labels: { yu: 'RAL 7984', en: 'RAL 8965' },
                value: '#8A6126',
                price: { value: 11 }
              },
              8: {
                id: 8,
                labels: { yu: 'RAL 9251', en: 'RAL 8965' },
                value: '#AF2B1E',
                price: { value: 23 }
              },
              9: {
                id: 9,
                labels: { yu: 'RAL 6458', en: 'RAL 6458' },
                value: '#3d4045',
                price: { value: 14 }
              },
              10: {
                id: 10,
                labels: { yu: 'RAL 9865', en: 'RAL 9865' },
                value: '#2a3237',
                price: { value: 19 }
              },
              11: {
                id: 11,
                labels: { yu: 'RAL 8957', en: 'RAL 8957' },
                value: '#d7d5d8',
                price: { value: 19 }
              }
            }
          }
        }
      },
      2: {
        id: 2,
        labels: { yu: 'Okvir', en: 'Frame' },
        inputs: {
          1: {
            id: 1,
            type: 'colorSelect',
            preview: 'frameColor',
            uncheckable: true,
            options: {
              1: {
                id: 1,
                labels: { yu: 'RAL 9584', en: 'RAL 9584' },
                value: '#ffffff',
                price: { value: 0 }
              },
              2: {
                id: 2,
                labels: { yu: 'RAL 5874', en: 'RAL 5874' },
                value: '#41494C',
                price: { value: 0 }
              },
              3: {
                id: 3,
                labels: { yu: 'RAL 8965', en: 'RAL 8965' },
                value: '#660101',
                price: { value: 0 }
              },
              4: {
                id: 4,
                labels: { yu: 'RAL 8941', en: 'RAL 8965' },
                value: '#838281',
                price: { value: 0 }
              },
              5: {
                id: 5,
                labels: { yu: 'RAL 8945', en: 'RAL 8965' },
                value: '#3E2809',
                price: { value: 0 }
              },
              6: {
                id: 6,
                labels: { yu: 'RAL 2854', en: 'RAL 8965' },
                value: '#033871',
                price: { value: 0 }
              },
              7: {
                id: 7,
                labels: { yu: 'RAL 7984', en: 'RAL 8965' },
                value: '#8A6126',
                price: { value: 0 }
              },
              8: {
                id: 8,
                labels: { yu: 'RAL 9251', en: 'RAL 8965' },
                value: '#AF2B1E',
                price: { value: 0 }
              },
              9: {
                id: 9,
                labels: { yu: 'RAL 6458', en: 'RAL 6458' },
                value: '#3d4045',
                price: { value: 0 }
              },
              10: {
                id: 10,
                labels: { yu: 'RAL 9865', en: 'RAL 9865' },
                value: '#2a3237',
                price: { value: 0 }
              },
              11: {
                id: 11,
                labels: { yu: 'RAL 8957', en: 'RAL 8957' },
                value: '#d7d5d8',
                price: { value: 19 }
              }
            }
          }
        }
      }
    }
  },
  7: {
    id: 7,
    labels: {
      yu: 'Brava',
      en: 'Lock'
    },
    inputs: {
      1: {
        id: 1,
        type: 'imageSelect',
        preview: 'lock',
        options: {
          1: {
            id: 1,
            labels: { yu: 'Standard', en: 'Standard' },
            value: 'standard',
            price: { value: 14, fix: true }
          }
        }
      }
    }
  },
  8: {
    id: 8,
    labels: {
      yu: 'Rukohvat',
      en: 'Handrail'
    },
    inputs: {
      1: {
        id: 1,
        type: 'checkbox',
        preview: 'handrailInside',
        labels: { yu: 'Isto unutra', en: 'Same inside' },
        options: {}
      },
      2: {
        id: 2,
        type: 'imageOptionSelect',
        preview: 'handrail',
        options: {
          1: {
            id: 1,
            labels: { yu: 'OPR', en: 'OPR' },
            value: 'opr',
            price: { value: 23, fix: true },
            options: {
              1: { id: 1, value: 'opr320', labels: { yu: 'OPR320', en: 'OPR320' } },
              2: { id: 2, value: 'opr550', labels: { yu: 'OPR550', en: 'OPR550' } },
              3: { id: 3, value: 'opr750', labels: { yu: 'OPR750', en: 'OPR750' } },
              4: { id: 4, value: 'opr950', labels: { yu: 'OPR950', en: 'OPR950' } },
              5: { id: 5, value: 'opr1150', labels: { yu: 'OPR1150', en: 'OPR1150' } },
              6: { id: 6, value: 'opr1250', labels: { yu: 'OPR1250', en: 'OPR1250' } },
              7: { id: 7, value: 'opr1500', labels: { yu: 'OPR1500', en: 'OPR1500' } },
              8: { id: 8, value: 'opr1800', labels: { yu: 'OPR1800', en: 'OPR1800' } },
              9: { id: 9, value: 'opr2000', labels: { yu: 'OPR2000', en: 'OPR2000' } }
            }
          },
          2: {
            id: 2,
            labels: { yu: 'GFR', en: 'GFR' },
            value: 'gfr',
            price: { value: 23, fix: true },
            options: {
              1: { id: 1, value: 'gfr320', labels: { yu: 'GFR320', en: 'GFR320' } },
              2: { id: 2, value: 'gfr550', labels: { yu: 'GFR550', en: 'GFR550' } },
              3: { id: 3, value: 'gfr750', labels: { yu: 'GFR750', en: 'GFR750' } },
              4: { id: 4, value: 'gfr950', labels: { yu: 'GFR950', en: 'GFR950' } },
              5: { id: 5, value: 'gfr1150', labels: { yu: 'GFR1150', en: 'GFR1150' } },
              6: { id: 6, value: 'gfr1250', labels: { yu: 'GFR1250', en: 'GFR1250' } },
              7: { id: 7, value: 'gfr1500', labels: { yu: 'GFR1500', en: 'GFR1500' } },
              8: { id: 8, value: 'gfr1800', labels: { yu: 'GFR1800', en: 'GFR1800' } },
              9: { id: 9, value: 'gfr2000', labels: { yu: 'GFR2000', en: 'GFR2000' } }
            }
          }
        }
      }
    }
  },
  9: {
    id: 9,
    labels: { yu: 'Kvaka', en: 'Handle' },
    type: 'subcategory',
    onlyOne: false,
    subcategories: {
      1: {
        id: 1,
        labels: { yu: 'Unutra', en: 'Inside' },
        dependingView: 'inside',
        inputs: {
          1: {
            id: 1,
            type: 'imageSelect',
            preview: 'handleInside',
            options: {
              1: {
                id: 1,
                labels: { yu: 'Standard', en: 'Standard' },
                value: 'standard',
                price: { value: 0 }
              }
            }
          }
        }
      },
      2: {
        id: 2,
        labels: { yu: 'Vani', en: 'Outside' },
        dependingView: 'outside',
        inputs: {
          1: {
            id: 1,
            type: 'imageSelect',
            preview: 'handleOutside',
            uncheckable: true,
            options: {
              1: {
                id: 1,
                labels: { yu: 'Standard', en: 'Standard' },
                value: 'standard',
                price: { value: 0 }
              }
            }
          }
        }
      }
    }
  },
  10: {
    id: 10,
    labels: { yu: 'Rozeta', en: 'Rosette' },
    type: 'subcategory',
    subcategories: {
      1: {
        id: 1,
        labels: { yu: 'Vani', en: 'Outside' },
        dependingView: 'outside',
        inputs: {
          1: {
            id: 1,
            type: 'imageSelect',
            preview: 'rosetteOutside',
            options: {
              1: {
                id: 1,
                labels: { yu: 'Standard', en: 'Standard' },
                value: 'standard',
                price: { value: 0 }
              },
              2: {
                id: 2,
                labels: { yu: 'KRP18', en: 'KRP18' },
                value: 'krp18',
                price: { value: 0 }
              },
              3: {
                id: 3,
                labels: { yu: 'KST23', en: 'KST23' },
                value: 'kst23',
                price: { value: 0 }
              }
            }
          }
        }
      },
      2: {
        id: 2,
        labels: { yu: 'Unutra', en: 'Inside' },
        dependingView: 'inside',
        inputs: {
          1: {
            id: 1,
            type: 'imageSelect',
            preview: 'rosetteInside',
            options: {
              1: {
                id: 1,
                labels: { yu: 'Standard', en: 'Standard' },
                value: 'standard',
                price: { value: 0 }
              },
              2: {
                id: 2,
                labels: { yu: 'KRP18', en: 'KRP18' },
                value: 'krp18',
                price: { value: 0 }
              },
              3: {
                id: 3,
                labels: { yu: 'KST23', en: 'KST23' },
                value: 'kst23',
                price: { value: 0 }
              }
            }
          }
        }
      }
    }
  },
  11: {
    id: 11,
    labels: { yu: 'Zaštita za panel', en: 'Panel protection' },
    dependingView: 'outside',
    inputs: {
      1: {
        id: 1,
        type: 'imageSelect',
        preview: 'protection',
        oneColumn: true,
        uncheckable: true,
        options: {
          1: {
            id: 1,
            labels: { yu: 'WSA50', en: 'WSA50' },
            value: 'wsa50',
            price: { value: 20, fix: true }
          },
          2: {
            id: 2,
            labels: { yu: 'KPG30', en: 'KPG30' },
            value: 'kpg30',
            price: { value: 20, fix: true }
          }
        }
      }
    }
  },
  12: {
    id: 12,
    labels: { yu: 'Špijunka', en: 'Door viewer' },
    inputs: {
      1: {
        id: 1,
        type: 'imageSelect',
        preview: 'doorViewer',
        uncheckable: true,
        options: {}
      }
    }
  },
  13: {
    id: 13,
    labels: { yu: 'Kućni broj', en: 'House number' },
    dependingView: 'outside',
    inputs: {
      1: {
        id: 1,
        type: 'imageSelect',
        preview: 'houseNumber',
        uncheckable: true,
        options: {}
      }
    }
  },
  14: {
    id: 14,
    labels: { yu: 'Kvaka', en: 'Handle' },
    dependingView: 'inside',
    inputs: {
      1: {
        id: 1,
        type: 'imageSelect',
        preview: 'handle',
        options: {
          1: {
            id: 1,
            value: 'standard',
            labels: { yu: 'Standard', en: 'Standard' },
            price: { value: 0 }
          }
        }
      }
    }
  },
  15: {
    id: 15,
    labels: { yu: 'Krila', en: 'Wings' },
    inputs: {
      1: {
        id: 1,
        type: 'select',
        preview: 'wings',
        labels: {
          yu: '',
          en: ''
        },
        options: {
          1: { id: 1, value: 1, labels: { yu: 1, en: 1 }, price: { value: 0 } },
          2: { id: 2, value: 2, labels: { yu: 2, en: 2 }, price: { value: 0 } }
        }
      }
    }
  },
  16: {
    id: 16,
    labels: { yu: 'Staklo', en: 'Glass' },
    inputs: {
      1: {
        id: 1,
        type: 'imageSelect',
        preview: 'glass',
        options: {
          1: {
            id: 1,
            labels: { yu: 'Providno', en: 'Transparent' },
            value: 'transparent',
            price: { value: 0 }
          },
          2: {
            id: 2,
            labels: { yu: 'Mraz', en: 'Frosted' },
            value: 'frosted',
            price: { value: 0 }
          },
          3: {
            id: 3,
            labels: { yu: 'Pijesak', en: 'Sand' },
            value: 'sand',
            price: { value: 0 }
          },
          4: {
            id: 4,
            labels: { yu: 'Mutno', en: 'Blur' },
            value: 'blur',
            price: { value: 0 }
          }
        }
      }
    }
  },
  17: {
    id: 17,
    labels: { yu: 'Oblik kutije', en: 'Box shape' },
    depends: {
      property: 21,
      input: 1,
      value: 'external'
    },
    inputs: {
      1: {
        id: 1,
        type: 'imageSelect',
        preview: 'boxShape',
        options: {
          1: {
            id: 1,
            labels: { yu: 'Pento', en: 'Pento' },
            value: 'pento',
            price: { value: 0 }
          },
          2: {
            id: 2,
            labels: { yu: 'Pentoval', en: 'Pentoval' },
            value: 'pentoval',
            price: { value: 0 }
          },
          3: {
            id: 3,
            labels: { yu: 'Round', en: 'Round' },
            value: 'round',
            price: { value: 0 }
          },
          4: {
            id: 4,
            labels: { yu: 'Quadro', en: 'Quadro' },
            value: 'quadro',
            price: { value: 0 }
          }
        }
      }
    }
  },
  18: {
    id: 18,
    labels: { yu: 'Komarnik', en: 'Insect screen' },
    inputs: {
      1: {
        id: 1,
        type: 'imageSelect',
        preview: 'insectScreen',
        uncheckable: true,
        options: {
          1: {
            id: 1,
            labels: { yu: 'Integrisani', en: 'Integrated' },
            value: 'integrated',
            price: { value: 15 }
          }
        }
      }
    }
  },
  19: {
    id: 19,
    labels: { yu: 'Lamele', en: 'Slats' },
    inputs: {
      1: {
        id: 1,
        type: 'imageSelect',
        preview: null,
        options: {
          1: {
            id: 1,
            labels: { yu: 'ALU 39mm', en: 'ALU 39mm' },
            price: { value: 85 },
            value: 'alu39mm'
          },
          2: {
            id: 2,
            labels: { yu: 'PVC 39mm', en: 'PVC 39mm' },
            price: { value: 80 },
            value: 'pvc39mm'
          }
        }
      }
    }
  },
  20: {
    id: 20,
    labels: { yu: 'Klupica', en: 'Sill' },
    inputs: {
      1: {
        id: 1,
        type: 'imageSelect',
        preview: 'sill',
        uncheckable: true,
        options: {
          1: {
            id: 1,
            value: 'pvc',
            labels: { yu: 'PVC', en: 'PVC' },
            price: { value: 10 }
          }
        }
      }
    }
  },
  21: {
    id: 21,
    labels: { yu: 'Sistem', en: 'System' },
    inputs: {
      1: {
        id: 1,
        type: 'imageSelect',
        preview: 'system',
        options: {
          1: {
            id: 1,
            labels: { yu: 'Predprozorske', en: 'External' },
            value: 'external',
            price: { value: 0 }
          },
          2: {
            id: 2,
            labels: { yu: 'Nadprozorske', en: 'Built in' },
            value: 'builtIn',
            price: { value: 50, fix: true }
          }
        }
      }
    }
  },
  22: {
    id: 22,
    type: 'subcategory',
    labels: {
      yu: 'Boja',
      en: 'Color'
    },
    onlyOne: false,
    subcategories: {
      1: {
        id: 1,
        labels: { yu: 'Roletne', en: 'Shutters' },
        inputs: {
          1: {
            id: 1,
            type: 'colorSelect',
            preview: 'color',
            options: {
              1: {
                id: 1,
                labels: { yu: 'RAL 9584', en: 'RAL 9584' },
                value: '#ffffff',
                price: { value: 0 }
              },
              2: {
                id: 2,
                labels: { yu: 'RAL 5874', en: 'RAL 5874' },
                value: '#41494C',
                price: { value: 7 }
              },
              3: {
                id: 3,
                labels: { yu: 'RAL 8965', en: 'RAL 8965' },
                value: '#660101',
                price: { value: 13 }
              },
              4: {
                id: 4,
                labels: { yu: 'RAL 8941', en: 'RAL 8965' },
                value: '#838281',
                price: { value: 8 }
              },
              5: {
                id: 5,
                labels: { yu: 'RAL 8945', en: 'RAL 8965' },
                value: '#3E2809',
                price: { value: 16 }
              },
              6: {
                id: 6,
                labels: { yu: 'RAL 2854', en: 'RAL 8965' },
                value: '#033871',
                price: { value: 9 }
              },
              7: {
                id: 7,
                labels: { yu: 'RAL 7984', en: 'RAL 8965' },
                value: '#8A6126',
                price: { value: 11 }
              },
              8: {
                id: 8,
                labels: { yu: 'RAL 9251', en: 'RAL 8965' },
                value: '#AF2B1E',
                price: { value: 23 }
              },
              9: {
                id: 9,
                labels: { yu: 'RAL 6458', en: 'RAL 6458' },
                value: '#3d4045',
                price: { value: 14 }
              },
              10: {
                id: 10,
                labels: { yu: 'RAL 9865', en: 'RAL 9865' },
                value: '#2a3237',
                price: { value: 19 }
              },
              11: {
                id: 11,
                labels: { yu: 'RAL 8957', en: 'RAL 8957' },
                value: '#d7d5d8',
                price: { value: 19 }
              }
            }
          }
        }
      },
      2: {
        id: 2,
        labels: { yu: 'Vodilice', en: 'Rail guides' },
        inputs: {
          1: {
            id: 1,
            type: 'colorSelect',
            preview: 'coverColor',
            uncheckable: true,
            options: {
              1: {
                id: 1,
                labels: { yu: 'RAL 9584', en: 'RAL 9584' },
                value: '#ffffff',
                price: { value: 0 }
              },
              2: {
                id: 2,
                labels: { yu: 'RAL 5874', en: 'RAL 5874' },
                value: '#41494C',
                price: { value: 0 }
              },
              3: {
                id: 3,
                labels: { yu: 'RAL 8965', en: 'RAL 8965' },
                value: '#660101',
                price: { value: 0 }
              },
              4: {
                id: 4,
                labels: { yu: 'RAL 8941', en: 'RAL 8965' },
                value: '#838281',
                price: { value: 0 }
              },
              5: {
                id: 5,
                labels: { yu: 'RAL 8945', en: 'RAL 8965' },
                value: '#3E2809',
                price: { value: 0 }
              },
              6: {
                id: 6,
                labels: { yu: 'RAL 2854', en: 'RAL 8965' },
                value: '#033871',
                price: { value: 0 }
              },
              7: {
                id: 7,
                labels: { yu: 'RAL 7984', en: 'RAL 8965' },
                value: '#8A6126',
                price: { value: 0 }
              },
              8: {
                id: 8,
                labels: { yu: 'RAL 9251', en: 'RAL 8965' },
                value: '#AF2B1E',
                price: { value: 0 }
              },
              9: {
                id: 9,
                labels: { yu: 'RAL 6458', en: 'RAL 6458' },
                value: '#3d4045',
                price: { value: 0 }
              },
              10: {
                id: 10,
                labels: { yu: 'RAL 9865', en: 'RAL 9865' },
                value: '#2a3237',
                price: { value: 0 }
              },
              11: {
                id: 11,
                labels: { yu: 'RAL 8957', en: 'RAL 8957' },
                value: '#d7d5d8',
                price: { value: 19 }
              }
            }
          }
        }
      },
      3: {
        id: 3,
        labels: { yu: 'Kutija', en: 'Box' },
        inputs: {
          1: {
            id: 1,
            type: 'colorSelect',
            preview: 'boxColor',
            uncheckable: true,
            options: {
              1: {
                id: 1,
                labels: { yu: 'RAL 9584', en: 'RAL 9584' },
                value: '#ffffff',
                price: { value: 0 }
              },
              2: {
                id: 2,
                labels: { yu: 'RAL 5874', en: 'RAL 5874' },
                value: '#41494C',
                price: { value: 0 }
              },
              3: {
                id: 3,
                labels: { yu: 'RAL 8965', en: 'RAL 8965' },
                value: '#660101',
                price: { value: 0 }
              },
              4: {
                id: 4,
                labels: { yu: 'RAL 8941', en: 'RAL 8965' },
                value: '#838281',
                price: { value: 0 }
              },
              5: {
                id: 5,
                labels: { yu: 'RAL 8945', en: 'RAL 8965' },
                value: '#3E2809',
                price: { value: 0 }
              },
              6: {
                id: 6,
                labels: { yu: 'RAL 2854', en: 'RAL 8965' },
                value: '#033871',
                price: { value: 0 }
              },
              7: {
                id: 7,
                labels: { yu: 'RAL 7984', en: 'RAL 8965' },
                value: '#8A6126',
                price: { value: 0 }
              },
              8: {
                id: 8,
                labels: { yu: 'RAL 9251', en: 'RAL 8965' },
                value: '#AF2B1E',
                price: { value: 0 }
              },
              9: {
                id: 9,
                labels: { yu: 'RAL 6458', en: 'RAL 6458' },
                value: '#3d4045',
                price: { value: 0 }
              },
              10: {
                id: 10,
                labels: { yu: 'RAL 9865', en: 'RAL 9865' },
                value: '#2a3237',
                price: { value: 0 }
              },
              11: {
                id: 11,
                labels: { yu: 'RAL 8957', en: 'RAL 8957' },
                value: '#d7d5d8',
                price: { value: 19 }
              }
            }
          }
        }
      },
      4: {
        id: 4,
        labels: { yu: 'Letvica', en: 'Skirting' },
        inputs: {
          1: {
            id: 1,
            type: 'colorSelect',
            preview: 'skirtingColor',
            uncheckable: true,
            options: {
              1: {
                id: 1,
                labels: { yu: 'RAL 9584', en: 'RAL 9584' },
                value: '#ffffff',
                price: { value: 0 }
              },
              2: {
                id: 2,
                labels: { yu: 'RAL 5874', en: 'RAL 5874' },
                value: '#41494C',
                price: { value: 0 }
              },
              3: {
                id: 3,
                labels: { yu: 'RAL 8965', en: 'RAL 8965' },
                value: '#660101',
                price: { value: 0 }
              },
              4: {
                id: 4,
                labels: { yu: 'RAL 8941', en: 'RAL 8965' },
                value: '#838281',
                price: { value: 0 }
              },
              5: {
                id: 5,
                labels: { yu: 'RAL 8945', en: 'RAL 8965' },
                value: '#3E2809',
                price: { value: 0 }
              },
              6: {
                id: 6,
                labels: { yu: 'RAL 2854', en: 'RAL 8965' },
                value: '#033871',
                price: { value: 0 }
              },
              7: {
                id: 7,
                labels: { yu: 'RAL 7984', en: 'RAL 8965' },
                value: '#8A6126',
                price: { value: 0 }
              },
              8: {
                id: 8,
                labels: { yu: 'RAL 9251', en: 'RAL 8965' },
                value: '#AF2B1E',
                price: { value: 0 }
              },
              9: {
                id: 9,
                labels: { yu: 'RAL 6458', en: 'RAL 6458' },
                value: '#3d4045',
                price: { value: 0 }
              },
              10: {
                id: 10,
                labels: { yu: 'RAL 9865', en: 'RAL 9865' },
                value: '#2a3237',
                price: { value: 0 }
              },
              11: {
                id: 11,
                labels: { yu: 'RAL 8957', en: 'RAL 8957' },
                value: '#d7d5d8',
                price: { value: 19 }
              }
            }
          }
        }
      }
    }
  },
  23: {
    id: 23,
    labels: { yu: 'Cilindar', en: 'Cylinder' },
    inputs: {
      1: {
        id: 1,
        type: 'imageSelect',
        preview: null,
        options: {
          1: {
            id: 1,
            labels: { yu: 'ISEO F5', en: 'ISEO F5' },
            price: { value: 0 },
            value: 'iseo f5',
            info: {
              info: { yu: 'Klasičan niklovani cilindar za svakodnevnu uporabu', en: '' }
            }
          },
          2: {
            id: 2,
            labels: { yu: 'ISEO R6', en: 'ISEO R6' },
            price: { value: 0 },
            value: 'iseo r6',
            info: {
              info: {
                yu: 'Sigurnosni radijalni cilindar, 6 pinova. Zaštita protiv češljanja i bušenja',
                en: ''
              }
            }
          },
          3: {
            id: 3,
            labels: { yu: 'BKS Detect 3', en: 'BKS Detect 3' },
            price: { value: 0 },
            value: 'bks detect 3',
            info: {
              info: {
                yu:
                  'Patentirani profil do 2025.godine sa vlasničkom karticom. Ključevi se umnožavaju isključivo uz predočenje kartice.',
                en: ''
              }
            }
          },
          4: {
            id: 4,
            labels: { yu: 'BKS 50', en: 'BKS Detect 3' },
            price: { value: 0 },
            value: 'bks 50',
            info: {
              info: {
                yu:
                  'Patentirani profil do 2024.godine sa vlasničkom karticom. Ključevi se umnožavaju isključivo uz predočenje kartice. Prekretni profil.',
                en: ''
              }
            }
          },
          5: {
            id: 5,
            labels: { yu: 'BKS Janus', en: 'BKS Janus' },
            price: { value: 0 },
            value: 'bks janus',
            info: {
              info: {
                yu:
                  'Tvornički profil tvornice BKS. Ključevi se umnožavaju isključivo uz predočenje kartice u poduzeću tvornici BKS. Prekretni modularni profil.',
                en: ''
              }
            }
          },
          6: {
            id: 6,
            labels: { yu: 'CISA LOGO', en: 'CISA LOGO' },
            price: { value: 0 },
            value: 'cisa logo',
            info: {
              info: { yu: 'Ovalni cilindar sa 3 ključa LOGO line', en: '' }
            }
          },
          7: {
            id: 7,
            labels: { yu: 'Mottura Champions Pro', en: 'Mottura Champions Pro' },
            price: { value: 0 },
            value: 'mottura champions pro',
            info: {
              info: {
                yu:
                  'Vrhunski cilindar patentiranog profila, sa pet ključeva i vlasničkom karticom. doživotni MM patent što se tiče kopiranja ključeva. dodatni ključevi izrađuju se isključivo uz predočenje vlasničke kartice.',
                en: 'Nothing here'
              },
              bars: {}
            }
          }
        }
      }
    }
  }
};

export interface General {
  showPrice: boolean;
  currency: { [key: string]: CurrencyConfig };
}

export interface CurrencyConfig {
  value: string;
  label: string;
  symbol: string;
  side: 'left' | 'right';
  currencyValue: number;
  primary: boolean;
}

const general: General = {
  showPrice: true,
  currency: {
    bam: {
      value: 'bam',
      label: 'BAM',
      symbol: 'KM',
      side: 'right',
      currencyValue: 1,
      primary: true
    },
    eur: {
      value: 'eur',
      label: 'EUR',
      symbol: '€',
      side: 'left',
      currencyValue: 0.51,
      primary: false
    }
  }
};

const initState = {
  products,
  properties,
  general
};

const reducer = (state = initState, action: ConfigActionTypes) => {
  switch (action.type) {
    case 'CONFIG_INIT':
    // return action.payload;
    default:
      return state;
  }
};

export type ConfigState = typeof initState;

export default reducer;
