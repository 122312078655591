import React, { useEffect } from 'react';
import './sessionPanel.scss';
import SessionItem from './sessionItem/sessionItem';
import { connect, useDispatch } from 'react-redux';
import { State } from '../../store/store';
import { config } from '../../store/UI/action';
import { deleteProduct } from '../../store/session/actions';
import { ProductsConfig, CurrencyConfig } from './../../store/config/reducer';
import Button from '../../components/button/button';
import { ReactComponent as Plus } from '../../assets/plus.svg';
import { UITogglePanels } from './../../store/UI/action';
import { UIScreen } from './../../store/UI/reducer';

export interface SessionPanelProps {
  session: ProductsConfig;
  language: string;
  showPrice: boolean;
  panels: any;
  screen: UIScreen;
  translation: any;
  currencyConfig: CurrencyConfig;
}

const SessionPanel: React.SFC<SessionPanelProps> = (props: SessionPanelProps) => {
  const dispatch = useDispatch();

  const handleOnClick = (entryId: number) => {
    dispatch(config(entryId));
    if (props.screen.tabletPort) {
      dispatch(UITogglePanels({ config: true, session: false }));
    } else {
      dispatch(UITogglePanels({ config: true }));
    }
  };

  const handleOnDelete = (entryId: number) => {
    dispatch(config(null));
    dispatch(deleteProduct({ entryId }));
  };

  const handleOnSend = () => {
    dispatch(UITogglePanels({ request: true, session: false }));
  };

  return (
    <div
      className='session-panel'
      style={{ marginRight: props.screen.tabletPort ? (props.panels.session ? 0 : '-47.5rem') : 0 }}
    >
      <div className='session-panel__wrapper'>
        <h1 className='session-panel__title'>{props.translation.entered}</h1>
        <hr />
        <div className='session-panel__items'>
          {Object.keys(props.session).length > 0 &&
            Object.keys(props.session).map(entryId => (
              <React.Fragment key={entryId}>
                <SessionItem
                  type={props.session[+entryId].type}
                  label={
                    props.session[+entryId].amount +
                    'x ' +
                    props.session[+entryId].labels[props.language]
                  }
                  sublabel={'1100mm x 2200mm'}
                  price={
                    props.showPrice &&
                    (props.currencyConfig.side === 'left' ? props.currencyConfig.symbol : '') +
                      Math.round(
                        (props.session[+entryId].price ?? 0) * props.session[+entryId].amount
                      ) +
                      '.00' +
                      (props.currencyConfig.side === 'right'
                        ? ' ' + props.currencyConfig.symbol
                        : '')
                  }
                  onDelete={() => handleOnDelete(+entryId)}
                  onClick={() => handleOnClick(+entryId)}
                />
                <hr />
              </React.Fragment>
            ))}
        </div>
        {Object.keys(props.session).length > 0 ? (
          <Button label={props.translation.send} onClick={() => handleOnSend()} />
        ) : (
          <>
            <div
              className='session-panel__empty'
              onClick={() => dispatch(UITogglePanels({ addProduct: true }))}
            >
              <Plus className='session-panel__empty-plus' />
              <p className='session-panel__empty-label'>{props.translation.addProduct}</p>
            </div>
            <p className='session-panel__empty-label-2'>{props.translation.nothingAdded}</p>
          </>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    session: state.session,
    language: state.UI.language,
    showPrice: state.config.general.showPrice,
    panels: state.UI.panels,
    screen: state.UI.screen,
    translation: state.translation,
    currencyConfig: state.config.general.currency[state.UI.currency]
  };
};

export default connect(mapStateToProps)(SessionPanel);
