import React, { useEffect, useState, useRef } from 'react';
import './details.scss';
import DetailsItem from './detailsItem/detailsItem';
import { connect, useDispatch } from 'react-redux';
import { State } from '../../store/store';
import { ConfigState, ProductsConfig } from '../../store/config/reducer';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import { UITogglePanels, config } from './../../store/UI/action';

export interface DetailsProps {
  config: ConfigState;
  session: ProductsConfig;
  language: string;
  currency: string;
  screen: any;
}

const Details: React.SFC<DetailsProps> = (props: DetailsProps) => {
  const [entries, setEntries] = useState<null | [{ head: any[]; properties: any[] }]>(null);
  const detailsRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    //  Only like that
    dispatch(UITogglePanels({ session: false }));
    dispatch(config(null));
  }, []);

  useEffect(() => {
    const productEntries: [{ head: any[]; properties: any[] }] = [{ head: [], properties: [] }];
    Object.keys(props.session).forEach((productEntryKey, index) => {
      const productEntry = props.session[+productEntryKey];
      productEntries[+index] = {
        head: [+index + 1, productEntry.labels[props.language], productEntry.amount, '1200.00'],
        properties: []
      };

      Object.keys(productEntry.properties).forEach(propertyId => {
        const property = productEntry.properties[+propertyId];

        if (!property.subcategories) {
          Object.keys(property.values).forEach(valueId => {
            const propertyInput = property.values[+valueId];
            const propertyConfig = props.config.properties[+propertyId];
            const propertyInputConfig = propertyConfig.inputs[+valueId];

            if (propertyInput.valueId) {
              const propertyLabel = propertyConfig.labels[props.language];
              const propertyValueLabel =
                propertyInputConfig.options[propertyInput.valueId].labels[props.language];
              const propertyOptionLabel = propertyInput.optionId
                ? propertyInputConfig.options[propertyInput.valueId].options[propertyInput.optionId]
                    .labels[props.language]
                : null;

              propertyInput.valueId &&
                productEntries[+index].properties.push([
                  propertyLabel,
                  propertyOptionLabel ? propertyOptionLabel : propertyValueLabel
                ]);
            }
          });
        } else {
          Object.keys(property.subcategories).forEach(subcategoryId => {
            const propertyInput = property.subcategories[+subcategoryId];
            const propertyConfig = props.config.properties[+propertyId];
            const propertySubcategoryConfig =
              props.config.properties[+propertyId].subcategories[+subcategoryId];
            const propertyInputConfig = propertySubcategoryConfig.inputs[1];

            if (propertyInput.valueId) {
              const propertyLabel = propertyConfig.labels[props.language];
              const propertySubcategoryLabel = propertySubcategoryConfig.labels[props.language];
              const propertyValueLabel =
                propertyInputConfig.options[propertyInput.valueId].labels[props.language];
              // const propertyOptionLabel = propertyInput.optionId
              //   ? propertyInputConfig.options[propertyInput.valueId].options[propertyInput.optionId]
              //       .labels[props.language]
              //   : null;

              propertyInput.valueId &&
                productEntries[+index].properties.push([
                  propertyLabel + ' ' + propertySubcategoryLabel,
                  // propertyOptionLabel ? propertyOptionLabel : propertyValueLabel
                  propertyValueLabel
                ]);
            }
          });
        }
      });
    });

    setEntries(productEntries);

    // PDF Generation from image
    // Turn off box shadow on details-holder
    // if (detailsRef.current) {
    //   html2canvas(detailsRef.current).then(canvas => {
    //     // @ts-ignore
    //     var img = canvas.toDataURL('image/jpeg');

    //     window.open(canvas.toDataURL('image/jpeg').replace('image/jpeg', 'image/octet-stream'));

    //     const PDF = new jsPDF({ orientation: 'p', format: 'a4', unit: 'cm' });

    //     // @ts-ignore
    //     PDF.addImage(img, 'JPEG', 0, 0);

    //     PDF.output('dataurlnewwindow');
    //   });
    // }
  }, [props.session, props.language, props.currency]);

  return (
    <div className='details'>
      <div className='details-holder' ref={detailsRef}>
        <h1 className='details__title'>Detalji</h1>
        <hr />
        <div className='details__head'>
          <p className='details__head-p'>Redni broj</p>
          <p className='details__head-p'>Naziv</p>
          <p className='details__head-p'>Količina</p>
          <p className='details__head-p'>Cijena</p>
        </div>
        {entries?.map(entry => (
          <DetailsItem head={entry.head} properties={entry.properties} />
        ))}
        <div className='details__underline'></div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    config: state.config,
    session: state.session,
    language: state.UI.language,
    currency: state.UI.currency,
    screen: state.UI.screen
  };
};

export default connect(mapStateToProps)(Details);
