import React, { useState } from 'react';
import './colorSelect.scss';
import Checkmark from '../checkmark/checkmark';
import { isColorLighterThan } from '../../utils/color';
import { CurrencyConfig } from '../../store/config/reducer';
import { connect } from 'react-redux';
import { State } from '../../store/store';

export interface ColorSelectProps {
  options: Array<{
    value: string;
    label?: string;
    price?: number;
  }>;
  value: string | number;
  uncheckable: boolean;
  remove?: Function;
  onChange: Function;
  currencyConfig: CurrencyConfig;
}

const ColorSelect: React.SFC<ColorSelectProps> = (props: ColorSelectProps) => {
  const [value, setValue] = useState<string | number | null>(props.value);

  const handleOnChange = (value: string) => {
    props.onChange(value);
    setValue(value);
  };

  const handleRemove = () => {
    props.remove();
    setValue(null);
  };

  return (
    <div className='color-select'>
      {props.options.map((option, index) => {
        return (
          <div
            className='color-select__color-holder'
            key={index}
            onClick={() => option.value !== value && handleOnChange(option.value)}
          >
            <div
              className='color-select__color-holder-color'
              style={{
                backgroundColor: option.value,
                border: isColorLighterThan(option.value, 240) ? 'solid 1px #D1D1D1' : 'none'
              }}
            >
              {option.value === value && (
                <Checkmark uncheckable={props.uncheckable} onClick={handleRemove} />
              )}
            </div>
            {option.label && (
              <p
                className={
                  'color-select__color-holder-label' +
                  (option.value === value ? ' color-select__color-holder-active' : '')
                }
              >
                {option.label}
              </p>
            )}

            {option.price && (
              <p className='color-select__color-holder-price'>
                {option.value === value &&
                  option.price > 0 &&
                  `+${props.currencyConfig.side === 'left' ? props.currencyConfig.symbol : ''}${
                    option.price
                  }.00${
                    props.currencyConfig.side === 'right' ? ' ' + props.currencyConfig.symbol : ''
                  } m2`}
              </p>
            )}
          </div>
        );
      })}
    </div>
  );
};

const mapStateToProps = (state: State) => {
  return {
    currencyConfig: state.config.general.currency[state.UI.currency]
  };
};

export default connect(mapStateToProps)(ColorSelect);
