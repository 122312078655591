import store from '../../store/store';
import React from 'react';
import { previewAddProperty, previewChangeProperty } from './../../store/preview/actions';
import DimensionInput from '../../components/dimensionInput/dimensionInput';
import ImageSelect from '../../components/imageSelect/imageSelect';
import SelectInput from '../../components/selectInput/selectInput';
import ColorSelect from '../../components/colorSelect/colorSelect';
import { generalChangePropertyValue } from './../../store/general/actions';

const dispatch = (action: any) => store.dispatch(action);

const handleOnChange = (
  value: string | number | object,
  propertyId: number,
  inputId: number,
  subcategory: number | null,
  previewProperty?: string,
  onlyOne?: boolean
) => {
  dispatch(
    generalChangePropertyValue({
      propertyId,
      value,
      inputId,
      subcategory,
      onlyOne: onlyOne
    })
  );
  // @ts-ignore
  previewProperty &&
    dispatch(
      previewChangeProperty({
        previewProperty,
        // @ts-ignore
        value: typeof value === 'object' ? value.value : value
      })
    );
};

const inputGenerator = (
  input: any,
  property: string,
  inputId?: number,
  subcategoryId?: number | null,
  propertyId?: number,
  onlyOne?: boolean
) => {
  const state = store.getState();
  const general = state.general;
  const language = state.UI.language;

  input.preview &&
    dispatch(
      previewAddProperty({
        previewProperty: input.preview,
        value: !subcategoryId
          ? general.properties[propertyId].values[inputId].value
          : general.properties[propertyId].subcategories[subcategoryId].value
      })
    );

  switch (input.type) {
    case 'dimension':
      return (
        <DimensionInput
          key={input.labels.en}
          min={input.options.min}
          max={input.options.max}
          // @ts-ignore
          value={
            subcategoryId
              ? general.properties[propertyId].subcategories[subcategoryId].value
              : general.properties[propertyId].values[inputId].value
          }
          label={input.labels[language]}
          name={input.options.name}
          onChange={value =>
            handleOnChange(value, propertyId, inputId, subcategoryId, input.preview, onlyOne)
          }
        />
      );
    case 'imageSelect':
      return (
        <ImageSelect
          options={input.options}
          property={property}
          language={language}
          product={''}
          uncheckable={input.uncheckable ?? false}
          onChange={value =>
            handleOnChange(value, propertyId, inputId, subcategoryId, input.preview, onlyOne)
          }
          // @ts-ignore
          value={
            subcategoryId
              ? general.properties[propertyId].subcategories[subcategoryId].value
              : general.properties[propertyId].values[inputId].value
          }
        />
      );
    case 'select':
      return (
        <SelectInput
          // @ts-ignore
          options={Object.keys(input.options).map(option => ({
            value: input.options[option].value,
            label: input.options[option].labels[language]
          }))}
          onChange={value =>
            handleOnChange(value, propertyId, inputId, subcategoryId, input.preview, onlyOne)
          }
          value={
            subcategoryId
              ? general.properties[propertyId].subcategories[subcategoryId].value
              : general.properties[propertyId].values[inputId].value
          }
          label={input.labels[language]}
        />
      );
    case 'colorSelect':
      return (
        <ColorSelect
          options={Object.keys(input.options).map(option => ({
            value: input.options[option].value,
            label: input.options[option].labels[language],
            price: input.options[option].price.value
          }))}
          uncheckable={input.uncheckable ?? false}
          remove={() => {}}
          onChange={value =>
            handleOnChange(value, propertyId, inputId, subcategoryId, input.preview, onlyOne)
          }
          // @ts-ignore
          value={
            subcategoryId
              ? general.properties[propertyId].subcategories[subcategoryId].value
              : general.properties[propertyId].values[inputId].value
          }
        />
      );
  }
};

export default inputGenerator;
