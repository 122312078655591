const initState = {};

const reducer = (state = initState, action: any) => {
  switch (action.type) {
    case 'ADD_PRODUCT':
      return {
        ...state,
        [Object.keys(state).length]: { ...action.payload, amount: 1 }
      };
    case 'DELETE_PRODUCT':
      const newState = {};
      Object.keys(state).forEach(
        entryId => +entryId !== action.payload.entryId && (newState[+entryId] = state[entryId])
      );
      return newState;
    case 'TOGGLE_OPEN':
      if (!action.payload.subcategory) {
        let newState = {};
        Object.keys(state[action.payload.entry].properties).forEach(propertyId => {
          newState = {
            ...newState,
            [propertyId]: {
              ...state[action.payload.entry].properties[propertyId],
              open:
                +propertyId === action.payload.property
                  ? state[action.payload.entry].properties[propertyId].open
                    ? false
                    : true
                  : false
            }
          };
        });

        return {
          ...state,
          [action.payload.entry]: { ...state[action.payload.entry], properties: newState }
        };
      } else {
        const propertySubcategory =
          state[action.payload.entry].properties[action.payload.property].subcategories;
        let newState = propertySubcategory;

        Object.keys(propertySubcategory).forEach(id => {
          newState = {
            ...newState,
            [+id]: {
              ...newState[+id],
              open:
                newState[+id].id === action.payload.subcategory
                  ? propertySubcategory[id].open
                    ? false
                    : true
                  : false
            }
          };
        });

        return {
          ...state,
          [action.payload.entry]: {
            ...state[action.payload.entry],
            properties: {
              ...state[action.payload.entry].properties,
              [action.payload.property]: {
                ...state[action.payload.entry].properties[action.payload.property],
                subcategories: newState
              }
            }
          }
        };
      }

    case 'CHANGE_PRODUCT_VALUE':
      if (!action.payload.subcategory) {
        return {
          ...state,
          [action.payload.id]: {
            ...state[action.payload.id],
            change:
              typeof state[action.payload.id].change === 'number'
                ? state[action.payload.id].change + 1
                : 0,
            properties: {
              ...state[action.payload.id].properties,
              [action.payload.propertyId]: {
                ...state[action.payload.id].properties[action.payload.propertyId],
                touched: true,
                values: {
                  ...state[action.payload.id].properties[action.payload.propertyId].values,
                  [action.payload.inputId]: {
                    ...state[action.payload.id].properties[action.payload.propertyId].values[
                      action.payload.inputId
                    ],
                    valueId: action.payload.valueId,
                    value: action.payload.value,
                    option: action.payload.option ?? null,
                    optionId: action.payload.optionId ?? null,
                    touched:
                      typeof action.payload.value === 'object'
                        ? action.payload.value.value === 0
                          ? false
                          : true
                        : true
                  }
                }
              }
            }
          }
        };
      } else {
        let newSubactegory = {};
        Object.keys(
          state[action.payload.id].properties[action.payload.propertyId].subcategories
        ).forEach(subcategoryId => {
          newSubactegory = {
            ...newSubactegory,
            [subcategoryId]: {
              ...state[action.payload.id].properties[action.payload.propertyId].subcategories[
                subcategoryId
              ],
              valueId:
                +subcategoryId === +action.payload.subcategory
                  ? action.payload.valueId
                  : action.payload.onlyOne
                  ? null
                  : state[action.payload.id].properties[action.payload.propertyId].subcategories[
                      subcategoryId
                    ].valueId,
              //
              value:
                +subcategoryId === +action.payload.subcategory
                  ? action.payload.value
                  : action.payload.onlyOne
                  ? null
                  : state[action.payload.id].properties[action.payload.propertyId].subcategories[
                      subcategoryId
                    ].value,
              optionId: action.payload.optionId
                ? +subcategoryId === +action.payload.subcategory
                  ? action.payload.optionId
                  : action.payload.onlyOne
                  ? null
                  : state[action.payload.id].properties[action.payload.propertyId].subcategories[
                      subcategoryId
                    ].optionId
                : null,
              //
              option: action.payload.option
                ? +subcategoryId === +action.payload.subcategory
                  ? action.payload.option
                  : action.payload.onlyOne
                  ? null
                  : state[action.payload.id].properties[action.payload.propertyId].subcategories[
                      subcategoryId
                    ].option
                : null,
              touched:
                typeof action.payload.value === 'object'
                  ? action.payload.value.value === 0
                    ? false
                    : true
                  : true
            }
          };
        });

        return {
          ...state,
          [action.payload.id]: {
            ...state[action.payload.id],
            change:
              typeof state[action.payload.id].change === 'number'
                ? state[action.payload.id].change + 1
                : 0,
            properties: {
              ...state[action.payload.id].properties,
              [action.payload.propertyId]: {
                ...state[action.payload.id].properties[action.payload.propertyId],
                touched: true,
                subcategories: {
                  ...newSubactegory
                }
              }
            }
          }
        };
      }
    case 'CHANGE_PRODUCT_AMOUNT':
      return {
        ...state,
        [action.payload.entry]: {
          ...state[action.payload.entry],
          amount: action.payload.value
        }
      };
    case 'REMOVE_PRODUCT_VALUE':
      if (!action.payload.subcategoryId) {
        return {
          ...state,
          [action.payload.id]: {
            ...state[action.payload.id],
            properties: {
              ...state[action.payload.id].properties,
              [action.payload.propertyId]: {
                ...state[action.payload.id].properties[action.payload.propertyId],
                values: {
                  ...state[action.payload.id].properties[action.payload.propertyId].values,
                  [action.payload.inputId]: {
                    ...state[action.payload.id].properties[action.payload.propertyId].values[
                      action.payload.inputId
                    ],
                    value: null,
                    valueId: null
                  }
                }
              }
            }
          }
        };
      } else {
        return {
          ...state,
          [action.payload.id]: {
            ...state[action.payload.id],
            properties: {
              ...state[action.payload.id].properties,
              [action.payload.propertyId]: {
                ...state[action.payload.id].properties[action.payload.propertyId],
                subcategories: {
                  ...state[action.payload.id].properties[action.payload.propertyId].subcategories,
                  [action.payload.subcategoryId]: {
                    ...state[action.payload.id].properties[action.payload.propertyId].subcategories[
                      action.payload.subcategoryId
                    ],

                    value: null,
                    valueId: null
                  }
                }
              }
            }
          }
        };
      }

    case 'SESSION_PRODUCT_PRICE':
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          price: action.payload.price
        }
      };
    case 'SESSION_PRODUCT_DIMENSIONS':
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          ...action.payload.dimensions
        }
      };
    default:
      return state;
  }
};

export default reducer;
