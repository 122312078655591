import React from 'react';
import './additionalPanelRight.scss';

export interface AdditionalPanelRightProps {
  frameColor: string;
  color: string;
  width: number;
  scale: number;
}

const AdditionalPanelRight: React.SFC<AdditionalPanelRightProps> = (
  props: AdditionalPanelRightProps
) => {
  return (
    <div className='frame__additional'>
      <div
        className='frame__additional-right'
        style={{
          backgroundColor: props.frameColor ? props.frameColor : props.color,
          width: 23 * props.scale
        }}
      >
        <div className='frame__additional-right-shadow-1'></div>
        <div className='frame__additional-right-shadow-2'></div>
        <div className='frame__additional-right-shadow-3'></div>
        <div className='frame__additional-right-shadow-4'></div>
      </div>
      <div className='frame__additional-panel'>
        <div
          className='space'
          style={{ width: props.width * props.scale + 'px', height: '100%' }}
        ></div>
        <div
          className='frame__additional-right-bottom'
          style={{
            backgroundColor: props.frameColor ? props.frameColor : props.color,
            height: 23 * props.scale
          }}
        >
          <div className='frame__additional-right-bottom-shadow-1'></div>
          <div className='frame__additional-right-bottom-shadow-2'></div>
        </div>
      </div>
    </div>
  );
};

export default AdditionalPanelRight;
