import React from 'react';
import './checkbox.scss';
import { ReactComponent as CheckMark } from '../../assets/checkmark.svg';

export interface CheckBoxProps {
  value: boolean;
  onChange: Function;
  label: string;
}

const CheckBox: React.SFC<CheckBoxProps> = (props: CheckBoxProps) => {
  return (
    <div className='checkbox'>
      <input
        type='checkbox'
        className='checkbox__input'
        id='checkbox__input'
        checked={props.value}
        onChange={e => {
          props.onChange(e.target.checked);
        }}
      />
      <CheckMark
        className='checkbox__checkmark'
        style={{ opacity: props.value ? 1 : 0 }}
        onClick={() => props.onChange(!props.value)}
      />
      <label htmlFor='checkbox__input' className='checkbox__label'>
        {props.label}
      </label>
    </div>
  );
};

export default CheckBox;
