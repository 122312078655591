export const previewAddProperty = payload => {
  return {
    type: 'PREVIEW_PROPERTY_ADD',
    payload
  };
};

export const previewChangeProperty = payload => {
  return {
    type: 'PREVIEW_PROPERTY_CHANGE',
    payload
  };
};

export const setInfoCard = payload => {
  return {
    type: 'PREVIEW_SET_INFO_CARD',
    payload
  };
};

export const changePreview = (payload: any) => {
  return {
    type: 'PREVIEW_CHANGE',
    payload
  };
};
