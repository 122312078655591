export const addProduct = payload => {
  return {
    type: 'ADD_PRODUCT',
    payload
  };
};

export const deleteProduct = (payload: { entryId: number }) => {
  return {
    type: 'DELETE_PRODUCT',
    payload
  };
};

export const toggleOpen = (payload: { entry: number; property: number; subcategory?: number }) => {
  return {
    type: 'TOGGLE_OPEN',
    payload
  };
};

export const changeProductValue = (payload: {
  id: number;
  value: string | number | object;
  propertyId: number;
  inputId: number;
  valueId: number;
  subcategory?: number;
  onlyOne?: boolean;
  option?: string | number;
  optionId?: number;
}) => {
  return {
    type: 'CHANGE_PRODUCT_VALUE',
    payload
  };
};

export const changeProductAmount = (payload: { entry: number; value: number }) => {
  return {
    type: 'CHANGE_PRODUCT_AMOUNT',
    payload
  };
};

export const removeInputValue = (payload: {
  id: number;
  propertyId: number;
  inputId: number;
  subcategoryId?: number;
}) => {
  return {
    type: 'REMOVE_PRODUCT_VALUE',
    payload
  };
};

export const sessionProductPrice = (payload: { id: number; price: number }) => {
  return {
    type: 'SESSION_PRODUCT_PRICE',
    payload
  };
};

export const sessionProductDimensions = (payload: {
  id: number;
  dimensions: {
    width: number;
    height: number;
    overallWidth: number;
    overallHeight: number;
  };
}) => {
  return {
    type: 'SESSION_PRODUCT_DIMENSIONS',
    payload
  };
};
