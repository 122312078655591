import Axios from 'axios';

const axios = Axios.create();

axios.interceptors.response.use(
  response => response,
  error => Promise.reject(error.response)
);

export default axios;
