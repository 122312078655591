import React from 'react';
import './checkmark.scss';
import { ReactComponent as CheckmarkIcon } from '../../assets/checkmark.svg';
import { ReactComponent as XIcon } from '../../assets/x-small.svg';

export interface CheckmarkProps {
  uncheckable?: boolean;
  onClick?: Function;
}

const Checkmark: React.SFC<CheckmarkProps> = (props: CheckmarkProps) => {
  return (
    <div
      className={'checkmark' + (props.uncheckable ? ' uncheckable' : '')}
      onClick={e => props.uncheckable && props.onClick()}
    >
      <CheckmarkIcon className='checkmark__check' />
      <XIcon className='checkmark__x' />
    </div>
  );
};

export default Checkmark;
