import React, { useState, useEffect } from 'react';
import './entranceDoorFrame.scss';
import AdditionalPanelLeft from '../additionalPanelLeft/additionalPanelLeft';
import AdditionalPanelRight from '../additionalPanelRight/additionalPanelRight';
import AdditionalPanelTop from '../additionalPanelTop/additionalPanelTop';
import { isColorLight } from './../../../utils/color';

export interface EntranceDoorFrameProps {
  color: string;
  children: React.ReactChild | React.ReactFragment | React.ReactPortal | boolean | null | undefined;
  additionalLeftAmount: number;
  additionalLeftWidth: number;
  additionalRightAmount: number;
  additionalRightWidth: number;
  additionalTopAmount: number;
  additionalTopHeight: number;
  scale: number;
  view: 'inside' | 'outside';
  glass: string;
}

const EntranceDoorFrame: React.SFC<EntranceDoorFrameProps> = (props: EntranceDoorFrameProps) => {
  const [leftPanels, setLeftPanels] = useState([]);
  const [rightPanels, setRightPanels] = useState([]);

  useEffect(() => {
    if (leftPanels.length !== props.additionalLeftAmount) {
      const panels = [];
      for (let i = 1; i <= props.additionalLeftAmount; i++) panels.push(i);
      setLeftPanels(panels);
    }
  }, [props.additionalLeftAmount]);

  useEffect(() => {
    if (rightPanels.length !== props.additionalRightAmount) {
      const panels = [];
      for (let i = 1; i <= props.additionalRightAmount; i++) panels.push(i);
      setRightPanels(panels);
    }
  }, [props.additionalRightAmount]);

  return (
    <div
      className='entrance-door-frame'
      style={{
        gridTemplateColumns: `repeat(${3 +
          (props.additionalLeftAmount + props.additionalRightAmount)}, max-content)`
      }}
    >
      <div
        className='backview'
        style={{
          backgroundImage: `url(${require(`../../../assets/preview/backview-${props.glass}.jpg`)})`
        }}
      ></div>
      <div
        className='entrance-door-frame__top'
        style={{
          backgroundColor: props.color,
          gridColumn: `1 / -1`,
          gridRow: '1 / 2',
          height: `calc(23px * ${props.scale})`
        }}
      >
        <div
          className='entrance-door-frame__top-shadow-1'
          style={{
            width: `calc(100% - 44px * ${props.scale})`,
            left: 22 * props.scale,
            backgroundColor: isColorLight(props.color) ? 'black' : 'white'
          }}
        ></div>
        <div
          className='entrance-door-frame__top-shadow-2'
          style={{
            width: `calc(100% - 44px * ${props.scale})`,
            left: 22 * props.scale,
            opacity: isColorLight(props.color) ? 0.15 : 0.2
          }}
        ></div>
        <div
          className='entrance-door-frame__top-corner-line-left'
          style={{
            backgroundColor: isColorLight(props.color) ? 'black' : 'white',
            opacity: isColorLight(props.color) ? 0.15 : 0.2
          }}
        ></div>
        <div
          className='entrance-door-frame__top-corner-line-right'
          style={{
            backgroundColor: isColorLight(props.color) ? 'black' : 'white',
            opacity: isColorLight(props.color) ? 0.15 : 0.2
          }}
        ></div>
      </div>

      {props.additionalTopAmount > 0 && (
        <AdditionalPanelTop
          color={props.color}
          frameColor={props.color}
          height={props.additionalTopHeight}
          gridColumn={`2 / ${props.additionalLeftAmount + props.additionalRightAmount + 3}`}
          scale={props.scale}
        />
      )}

      <div
        className='entrance-door-frame__left'
        style={{
          backgroundColor: props.color,
          gridRow: props.additionalTopAmount > 0 && '2 / 4',
          gridColumn: '1 / 2',
          width: `calc(23px * ${props.scale})`
        }}
      >
        <div
          className='entrance-door-frame__left-shadow-1'
          style={{
            backgroundColor: isColorLight(props.color) ? 'black' : 'white'
          }}
        ></div>
        <div
          className='entrance-door-frame__left-shadow-2'
          style={{
            opacity: isColorLight(props.color) ? 0.15 : 0.2
          }}
        ></div>
      </div>

      {leftPanels.map(() => (
        <AdditionalPanelLeft
          color={props.color}
          frameColor={props.color}
          width={props.additionalLeftWidth}
          scale={props.scale}
        />
      ))}

      {props.children}

      {rightPanels.map(() => (
        <AdditionalPanelRight
          color={props.color}
          frameColor={props.color}
          width={props.additionalRightWidth}
          scale={props.scale}
        />
      ))}

      <div
        className='entrance-door-frame__right'
        style={{
          backgroundColor: props.color,
          gridRow: props.additionalTopAmount > 0 && '2 / 4',
          width: `calc(23px * ${props.scale})`
        }}
      >
        <div
          className='entrance-door-frame__right-shadow-1'
          style={{
            backgroundColor: isColorLight(props.color) ? 'black' : 'white'
          }}
        ></div>
        <div
          className='entrance-door-frame__right-shadow-2'
          style={{
            opacity: isColorLight(props.color) ? 0.15 : 0.2
          }}
        ></div>
      </div>
      <div className='entrance-door-frame-white-bottom'></div>
    </div>
  );
};

export default EntranceDoorFrame;
